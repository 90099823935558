<script>

//import { library } from '@fortawesome/fontawesome-svg-core';
//import { faPen, faArrowAltCircleRight, faSignOutAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faComment } from '@fortawesome/free-solid-svg-icons';
//library.add(faPen,faArrowAltCircleRight,faSignOutAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faComment)
//import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import $ from 'jquery'; 
import { useToast } from "vue-toastification";
import MyPagination from '@/components/SystemElements/Pagination.vue'
import MyCountRecords from '@/components/SystemElements/CountRecordsOptions.vue'

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";
Fancybox.bind("[data-fancybox]", {
  // Your options go here
});

import Popper from "vue3-popper";

//import "viewerjs/dist/viewer.css";
//import Viewer from "v-viewer/src/component.vue";
//import Viewer from "v-viewer";
//import '@fancyapps/fancybox/dist/jquery.fancybox.min.js'

//import { Image } from 'vue3-image-viewer';

import PhotoEdit from '@/components/Core/Photos/PhotoEdit.vue'
import PhotoDelete from '@/components/Core/Photos/PhotoDelete.vue'
import PhotosDeleteBulk from '@/components/Core/Photos/PhotosDeleteBulk.vue'

//general functions
import MixinGeneralFunct from '@/mixins/GeneralFunctions';

import axios from 'axios';

export default {
	setup() {
		const toast = useToast();
		return { toast }
	},
	name: 'Photos',
	mixins: [ MixinGeneralFunct ],
	//directives: {
		// enables v-focus in template
		//Viewer
	//},
	components: {
		//"font-awesome-icon":FontAwesomeIcon
		PhotoEdit
		,PhotoDelete
		,PhotosDeleteBulk
		,Popper
		,MyPagination
		,MyCountRecords
        //,GroupAuthorizations
		//,Image
	},
	props: ["auth","secData","params"],
	data() {
		return {
			prefix:'',
		
            
            accData:[],
           
            preloader:true,

            menuItems:[],
            actLangs:[],
            
            helpboxData: [],
            
            basicDataCount:0,
            basicData:[],
            selectedFilterText:'',
            
            selectedGallery:'',
            optionsGalleries:[],

            selectedPhotoId:'',
			

            countPages:0,

			selectedPage:1,
			selectedRecords:10,
			selectedCol:'a.id',
			selectedSort:'desc',
			
			selectedIds:[],
			selectAllIds:false,
			
			
		
			showFilter:false,
			filterExtended:true,
			
			showedHelpbox:false


		}
	},
	
	mounted:function(){

	},
	
	methods: {
		
		//na fontende aj preview aj tabula, zober povodne idcka a idcka po aktualizacii a tie naviac zobraz na par sekund inou farbou, alebo ako nove.
		
		//default data
		
		
		loadDefaultValues:function(){
			
			//show loader
			this.preloader=true
			
						
			var self = this;
			axios.get('/admins/app/core/Photos/getPhotoList.php',{
				params:{
					filterText: this.selectedFilterText,
					filterGallery: this.selectedGallery,
					
					//system
					selectedIds: this.selectedIds,
					orderCol: this.selectedCol,
					orderSort: this.selectedSort,
					page: this.selectedPage,
					records: this.selectedRecords
					
				}
			})
			.then(function (response) {
				//console.log(response.data)
			
                //hide loader
				self.preloader=false

                //access data
				self.accData = response.data.accData;
				
				//helpbox data
				self.helpboxData = 'guidebook.guidePhotos';
										
			//	if(app.accessToEmployee){
					

					self.countPages = response.data.countPages;

					self.basicDataCount = response.data.basicDataCount;
					self.basicData = response.data.basicData;
					
					self.optionsGalleries = response.data.optionsGalleries;
					
					//oznacenie poloziek cez strankovac
					var indexToSelect;
					var selectedIdsTEMP = [];
					selectedIdsTEMP = response.data.selectedIds; 
					self.selectedIds = [];
					for(let i = 0; i < selectedIdsTEMP.length; i++){
						self.selectedIds.push(Number(selectedIdsTEMP[i]));
					}
					for(let i = 0; i < self.basicData.length; i++){
						indexToSelect = self.selectedIds.indexOf(self.basicData[i].id);
						if(indexToSelect>=0){
							self.basicData[i].selectedId=true;
						}
					}
					self.selectAllIds = false;
					
					
					
					//app.countLimit();
					
			//	}
				
			})
				.catch(function (error) {
				console.log(error);
				
				
			});
		
		},
		
				
		//odomknutie zaznamu
		unlockPhoto:function(groupId){
			
			this.cSession();
			var bodyFormData = new FormData();
			bodyFormData.set('myRecordID',groupId);
				
			var self = this;
			axios({
				method: 'post',
				url: '/admins/app/core/Photo/controlPhotoUnlock.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
				//	console.log(response.data);
					if(response.data==1){
						self.toast.success(this.$t('msgCore.dataWasUnlocked'));
						self.loadDefaultValues();
					}else if(response.data==0){
						self.toast.warning(this.$t('msgCore.dataCantBeUnlocked'));
						self.loadDefaultValues();
					}else if(response.data==2){
						self.toast.error(this.$t('msgCore.dataWasNotUnlocked'));
						self.loadDefaultValues();
					}else if(response.data==3){
						self.toast.error(this.$t('msgCore.accessDenied'));
						self.loadDefaultValues();
					}
					
				}
			})
			.catch(error => {
				console.log(error.response)
			}); 
			
		},
		
		
		
		
		
		//systemove funkcie k zoznamu
		//(od)oznacenie vsetkych poloziek
		unSelectIds:function(){
			if(this.selectAllIds){
				for(let i = 0; i < this.basicData.length; i++){
					this.basicData[i].selectedId=true;
					this.selectedIds.push(this.basicData[i].id);
				}
				this.selectedIds = this.selectedIds.filter( this.onlyUnique );
			}else{
				for(let i = 0; i < this.basicData.length; i++){
					this.basicData[i].selectedId=false;
					var indexToRemove = this.selectedIds.indexOf(this.basicData[i].id);
					if(indexToRemove>=0){
						this.selectedIds.splice(indexToRemove, 1);
						
					}
					//this.selectedIds = [];
				}
				this.selectedIds = this.selectedIds.filter( this.onlyUnique );
			}
			//console.log(this.selectedIds);
		},
		
		//oznacenie jednej polozky
		checkSelectIds:function(index,id){
			if(this.basicData[index].selectedId==true){
				this.selectedIds.push(id);
			}else{
				var indexToRemove = this.selectedIds.indexOf(id);
				this.selectedIds.splice(indexToRemove, 1);
			}
			
			this.selectedIds = this.selectedIds.filter( this.onlyUnique );	
			//console.log(this.selectedIds);
		},
		
		//radenie zoznamu - orders by
		orderByCol:function(col){
			
			if(col === this.selectedCol) {
				this.selectedSort = this.selectedSort==='asc'?'desc':'asc';
			}
			this.selectedCol = col;
			
			this.loadDefaultValues();
		},
		
		//select page 
		selPage:function(x){
			
			if(x=='prev' || x=='next'){
				if(x=='prev'&&this.selectedPage!=1){this.selectedPage=this.selectedPage-1}
				if(x=='next'&&this.selectedPage!=this.countPages){this.selectedPage=this.selectedPage+1}
			}else{
				if(this.selectedPage != x){
					this.selectedPage = Number(x);
				}
			}
			//console.log(this.selectedPage);
			this.loadDefaultValues();
			
		},

		//active menu
		isActive: function (col) {
			return this.selectedCol === col;
		},
		isAsc: function (col) {
			if(this.selectedCol === col && this.selectedSort === 'asc'){
				return true;
			}
		},
		isDesc: function (col) {
			if(this.selectedCol === col && this.selectedSort === 'desc'){
				return true;
			}
		},
		
		
		
		//zobrazi filtre
		showFilters: function(){
			if(!this.showFilter) {
				this.showFilter = true;
			}else{
				this.showFilter = false;
			}
		},
		
		//vycisti text filter
		resetTextFilter: function(){
			this.selectedFilterText = '';
			this.loadDefaultValues();
		},
		
		// zobrazi napovedu
		showHelpbox: function(){
			if(!this.showedHelpbox) {
				this.showedHelpbox = true;
			}else{
				this.showedHelpbox = false;
			}
		},
		// systemove funkcie k zoznamu KONIEC
		
		
		//modal box gallery editacia
		loadModalFormPhotoEdit:function(recordID){
			this.cSession();
			//console.log(recordID)
			this.selectedPhotoId=recordID;
			$('#myModalEditPhoto').addClass('modal');
			$('#myModalEditPhoto').modal({backdrop: "static"});
		},
		
		//modal box gallery delete
		loadModalFormPhotoDelete:function(recordID){
			this.cSession();
			//console.log(recordID)
			this.selectedPhotoId=recordID;
			$('#myModalDeletePhoto').addClass('modal');
			$('#myModalDeletePhoto').modal({backdrop: "static"});
		},

		//modal box hromadne vymazanie
		loadModalFormPhotoDeleteBulk:function(){
			this.cSession();
						
			if(this.selectedIds.length>0){
				$('#myModalDeleteBulkPhoto').addClass('modal');
				$('#myModalDeleteBulkPhoto').modal({backdrop: "static"});
			}else{
				self.toast.warning(this.$t('msgCore.selectRecords'));
			}
		},

	
		reloadAfterChange() {
		//	console.log(e);
			this.loadDefaultValues();
			this.selectedPhotoId='';
		},

		reloadAfterDeleteBulk() {
		//	console.log(e);
			this.loadDefaultValues();
			this.selectedIds=[];
		},

		//vyber stranky v strankovaci
		reloadAfterChangeNrPage(selectedPage) {
			this.selectedPage=selectedPage
			this.loadDefaultValues();
		},

		//vyber poctu zaznamov
		reloadAfterChangeCountRecords(selectedCount) {
			this.selectedRecords=selectedCount
			this.loadDefaultValues();
		},

		//otocenie fotky o 90
		rotatePhoto:function(photoId){
			this.cSession();
			var bodyFormData = new FormData();
			bodyFormData.set('photoId',photoId);
			
			var self = this;
			axios({
				method: 'post',
				url: '/admins/app/core/Photo/controlPhotoRotate.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
				//	console.log('success2', response.data);
					if(response.data.resCode==1){
						self.toast.success(this.$t('msgCore.photoWasRotated'));
						self.loadDefaultValues();
					}else if(response.data.resCode==0){
						self.toast.warning(this.$t('msgCore.photoCantBeRotated'));
					}else if(response.data.resCode==2){
						self.toast.error(this.$t('msgCore.photoWasNotRotated'));
					}else if(response.data.resCode==3){
						self.toast.error(this.$t('msgCore.accessDenied'));
					}
					
					
				}
			})
			.catch(error => {
				console.log(error.response)
			}); 
		},

		//zmena stavu
		changeState:function(recordId,state){
			this.cSession();
			var bodyFormData = new FormData();
			bodyFormData.set('recordId',recordId);
			bodyFormData.set('state',state);
			
			var self = this;
			axios({
				method: 'post',
				url: '/admins/app/core/Photo/controlPhotoChangeState.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
				//	console.log('success2', response.data);
					if(response.data.resCode==1){
						self.toast.success(this.$t('msgCore.dataWasSaved'));
						self.loadDefaultValues();
					}else if(response.data.resCode==0){
						self.toast.warning(this.$t('msgCore.dataCantBeSaved'));
					}else if(response.data.resCode==2){
						self.toast.warning(this.$t('msgCore.dataCantBeSaved'));
					}else if(response.data.resCode==3){
						self.toast.error(this.$t('msgCore.accessDenied'));
					}
				}
			})
			.catch(error => {
				console.log(error.response)
			}); 
	
		}
		
		
	},	
	
			
	watch: {
		'auth.photosList': function () {
			this.loadDefaultValues();
		}
	},

	computed:{
		
	},
	
}

</script>

<template>
	
	<div v-show="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
	<div id="version" class="version"><p>{{accData.version}}</p></div>
				
		<div v-if="accData.modulAcc" > 
					
            <div class="row">
                <div class="col-12">
                                    
                        <div class="row">
                            <div class="col-6">
                                <h5>{{ $t('msgCore.Photos') }}</h5>	 
                            </div> 
                            
                            <div class="col-6 right">
                                <button type="button" :disabled="!auth.photosDeleteBulk" :title="$t('msgCore.btnDelete')" class="btn btn-danger btn-sm" v-on:click="loadModalFormPhotoDeleteBulk()"><i class="smire-icon fas fa-trash-alt"></i> {{ $t('msgCore.btnDelete') }}</button>
                            </div> 
                        </div>

                        <!-- filters basic -->
                        <div class="row">
                            <div class="input-group col-md-4 col-12">
                                <button type="button" :title="$t('msgCore.btnFilter')" class="btn btn-info btn-sm my-btn" v-on:click="showFilters()">
                                    <span v-if="filterExtended">
                                        <i v-if="!showFilter" class="smire-icon fas fa-search-plus"></i> 
                                        <i v-else class="smire-icon fas fa-search-minus"></i>
                                    </span>
                                    <span v-else>
                                        <i class="smire-icon fas fa-search"></i> 
                                    </span>
                                    {{ $t('msgCore.btnFilter') }}
                                </button>
                                <input type="text" class="form-control form-control-sm" v-model='selectedFilterText' :placeholder="$t('msgCore.searchTitle')" v-on:change="loadDefaultValues()">
                                <button type="button" :title="$t('msgCore.btnStorno')" class="btn btn-outline-info btn-sm my-btn" v-on:click="resetTextFilter()">
                                    <i class="smire-icon fas fa-times"></i>
                                </button>
                            </div>
                        </div>
                        

                        <!-- filters extended -->
                       <div v-show="showFilter && filterExtended" class="row">
							<div class="input-group form-group-filter col-4">
								<select v-model='selectedGallery' class="form-control form-control-sm" v-on:change="loadDefaultValues()">
									<option value="">{{ $t('msgCore.selectGallery') }}</option>
									<option v-for="(option) in optionsGalleries" :value="option.value" :key="option" >{{ option.title }}</option>
								</select>
							</div>				
						</div>
                        
                    
                </div>
            </div>	

            
            <!-- zakladny zoznam -->
			<div v-if="basicDataCount>0">
						
				<div class="row">
					<div class="col-12">
	
						<div class="table-responsive">
							<table class="table table-hover table-sm">
							
								<thead>
									<tr>
										<th class="orderBy" v-on:click="orderByCol('a.id')" :class="{ active: isActive('id'), asc: isAsc('id'), desc: isDesc('id')}">id</th>
									
										<th width="10" class="align-middle"><div class="form-check"><label class="form-check-label"><input v-model="selectAllIds" v-on:change="unSelectIds()" class="form-check-input" type="checkbox"><span class="form-check-sign"><span class="check"></span></span></label></div></th>
									
										<th>
											{{$t('msgCore.Action')}}
										</th>

										
										<th colspan="2">{{$t('msgCore.State')}}</th>
										
										<th>{{$t('msgCore.Preview')}}</th>
										
										<th class="orderBy" v-on:click="orderByCol('a.title')">{{$t('msgCore.Title')}}</th>
										
										<th>{{$t('msgCore.Gallery')}}</th>
										
										<th>{{$t('msgCore.Size')}}</th>
										
										<th class="orderBy" v-on:click="orderByCol('a.created')">{{$t('msgCore.Created')}}</th>
										
										<th>{{$t('msgCore.Modified')}}</th>
										
										<th>{{$t('msgCore.AccessLevel')}}</th>									
									</tr>
								</thead>
								
								
								<tr v-for="(basicObj, index) in basicData" :key="basicObj" >
								
									<td>{{ basicObj.id }}</td>
									
									<td class="align-middle">
										<div class="form-check">
											<label class="form-check-label">
											<input v-model.number='basicObj.selectedId' class="form-check-input" type="checkbox" v-on:change="checkSelectIds(index,basicObj.id);">
											<span class="form-check-sign"><span class="check"></span></span>
											</label>
										</div>	
									</td>
								
									<td>
									
										<button v-if="basicObj.isCheckedOut" type="button" :disabled="!auth.photosUnlock" v-on:click="unlockPhoto(basicObj.id)" v-bind:title="basicObj.checkedOutName+', '+basicObj.isCheckedOutDate" class="btn btn-sm"><i class="smire-icon fas fa-lock"></i></button>
									
										<div class="input-group">	
																	
											<div class="btn-group">				
												<button type="button" :disabled="!auth.photosEdit" v-on:click="loadModalFormPhotoEdit(basicObj.id)" :title="$t('msgCore.btnEdit')" id="myModalBtn" class="btn btn-secondary btn-sm"><i class="smire-icon fas fa-edit"></i></button>
												
												<button type="button" :disabled="!auth.photosDelete" v-on:click="loadModalFormPhotoDelete(basicObj.id)" :title="$t('msgCore.btnDelete')" id="myModalBtn" class="btn btn-danger btn-sm"><i class="smire-icon fas fa-trash-alt"></i></button>
														
											</div>	
																										
											<button type="button" :disabled="!auth.photosRotate" v-on:click="rotatePhoto(basicObj.id)" :title="$t('msgCore.btnRotate')" id="myModalBtn" class="btn btn-primary btn-sm"><i class="smire-icon fas fa-sync"></i></button>
											
										</div>	
									</td>

									<td  :title="$t('msgCore.' + basicObj.state_name)">
										<i class="fas fa-circle" :class="{'text-success':basicObj.state==1,'text-danger':basicObj.state==2}" ></i>
									</td>	

									<td>
										<div class="togglebutton">
											<label>
												<input v-model='basicObj.isActive' :disabled="!auth.photosChangeState" type="checkbox" v-on:change="changeState(basicObj.id,basicObj.state)">
												<span class="toggle"></span>
											</label>
										</div>
									</td>
									
									
									<td>
										<!-- <a class="fancybox" data-fancybox="gallery" :href="basicObj.fotoDataOrigo"><img :src="basicObj.fotoData" style="height: 70px;"></a>-->

										<photo-provider>
											<photo-consumer :download-name="basicObj.title"  :src="basicObj.fotoDataOrigo">
												<img :src="basicObj.fotoData" class="view-box" style="height: 70px; cursor:pointer;">
											</photo-consumer>
										</photo-provider>

									</td>
									
									<td >{{ basicObj.title }}</td>
									
									<td >{{ basicObj.gallery_name }}</td>
									
									<td >{{ basicObj.fileSize }}</td>
									
									<td>
										<Popper :content="basicObj.creator+', '+basicObj.createdView">
											<button class="personCircle v-center" v-bind:style="{background:basicObj.creatorColor}">{{ basicObj.creatorNameLetter+''+basicObj.creatorSurnameLetter }}</button>
										</Popper>
									</td>

									<td>
										<Popper v-if="basicObj.editor" :content="basicObj.editor+', '+basicObj.modifiedView">
											<button class="personCircle v-center" v-bind:style="{background:basicObj.editorColor}">{{ basicObj.editorNameLetter+''+basicObj.editorSurnameLetter }}</button>
										</Popper>
									</td>
                                        
									
								
									<td >{{ basicObj.access_name }}</td>
									
									
								</tr>
									

							
							</table>	
						</div>		
					
					</div>
				</div>
					
				<div class="row row-pagination">
					<div class="col-6 col-md-3 col-lg-2">
						<MyCountRecords :selectedRecords="selectedRecords" @changeAfterSelectCount="reloadAfterChangeCountRecords"></MyCountRecords>
					</div>
					
					<div class="col-6 col-md-3 col-lg-2">
						<div class="form-group form-group-filter">
							<span class="btn btn-default btn-sm">Počet: {{basicDataCount}}</span>
						</div>
					</div>
					
					<div class="col-12 col-md-6 col-lg-8">	
						<MyPagination :countPages="countPages" :selectedPage="selectedPage" @changeAfterSelectPage="reloadAfterChangeNrPage"></MyPagination>
					</div>
				</div>	
								
							
			</div>	
			
			<div v-else class="row">
				<div class="col-12">
					<div  class="alert alert-warning" role="alert">
						{{$t('msgCore.forThisSelectExistAnyData')}}
					</div>	
				</div>							
			</div>
            
        </div>	
        
        <div v-else class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="alert alert-warning" role="alert">
                        {{$t('msgCore.accessDenied')}}
                    </div>
                </div>
            </div>
        </div>
	
		<PhotoEdit :auth="auth" :secData="secData" :photoId="String(selectedPhotoId)" @changeRecord="reloadAfterChange"></PhotoEdit>
		<PhotoDelete :auth="auth" :secData="secData" :photoId="String(selectedPhotoId)" @changeRecord="reloadAfterChange"></PhotoDelete>
		<PhotosDeleteBulk :auth="auth" :secData="secData" :photoIds="selectedIds" @changeAfterDeleteBulk="reloadAfterDeleteBulk"></PhotosDeleteBulk>
		

		
</template>


