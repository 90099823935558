<script>

export default {
	name: 'MainLogo',
	
	components: {
	
	},
	
	data() {
		return {
		
		}
	},
	
	props: {'params': Object},
	
	mounted:function(){
		
	},
	
	methods: {
			
	},	
	
	watch: {
		
	},

	computed:{
		
	},
	
}

</script>

<template>
	<div>
		<div class='container-fluid'>
			<div class='row'>
				<div class='col-8 mylogo'>
					<svg version="1.0" id="Vrstva_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="507.51px" height="158.96px" viewBox="0 0 507.51 158.96" enable-background="new 0 0 507.51 158.96" xml:space="preserve">
<g>
	<path fill="#ffffff" d="M20.4,155.661c-8.534-2.201-15.334-4.967-20.4-8.301l10.4-22.4c4.8,3.07,10.6,5.57,17.4,7.5c6.8,1.936,13.466,2.9,20,2.9
		c13.2,0,19.8-3.266,19.8-9.799c0-3.066-1.8-5.266-5.4-6.602c-3.6-1.33-9.135-2.465-16.6-3.398c-8.8-1.332-16.069-2.867-21.8-4.602
		c-5.734-1.73-10.7-4.799-14.9-9.199c-4.2-4.4-6.3-10.666-6.3-18.801c0-6.799,1.965-12.831,5.9-18.099
		c3.931-5.266,9.666-9.366,17.2-12.3c7.531-2.932,16.431-4.4,26.7-4.4c7.6,0,15.166,0.834,22.7,2.5
		c7.531,1.669,13.766,3.969,18.7,6.9l-10.4,22.2c-9.469-5.331-19.8-8-31-8c-6.668,0-11.668,0.935-15,2.8c-3.334,1.869-5,4.269-5,7.2
		c0,3.334,1.8,5.668,5.4,7c3.6,1.334,9.332,2.6,17.2,3.801c8.8,1.469,16,3.033,21.6,4.699c5.6,1.668,10.465,4.699,14.6,9.1
		c4.131,4.4,6.2,10.535,6.2,18.4c0,6.668-2,12.6-6,17.801c-4,5.199-9.834,9.234-17.5,12.1c-7.668,2.865-16.769,4.299-27.3,4.299
		C37.666,158.96,28.931,157.86,20.4,155.661z"/>
	<path fill="#ffffff" d="M286.499,60.061c7.932,7.934,11.9,19.834,11.9,35.7v61.6h-31.201v-56.799c0-8.531-1.768-14.9-5.299-19.102
		c-3.535-4.199-8.569-6.299-15.101-6.299c-7.334,0-13.134,2.369-17.4,7.1c-4.269,4.734-6.4,11.77-6.4,21.1v54h-31.2v-56.799
		c0-16.932-6.8-25.401-20.4-25.401c-7.2,0-12.935,2.369-17.2,7.1c-4.269,4.734-6.4,11.77-6.4,21.1v54h-31.2v-107.6h29.8v12.4
		c4-4.531,8.9-8,14.7-10.4c5.8-2.4,12.166-3.6,19.1-3.6c7.6,0,14.466,1.5,20.6,4.5c6.131,3,11.065,7.369,14.8,13.1
		c4.4-5.6,9.966-9.931,16.7-13c6.731-3.065,14.1-4.6,22.101-4.6C267.864,48.161,278.564,52.129,286.499,60.061z"/>
	<path fill="#f88c00" d="M317.051,36.026c-3.928-4.027-5.891-9.025-5.891-14.991c0-5.961,1.963-10.959,5.891-14.99
		C320.98,2.017,326.071,0,332.328,0c6.255,0,11.348,1.935,15.276,5.803c3.929,3.869,5.891,8.705,5.891,14.507
		c0,6.287-1.962,11.485-5.891,15.595c-3.929,4.111-9.021,6.166-15.276,6.166C326.071,42.07,320.98,40.057,317.051,36.026z"/>
	<path fill="#ffffff" d="M362.608,52.161c6.331-2.666,13.631-4,21.899-4v28.8c-3.468-0.266-5.8-0.4-6.999-0.4c-8.936,0-15.936,2.5-21,7.5
		c-5.069,5-7.601,12.5-7.601,22.5v50.799h-31.2v-107.6h29.801v14.2C351.239,58.761,356.274,54.83,362.608,52.161z"/>
	<path fill="#ffffff" d="M506.911,112.36h-81.4c1.466,6.67,4.932,11.936,10.4,15.801c5.466,3.869,12.266,5.799,20.399,5.799
		c5.601,0,10.566-0.83,14.9-2.5c4.331-1.664,8.365-4.299,12.1-7.898l16.601,18c-10.135,11.6-24.935,17.398-44.4,17.398
		c-12.134,0-22.868-2.365-32.2-7.1c-9.334-4.73-16.534-11.299-21.6-19.699c-5.068-8.4-7.6-17.932-7.6-28.6
		c0-10.531,2.5-20.031,7.5-28.5c5-8.466,11.865-15.066,20.6-19.8c8.731-4.731,18.5-7.1,29.3-7.1c10.531,0,20.065,2.269,28.601,6.8
		c8.531,4.534,15.23,11.034,20.1,19.5c4.865,8.468,7.3,18.3,7.3,29.499C507.51,104.36,507.31,107.161,506.911,112.36z
		M433.911,77.761c-4.8,4-7.734,9.469-8.8,16.4h53c-1.069-6.801-4-12.232-8.801-16.3c-4.8-4.065-10.668-6.1-17.6-6.1
		C444.642,71.761,438.71,73.761,433.911,77.761z"/>
</g>
</svg>
				</div>
			</div>
				
		</div>
	</div>
</template>


