<script>

import Popper from "vue3-popper";
import { useToast } from "vue-toastification";
import axios from 'axios';

//general functions
import MixinGeneralFunct from '@/mixins/GeneralFunctions';
import { defineComponent } from 'vue'



export default defineComponent ({
	setup() {
		const toast = useToast();
		return { toast }
	},
	name: 'CampaignSend',
	mixins: [ MixinGeneralFunct ],
	components: {
		Popper
	},
	props: {'campaignId': String, 'auth':Object, 'secData':Object},
	emits: ["campaignId","changeRecord"],
	data() {
	
		return {
			prefix:'',
			
			params:[],
			accData: [],
			
			preloader:true,
			
			isNewRecord:true,
	
			//form
			contactsValid:[],
			contactsToSend:[],
			contactsSent:[],
			campaign:[],
			template:[],

			btnSaving:false,
			
			optionsCategories:[],
			selectedCategoryId:''
		}
	},
	
	mounted:function(){
		
	},
	
	methods: {
		// load data form
		loadDefaultValues:function(){
		
			var bodyFormData = new FormData();
			bodyFormData.set('campaignId',this.campaignId);
			//bodyFormData.set('categoryId',this.selectedCategoryId);
			bodyFormData.set('myOptionsCategories',JSON.stringify(this.optionsCategories));
			
			var self=this;
			axios({
				method: 'post',
				url: '/admins/app/customMailing/MailingCampaigns/getCampaignContacts.php',
				data: bodyFormData
			})
			.then(function (response) {
			
				console.log(response.data);
				self.accData = response.data.accData;
				
				//hide loader
				self.preloader=false
					
				self.contactsValid = response.data.contactsValid;
				self.contactsToSend = response.data.contactsToSend;
				self.contactsSent = response.data.contactsSent;
				self.campaign = response.data.campaign;
				self.template = response.data.template;
				self.optionsCategories = response.data.optionsCategories;
				
			})
				.catch(function (error) {
				console.log(error);
				
				
			});
		
		},
		
		closeModalBoxSecureHelper:function(myform,campaignId){
			this.unlockCampaign(campaignId);
			//this.loadDefaultValues();
			this.closeModalBoxSecure(myform);
		},
		
		
		sendCampaignEmail:function(){

			if(this.campaignId){
				
				var bodyFormData = new FormData();
				bodyFormData.set('campaignId',this.campaignId);
				//bodyFormData.set('categoryId',this.selectedCategoryId);
				bodyFormData.set('myOptionsCategories',JSON.stringify(this.optionsCategories));

				this.btnSaving=true;

				var self=this;
				axios({
					method: 'post',
					url: '/admins/app/customMailing/MailingCampaigns/controlCampaignPlaceEmailToFront.php',
					data: bodyFormData
				})
				.then(response => {
					if (response.data.error) {
							console.log('error', response.data.error)
					} else {
						console.log('success2', response.data);
						if(response.data.resCode==1){
							self.btnSaving=false;
							self.toast.success(this.$t('msgCore.dataWasSaved'));
							self.$emit('changeRecord', '')

							self.closeModalBoxSecure('myModalSendCampaign');
							
						}else if(response.data.resCode==0){
							self.toast.warning(this.$t('msgCore.dataCantBeSaved'));
							self.closeModalBoxSecure('myModalSendCampaign');
						}else if(response.data.resCode==2){
							self.toast.error(this.$t('msgCore.dataWasNotSaved'));
							self.closeModalBoxSecure('myModalSendCampaign');
						}else if(response.data.resCode==3){
							self.toast.error(this.$t('msgCore.accessDenied'));
							self.closeModalBoxSecure('myModalSendCampaign');
						}
						self.campaign = [];
					}
				})
				.catch(error => {
					console.log(error.response)
				}); 
				
				
			}
			
		},


		sendCampaignSMS:function(){

			if(this.campaignId){

				var bodyFormData = new FormData();
				bodyFormData.set('campaignId',this.campaignId);
				//bodyFormData.set('categoryId',this.selectedCategoryId);
				bodyFormData.set('myOptionsCategories',JSON.stringify(this.optionsCategories));

				this.btnSaving=true;

				var self=this;
				axios({
					method: 'post',
					url: '/admins/app/customMailing/MailingCampaigns/controlCampaignPlaceSMSToFront.php',
					data: bodyFormData
				})
				.then(response => {
					if (response.data.error) {
							console.log('error', response.data.error)
					} else {
						console.log('success2', response.data);
						if(response.data.resCode==1){
							self.btnSaving=false;
							self.createMessageBox('success','Campaign',this.$t('msgCore.dataWasSaved'));
							self.$emit('changeRecord', '')
						
							self.closeModalBoxSecure('myModalSendCampaign');
							
						}else if(response.data.resCode==0){
							self.createMessageBox('warning','Campaign',this.$t('msgCore.dataCantBeSaved'));
							self.closeModalBoxSecure('myModalSendCampaign');
						}else if(response.data.resCode==2){
							self.createMessageBox('danger','Campaign',this.$t('msgCore.dataWasNotSaved'));
							self.closeModalBoxSecure('myModalSendCampaign');
						}else if(response.data.resCode==3){
							self.createMessageBox('danger','Campaign',this.$t('msgCore.accessDenied'));
							self.closeModalBoxSecure('myModalSendCampaign');
						}
						self.campaign = [];
					}
				})
				.catch(error => {
					console.log(error.response)
				}); 
				
				
			}
			
		},
		
		//odomknutie zaznamu
		unlockCampaign:function(campaignId){
			
			this.cSession();
			var bodyFormData = new FormData();
			bodyFormData.set('myRecordID',campaignId);
				
			var self = this;
			axios({
				method: 'post',
				url: '/admins/app/customMailing/MailingCampaigns/controlCampaignUnlock.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
					//console.log(response.data);
					if(response.data==1){
						self.$emit('changeRecord', '')
					}else if(response.data==0){
						//xx
					}else if(response.data==2){
						//xx
					}else if(response.data==3){
						//xx
					}
					
				}
			})
			.catch(error => {
				console.log(error.response)
			}); 
			
		},

	},
	
	watch: {
		campaignId() {
			this.loadDefaultValues();
		}
	},

	computed:{
		
	},
	
})

</script>

<template>
	<div id="msg"></div>
	<div id="myModalSendCampaign" class="fade show" role="dialog" style="z-index: 1040;  display: none;">
			<div class="modal-dialog modal-lg" style="width: 100%; max-width: none;">
				<div v-show="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
				<div class="modal-content">
					<div class="modal-header">
						<h6 class="modal-title">{{ $t('msgCustomMailing.Campaign') }} <b>{{ campaign.title }}</b> </h6>
					</div>
			
							<div v-if="accData.modulAcc && auth.campaignsSend" class="modal-body">
								
								<div class="row">
									<div class="col-12">
										<div v-if="campaign.isCheckedOut && secData.userId != campaign.isCheckedOutId" class="angle">
											<span class="angle-inner" :class="{'angle-warning':campaign.isCheckedOut}"><i class="fa" :class="{'angle-warning':campaign.isCheckedOut, 'fa-exclamation-triangle':campaign.isCheckedOut}" aria-hidden="true"></i> {{$t('msgCore.Edited')}} </span >
											
											<Popper v-if="campaign.isCheckedOut" :content="campaign.isCheckedOutName+', '+campaign.isCheckedOutDate">
												<button class="personCircle v-center" v-bind:style="{background:campaign.isCheckedOutColor}">{{ campaign.isCheckedOutLetterName+''+campaign.isCheckedOutLetterSurname }}</button>
											</Popper>
											
										</div>
									</div>
								</div>

								
							
								<div class="row">
									
									<div class="col-md-12 col-12">
								
										<ul class="nav nav-tabs" role="tablist">
							
											<li class="nav-item">
												<a class="nav-link active" id="homen-tab" data-toggle="tab" href="#homen" role="tab" aria-controls="homen" aria-selected="true">{{ $t('msgCore.basicData') }}</a>
											</li>

											<li v-if="campaign.typeId==1" class="nav-item">
												<a class="nav-link" id="menu333-tab" data-toggle="tab" href="#menu333" role="tab" aria-controls="menu333" aria-selected="false">Email náhľad</a>
											</li>

											<li v-if="campaign.typeId==2" class="nav-item">
												<a class="nav-link" id="menu3334-tab" data-toggle="tab" href="#menu3334" role="tab" aria-controls="menu3334" aria-selected="false">Text SMS</a>
											</li>
											
										</ul>

										<div class="tab-content" id="myTabContent">
								
											<div id="homen" class="tab-pane fade show active" role="tabpanel">
												<div class="row">	
													<div class="col-12" >
														<label><b>Výber kategórie</b> </label>
														<div  v-for="(item) in optionsCategories" :key="item" class="togglebutton">
															<label>
															<input v-model='item.isSelected' type="checkbox" v-on:change="loadDefaultValues()">
															<span class="toggle"></span>
															{{item.title}}
															</label>
														</div>
													</div>
												</div>

												
												
												<div v-if="campaign.typeId==1" class="row">
																									
													<div class="col-12">
														<table class="table table-sm">
															<tr>	
																<td>Emaily vo fronte: {{contactsToSend}}</td>
																<td>Odoslané emaily: {{contactsSent}}</td>
																<td>Vhodné emaily k odoslaniu: {{contactsValid}}</td>
															</tr>
														</table>
													</div>
													
												</div>	

												<div v-if="campaign.typeId==2" class="row">
																									
													<div class="col-12">
														<table class="table table-sm">
															<tr>	
																<td>SMS vo fronte: {{contactsToSend}}</td>
																<td>Odoslané SMS: {{contactsSent}}</td>
																<td>Vhodné SMS k odoslaniu: {{contactsValid}}</td>
															</tr>
														</table>
													</div>
													
												</div>	

											</div>

											<div v-if="campaign.typeId==1" id="menu333" class="tab-pane fade" role="tabpanel">
												<div class="row">
													<div class="col-12" v-html="template.logo"></div>
												</div>
												<div class="row">
													<div class="col-12">
														<table>
															<tr>
																<td v-html="campaign.full_text">

																</td>
															</tr>
														</table>
													</div>
												</div>
												<div class="row">
													<div class="col-12" v-html="template.pata"></div>
												</div>
											</div>


											<div v-if="campaign.typeId==2" id="menu3334" class="tab-pane fade" role="tabpanel">
											
												<div class="row">
													<div class="col-12">
														{{campaign.sms_text}}
													</div>
												</div>
												
											</div>
											
										</div>
										
									</div>
									
																		
																		
								</div>
								
							</div>
							
							<div v-else class="modal-body">
								<div class="row">
									<div class="col-12">
										<div class="alert alert-warning" role="alert">
											{{$t('msgCore.accessDenied')}}
										</div>
									</div>
								</div>
							</div>
							
							<div class="modal-footer">
							
								<div class="btn-group">
									<button type="button" v-on:click="closeModalBoxSecureHelper('myModalSendCampaign',campaign.id)" class="btn btn-default btn-sm">{{ $t('msgCore.btnClose') }}</button>
									
									<button v-if="campaign.typeId==1" type="button" :disabled="contactsValid==0 || !auth.campaignsSend || !accData.modulAcc || btnSaving" v-on:click="sendCampaignEmail()" class="btn btn-warning btn-sm"><span v-if="btnSaving" class="spinner-btn"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></span> {{ $t('msgCustomMailing.PlaceEmailsToFront') }}</button>	

									<button v-if="campaign.typeId==2" type="button" :disabled="contactsValid==0 || !auth.campaignsSend || !accData.modulAcc || btnSaving" v-on:click="sendCampaignSMS()" class="btn btn-warning btn-sm"><span v-if="btnSaving" class="spinner-btn"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></span> {{ $t('msgCustomMailing.PlaceSMSToFront') }}</button>	
								</div>	
								
							</div>
						
				
					
					
				
				</div>

			</div>
			
		</div>
		

	
	

</template>


