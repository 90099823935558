<script>

import Popper from "vue3-popper";
import { useToast } from "vue-toastification";
 /* Default icons are required for TinyMCE 5.3 or above */
 import 'tinymce/icons/default';

 /* A theme is also required */
 import 'tinymce/themes/silver';

 /* Import the skin */
 import 'tinymce/skins/ui/oxide/skin.css';

 /* Import plugins */
 import 'tinymce/plugins/advlist';
 import 'tinymce/plugins/code';
 import 'tinymce/plugins/emoticons';
 import 'tinymce/plugins/emoticons/js/emojis';
 import 'tinymce/plugins/link';
 import 'tinymce/plugins/lists';
 import 'tinymce/plugins/table';


import axios from 'axios';

//general functions
import MixinGeneralFunct from '@/mixins/GeneralFunctions';
import { defineComponent } from 'vue'



export default defineComponent ({
	setup() {
		const toast = useToast();
		return { toast }
	},
	name: 'ContactEdit',
	mixins: [ MixinGeneralFunct ],
	components: {
		
		Popper
	},
	props: {'contactId': String, 'auth':Object, 'secData':Object},
	emits: ["contactId","changeRecord"],
	data() {
	
		return {
			prefix:'',
			
			params:[],
			accData: [],
			
			preloader:true,
			
			isNewRecord:true,

			optionsAccessLevels: [],
			optionsTypes:[],
			optionsCategories:[],
			optionsStates:[],
			optionsLanguages:[],
			
			initObj: {
				
				relative_urls: false
				,convert_urls: false
				,remove_script_host:false
				,height:450
				,entity_encoding : "raw" 
				,language:'sk'
				,extended_valid_elements: 'i[class]'
				,iconfonts_selector: '.fa, .fab, .fal, .far, .fas, .glyphicon'
			},
			
						
			//form
			contact:[]
			
		}
	},
	
	mounted:function(){
		
	},
	
	methods: {
		// load data form
		loadDefaultValues:function(){
			
			this.preloader=true
			//console.log(this.contactId)		
			if(this.contactId=='null'){
				this.isNewRecord=true;
			}else if(this.contactId && this.contactId>0){
				this.isNewRecord=false;
			}
			
			var bodyFormData = new FormData();
			bodyFormData.set('contactId',this.contactId);
			
			var self=this;
			axios({
				method: 'post',
				url: '/admins/app/customMailing/MailingContacts/getContactData.php',
				data: bodyFormData
			})
			.then(function (response) {
			
				//console.log(response.data);
				self.accData = response.data.accData;
				
				//hide loader
				self.preloader=false
				
				self.accData = response.data.accData;
								
				self.contact = response.data.contact;
				
				self.optionsAccessLevels = response.data.optionsAccessLevels;
				self.optionsStates = response.data.optionsStates;
				self.optionsCategories = response.data.optionsCategories;

				//self.optionsTemplates = response.data.optionsTemplates;
				//self.optionsLanguages = response.data.optionsLanguages;
		
			})
				.catch(function (error) {
				console.log(error);
				
				
			});
		
		},
		
		closeModalBoxSecureHelper:function(myform,contactId){
			this.unlockContact(contactId);
			//this.loadDefaultValues();
			this.closeModalBoxSecure(myform);
		},
		
		
		
		
		checkSave:function(){
								
			if(!this.contact.email){this.contact.emailError = true; }else{this.contact.emailError = false;}
			if(!this.contact.access){this.contact.accessError = true; }else{this.contact.accessError = false;}
			
			if(!this.contact.emailError && this.contact.access){
				return true;
			}else{
				return false;
			}
		},	
		
		saveContact:function(){

			if(this.checkSave()){
				
				var bodyFormData = new FormData();
				bodyFormData.set('myContact',JSON.stringify(this.contact));
				bodyFormData.set('myOptionsCategories',JSON.stringify(this.optionsCategories));
				
				var self=this;
				axios({
					method: 'post',
					url: '/admins/app/customMailing/MailingContacts/controlContactSave.php',
					data: bodyFormData
				})
				.then(response => {
					if (response.data.error) {
							console.log('error', response.data.error)
					} else {
					//	console.log('success2', response.data);
						
						if(response.data.resCode==1){
							self.toast.success(this.$t('msgCore.dataWasSaved'));
							self.$emit('changeRecord', '')
							self.closeModalBoxSecure('myModalEditContact');
						}else if(response.data.resCode==0){
							self.toast.warning(this.$t('msgCore.dataCantBeSaved'));
							self.closeModalBoxSecure('myModalEditContact');
						}else if(response.data.resCode==2){
							self.toast.error(this.$t('msgCore.dataWasNotSaved'));
							self.closeModalBoxSecure('myModalEditContact');
						}else if(response.data.resCode==3){
							self.toast.error(this.$t('msgCore.accessDenied'));
							self.closeModalBoxSecure('myModalEditContact');
						}
						self.contact = [];
					}
				})
				.catch(error => {
					console.log(error.response)
					self.toast.error(error.response.status + '|' + error.response.statusText );
				}); 
				
				
			}else{
				this.toast.warning(this.$t('msgCore.fillInAllData'));
			}
			
		},
		
		//odomknutie zaznamu
		unlockContact:function(contactId){
			
			this.cSession();
			var bodyFormData = new FormData();
			bodyFormData.set('myRecordID',contactId);
				
			var self = this;
			axios({
				method: 'post',
				url: '/admins/app/customMailing/MailingContacts/controlContactUnlock.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
					//console.log(response.data);
					if(response.data==1){
						self.$emit('changeRecord', '')
					}else if(response.data==0){
						//xx
					}else if(response.data==2){
						//xx
					}else if(response.data==3){
						//xx
					}
					
				}
			})
			.catch(error => {
				console.log(error.response)
			}); 
			
		},

	},
	
	watch: {
		contactId() {
			this.loadDefaultValues();
		}
	},

	computed:{
		
	},
	
})

</script>

<template>
	<div id="msg"></div>
	<div id="myModalEditContact" class="fade show" role="dialog" style="z-index: 1040;  display: none;">
			<div class="modal-dialog modal-lg" style="width: 100%; max-width: none;">
				<div v-show="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
				<div class="modal-content">
					<div class="modal-header">
						<h6 class="modal-title">{{ $t('msgCore.Contact') }} <span v-if="!contact.isNewRecord"><b>{{ contact.title }}</b> id: {{contact.id}}</span></h6>
					</div>
			
							<div v-if="accData.modulAcc && (auth.contactsNew || auth.contactsEdit)" class="modal-body">
								
								<div class="row">
									<div class="col-12">
										<div v-if="contact.isCheckedOut && secData.userId != contact.isCheckedOutId" class="angle">
											<span class="angle-inner" :class="{'angle-warning':contact.isCheckedOut}"><i class="fa" :class="{'angle-warning':contact.isCheckedOut, 'fa-exclamation-triangle':contact.isCheckedOut}" aria-hidden="true"></i> {{$t('msgCore.Edited')}} </span >
											
											<Popper v-if="contact.isCheckedOut" :content="contact.isCheckedOutName+', '+contact.isCheckedOutDate">
												<button class="personCircle v-center" v-bind:style="{background:contact.isCheckedOutColor}">{{ contact.isCheckedOutLetterName+''+contact.isCheckedOutLetterSurname }}</button>
											</Popper>
											
										</div>
									</div>
								</div>
							
								<div class="row">
									
									<div class="col-md-8 col-12">
								
										<ul class="nav nav-tabs" role="tablist">
							
											<li class="nav-item">
												<a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">{{ $t('msgCore.basicData') }}</a>
											</li>
											
											<li class="nav-item">
												<a class="nav-link" id="menu4-tab" data-toggle="tab" href="#menu4" role="tab" aria-controls="menu4" aria-selected="false">Kategórie</a>
											</li>
											
										</ul>

										<div class="tab-content" id="myTabContent">
								
											<div id="home" class="tab-pane fade show active" role="tabpanel">
															
									
												<div class="form-row">
																								
													<div class="form-group col-md-4 col-12">
														<label ><b>{{ $t('msgCore.Firstname') }}</b> </label>
														<input type="text" class="form-control form-control-sm" v-model="contact.firstname" >
														
													</div>
													
													<div class="form-group col-md-4 col-12">
														<label ><b>{{ $t('msgCore.Surname') }}</b> </label>
														<input type="text" class="form-control form-control-sm" v-model="contact.surname" >
														
													</div>
												</div>

												<div class="form-row">
													<div class="form-group col-md-4 col-12">
														<label ><b>{{ $t('msgCore.Mobil') }}</b> </label>
														<input type="text" class="form-control form-control-sm" v-model="contact.mobil" >
														
													</div>

													<div class="form-group col-md-4 col-12">
														<label :class="{'text-danger':contact.emailError}"><b>{{ $t('msgCore.Email') }}</b> </label>
														<input type="text" class="form-control form-control-sm" :class="{'is-invalid':contact.emailError}" v-on:change="checkSave()" v-model="contact.email" >
														<div v-show="contact.emailError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.fillIn')}}</span></div>
													</div>
												
												</div>

												<div class="form-row">
													<div class="form-group col-md-4 col-12">
														<label ><b>{{ $t('msgCore.Mobil') }}</b> </label>
														<input type="text" class="form-control form-control-sm" v-model="contact.mobil2" >
													</div>

													<div class="form-group col-md-4 col-12">
														<label ><b>{{ $t('msgCore.Email') }}</b> </label>
														<input type="text" class="form-control form-control-sm" v-model="contact.email2" >
													</div>
												</div>

												<div class="form-row">
													<div class="form-group col-md-4 col-12">
														<label ><b>{{ $t('msgCore.Mobil') }}</b> </label>
														<input type="text" class="form-control form-control-sm" v-model="contact.mobil3" >
													</div>

													<div class="form-group col-md-4 col-12">
														<label ><b>{{ $t('msgCore.Email') }}</b> </label>
														<input type="text" class="form-control form-control-sm" v-model="contact.email3" >
													</div>
												</div>

												<div class="form-row">
													<div class="form-group col-md-4 col-12">
														<label ><b>{{ $t('msgCore.Mobil') }}</b> </label>
														<input type="text" class="form-control form-control-sm" v-model="contact.mobil4" >
													</div>

													<div class="form-group col-md-4 col-12">
														<label ><b>{{ $t('msgCore.Email') }}</b> </label>
														<input type="text" class="form-control form-control-sm" v-model="contact.email4" >
													</div>
												</div>

												<div class="form-row">
													<div class="form-group col-md-4 col-12">
														<label ><b>{{ $t('msgCore.Mobil') }}</b> </label>
														<input type="text" class="form-control form-control-sm" v-model="contact.mobil5" >
													</div>

													<div class="form-group col-md-4 col-12">
														<label ><b>{{ $t('msgCore.Email') }}</b> </label>
														<input type="text" class="form-control form-control-sm" v-model="contact.email5" >
													</div>
												</div>


												
												<div class="form-row">
													
													<div class="form-group col-md-4 col-12">
														<label ><b>{{ $t('msgCore.Company') }}</b> </label>
														<input type="text" class="form-control form-control-sm" v-model="contact.company" >
														
													</div>

													<div class="form-group col-md-4 col-12">
														<label ><b>{{ $t('msgCore.Ico') }}</b> </label>
														<input type="text" class="form-control form-control-sm" v-model="contact.ico" >
														
													</div>
												
												</div>	

												<div class="form-row">
													
													<div class="form-group col-md-4 col-12">
														<label ><b>{{ $t('msgCore.City') }}</b> </label>
														<input type="text" class="form-control form-control-sm" v-model="contact.city" >
														
													</div>

													<div class="form-group col-md-4 col-12">
														<label ><b>{{ $t('msgCore.Street') }}</b> </label>
														<input type="text" class="form-control form-control-sm" v-model="contact.street" >
														
													</div>

													<div class="form-group col-md-4 col-12">
														<label ><b>{{ $t('msgCore.PSC') }}</b> </label>
														<input type="text" class="form-control form-control-sm" v-model="contact.psc" >
														
													</div>
												
												</div>	

												<div class="form-row">
													
													<div class="form-group col-md-4 col-12">
														<label ><b>{{ $t('msgCore.Web') }}</b> </label>
														<input type="text" class="form-control form-control-sm" v-model="contact.web" >
														
													</div>

													<div class="form-group col-md-4 col-12">
														<label ><b>{{ $t('msgCore.Address') }}</b> </label>
														<input type="text" class="form-control form-control-sm" v-model="contact.address" >
														
													</div>

												</div>	

											</div>

											<div id="menu4" class="tab-pane fade" role="tabpanel">
												<div class="form-row">	
													<div class="col-12" >
														<label><b>Výber kategórie</b> </label>
														<div  v-for="(item) in optionsCategories" :key="item" class="togglebutton">
															<label>
															<input v-model='item.isSelected' type="checkbox">
															<span class="toggle"></span>
															{{item.title}}
															</label>
														</div>
													</div>
												</div>
											</div>
											
											
											
											
												
										</div>
										
									</div>
									
									<div class="col-md-4 col-12 form-control-system-data">
											
										<div class="form-row">
										
											<div class="form-group col-12">
												<label :class="{'text-danger':contact.accessError}"><b>{{ $t('msgCore.AccessLevel') }}</b></label>
																									
												<select v-model='contact.access' class="form-control form-control-sm " :class="{'is-invalid':contact.accessError}" v-on:change="checkSave()">
													<option value="">{{ $t('msgCore.selectLevel') }}</option>
													<option v-for="(option) in optionsAccessLevels" :value="option.value" :key="option">{{ option.title }}</option>
												</select>
												<div v-show="contact.accessError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.selectLevel')}}</span></div>
											</div>
											
											<div class="form-group col-12">
												<label :class="{'text-danger':contact.stateError}"><b>{{ $t('msgCore.State') }}</b></label>
												
												<div  v-for="(option) in optionsStates" :key="option.value" class="form-check">
													<label class="form-check-label">
														<input class="form-check-input" type="radio" name="optionsStates"  v-model='contact.state' v-on:change="checkSave()" :value="option.value">
														{{ $t('msgCore.'+option.title) }}
														<span class="circle">
															<span class="check"></span>
														</span>
													</label>
												</div>
												<div v-show="contact.stateError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.selectState')}}</span></div>
											</div>
											
											
										
										</div>
										
									</div>										
																		
								</div>
								
							</div>
							
							<div v-else class="modal-body">
								<div class="row">
									<div class="col-12">
										<div class="alert alert-warning" role="alert">
											{{$t('msgCore.accessDenied')}}
										</div>
									</div>
								</div>
							</div>
							
							<div class="modal-footer">
							
								<div class="btn-group">
									<button type="button" v-on:click="closeModalBoxSecureHelper('myModalEditContact',contact.id)" class="btn btn-default btn-sm">{{ $t('msgCore.btnClose') }}</button>
									
									<button type="button" :disabled="!auth.contactsEdit || !accData.modulAcc" v-on:click="saveContact()" class="btn btn-warning btn-sm">{{ $t('msgCore.btnSave') }}</button>	
								</div>	
								
							</div>
						
				
					
					
				
				</div>

			</div>
			
		</div>
		

	
	

</template>


