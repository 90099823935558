
<script>

import { defineComponent } from 'vue'

import Popper from "vue3-popper";

import axios from 'axios';

export default defineComponent({
  name: 'ComponentLibrary',
	data() {
		return {
			result: [],
			library: [],
			documents: [],
		}
	},
  props: {
	'content': Object
  },
  emits: ["content","changeContent"],
  components: {
    Popper
  }
  ,mounted () {
	this.loadOptions();
	if(this.content.fields.libraryId){
		this.loadPreview();
	}
  },
  
	methods: {
		// load data form
		loadOptions:function(){
			
			var self=this;
			axios({
				method: 'post',
				url: '/admins/app/core/Library/getLibraryOptions.php'
			})
			.then(function (response) {
			
				console.log(response.data);
				
				//hide loader
				self.preloader=false
				
				self.accData = response.data.accData;
								
				self.result = response.data.result;
						
			})
				.catch(function (error) {
				console.log(error);
			
			});
		
		},

		loadPreview:function(){
			
			var bodyFormData = new FormData();
			bodyFormData.set('libraryId',this.content.fields.libraryId);

			var self=this;
			axios({
				method: 'post',
				url: '/admins/app/core/Library/getLibraryPreviewData.php',
				data: bodyFormData
			})
			.then(function (response) {

				console.log(response.data);

				self.library = response.data.library;
				self.documents = response.data.documents;

			})
				.catch(function (error) {
				console.log(error);
			
			});
		
		},

		loadContent:function(){
		
			this.content.content = JSON.stringify(this.content.fields);

			this.$emit('changeContent', this.content)
		}
	},
	
	watch: {
       
	},
	
	created() {

	}
})
</script>

<template>
	<div>
	  <div class="form-group col-md-4 col-12">
		  <label><b>{{ $t('msgCore.Library') }}</b></label>
		  <select v-model='content.fields.libraryId' class="form-control form-control-sm" v-on:change="loadContent();loadPreview();" >
			  <option  value="">{{ $t('msgCore.selectLibrary') }}</option>
			  <option v-for="(option) in result.optionsLibraries" :value="option.value" :key="option">{{ option.title }}</option>
		  </select>
	  </div>


		<div v-if="documents.length>0" class="col-12">
		
			<table class="table table-hover table-sm">
						
				<thead>
					<tr>
						<th>ID</th>
						<th></th>
						
						

						
						
						<th>{{$t('msgCore.Title')}}</th>
						
						<th>{{$t('msgCore.Extension')}}</th>
						
						<th>{{$t('msgCore.Size')}}</th>
						
						<th>{{$t('msgCore.Created')}}</th>
						
						<th>{{$t('msgCore.Modified')}}</th>

						<th>{{$t('msgCore.AccessLevel')}}</th>									
					</tr>
				</thead>
		
			
				<tr v-for="document in documents" :key="document" >
					<td>{{document.id}}</td>
					
					

					<td  :title="$t('msgCore.' + document.state_name)">
						<i class="fas fa-circle" :class="{'text-success':document.state==1,'text-danger':document.state==2}" ></i>
					</td>	

				
						
					<td><a :href="document.url">{{document.title}}</a></td>
					
					<td>{{document.extension}}</td>
					
					<td>{{document.fileSize}}</td>
					
					<td>
						<Popper :content="document.creator+', '+document.createdView">
							<button class="personCircle v-center" v-bind:style="{background:document.creatorColor}">{{ document.creatorNameLetter+''+document.creatorSurnameLetter }}</button>
						</Popper>
					</td>

					<td>
						<Popper v-if="document.editor" :content="document.editor+', '+document.modifiedView">
							<button class="personCircle v-center" v-bind:style="{background:document.editorColor}">{{ document.editorNameLetter+''+document.editorSurnameLetter }}</button>
						</Popper>
					</td>
					
					<td>{{document.access_name}}</td>	
				
				</tr>
			
			</table>
			
		</div>


	</div>
  </template>