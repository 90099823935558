<script>

//import { library } from '@fortawesome/fontawesome-svg-core';
//import { faPen, faArrowAltCircleRight, faSignOutAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faComment } from '@fortawesome/free-solid-svg-icons';
//library.add(faPen,faArrowAltCircleRight,faSignOutAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faComment)
//import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
//import $ from 'jquery'; 
import { useToast } from "vue-toastification";
import axios from 'axios';
//import { VideoPlayer } from 'vue-video-player'

//general functions
import MixinGeneralFunct from '@/mixins/GeneralFunctions';
import { defineComponent } from 'vue'
//import axios from 'axios';

export default defineComponent ({
	setup() {
		const toast = useToast();
		return { toast }
	},
	name: 'ParameterDelete',
	mixins: [ MixinGeneralFunct ],
	components: {
		//"font-awesome-icon":FontAwesomeIcon
		//,"video-player":VideoPlayer
	},
	props: {'parameterId': String, 'auth':Object, 'secData':Object},
	emits: ["parameterId","changeRecord"],
	data() {
		return {
			prefix:'',
			
			params:[],
			accData: [],
			
			preloader:true,
			
			//form
			parameter:[]
		}
	},
	
	mounted:function(){
		
		//console.log(this.parameterId)
		
		//console.log(this.$route.path)
	},
	
	methods: {
	
		// load data form
		loadDefaultValues:function(){
			
			this.preloader=true
			
			//console.log(this.parameterId);				
			if(this.parameterId){
				this.isNewRecord=false;
			}else{
				this.isNewRecord=true;
			}
			
			var bodyFormData = new FormData();
			bodyFormData.set('parameterId',this.parameterId);
			
			var self=this;
			axios({
				method: 'post',
				url: '/admins/app/core/Parameter/getParameterData.php',
				data: bodyFormData
			})
			.then(function (response) {
			
				//console.log(response.data);
				self.accData = response.data.accData;
				
				//hide loader
				self.preloader=false
				
				self.accData = response.data.accData;
								
				self.parameter = response.data.parameter;
				
			})
				.catch(function (error) {
				console.log(error);
				
				
			});
		
		},
		
		closeModalBoxSecureHelper:function(myform,parameterId){
			this.unlockParameter(parameterId);
			//this.loadDefaultValues();
			this.closeModalBoxSecure(myform);
		},
		
		
		
		//odomknutie zaznamu
		unlockParameter:function(parameterId){
			this.cSession();
			var bodyFormData = new FormData();
			bodyFormData.set('myRecordID',parameterId);
				
			var self = this;
			axios({
				method: 'post',
				url: '/admins/app/core/Parameter/controlParameterUnlock.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
					//console.log(response.data);
					if(response.data==1){
						self.$emit('changeRecord', '')
					}else if(response.data==0){
						//xx
					}else if(response.data==2){
						//xx
					}else if(response.data==3){
						//xx
					}
				}
			})
			.catch(error => {
				console.log(error.response)
			}); 
			
		},
		
		deleteParameter:function(parameterId){
			
			if(parameterId){
				
				var bodyFormData = new FormData();
				bodyFormData.set('myParameter',JSON.stringify(this.parameter));
				
				var self=this;
				axios({
					method: 'post',
					url: '/admins/app/core/Parameter/controlParameterDelete.php',
					data: bodyFormData
				})
				.then(response => {
					if (response.data.error) {
							console.log('error', response.data.error)
					} else {
						//console.log('success2', response.data);
						if(response.data==1){
							self.toast.success(this.$t('msgCore.dataWasDeleted'));
							self.$emit('changeRecord', '')
							self.closeModalBoxSecure('myModalDeleteParameter');
						}else if(response.data==0){
							self.toast.warning(this.$t('msgCore.dataCantBeDeleted'));
							self.closeModalBoxSecure('myModalDeleteParameter');
						}else if(response.data==2){
							self.toast.error(this.$t('msgCore.dataWasNotDeleted'));
							self.closeModalBoxSecure('myModalDeleteParameter');
						}else if(response.data==3){
							self.toast.error(this.$t('msgCore.accessDenied'));
							self.closeModalBoxSecure('myModalDeleteParameter');
						}
						self.group = [];
					}
				})
				.catch(error => {
					console.log(error.response)
				}); 
				
			}
			
		}
	
		
	},
	
	watch: {
		parameterId() {
			this.loadDefaultValues();
		}
	},

	computed:{
		
	},
	
})

</script>

<template>
	<div id="msg"></div>
	
		<div id="myModalDeleteParameter" class="fade show " role="dialog" style="z-index: 1040;  display: none;">
			<div class="modal-dialog modal-lg" style="width:100%; max-width: none;">
				<div v-show="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
				<div class="modal-content">
					<div class="modal-header">
						<h6 class="modal-title">{{ $t('msgCore.DELETE_RECORD') }}</h6>
					</div>
			
						<div v-if="accData.modulAcc && (auth.parametersDelete)" class="modal-body">
									
									
							<div class="form-row">
							
								
								<div class="form-group col-md-2 col-12">
																																			
									<label><b>{{ $t('msgCore.ID') }}</b></label><br>
									{{parameter.id}}
								</div>
								
								<div class="form-group col-md-10 col-12">
																																			
									<label><b>{{ $t('msgCore.Title') }}</b></label><br>
									{{ parameter.title }}
								</div>
								
								<div class="form-group col-12">
																																			
									<label><b>{{ $t('msgCore.Value') }}</b></label><br>
									{{ parameter.value }}
								</div>
								
																				
								<div class="form-group col-md-6 col-12">
									<label for="sum"><b>{{ $t('msgCore.AccessLevel') }}</b> </label><br>
									{{ parameter.access_name }}
								</div>
																	
							</div>	
						
						</div>
						
						<div v-else class="modal-body">
							<div class="row">
								<div class="col-12">
									<div class="alert alert-warning" role="alert">
										{{$t('msgCore.accessDenied')}}
									</div>
								</div>
							</div>
						</div>
													
						<div class="modal-footer">
						
							<div class="btn-group">
								<button type="button" v-on:click="closeModalBoxSecureHelper('myModalDeleteParameter',parameter.id)" class="btn btn-default btn-sm">{{ $t('msgCore.btnClose') }}</button>

								<button type="button" :disabled="(!auth.parametersDelete) || !accData.modulAcc" v-on:click="deleteParameter(parameter.id)" class="btn btn-danger btn-sm">{{ $t('msgCore.btnDelete') }}</button>
							</div>	
							
						</div>
						
				</div>

			</div>
			
		</div>
	
		
</template>


