<script>


import {ref} from 'vue'
import axios from 'axios';
import { useToast } from "vue-toastification";

import FileUpload from 'vue-upload-component'

	
//general functions
import MixinGeneralFunct from '@/mixins/GeneralFunctions';

export default {
	name: 'UploadPhoto',
	mixins: [ MixinGeneralFunct ],
	components: {
		FileUpload 
	},
	props: {'projectId': String,'auth':Object},
	emits: ["projectId","changeAfterUpload"],

	setup(_,{emit}) {

		const toast = useToast();
		

		//console.log(props);
		const upload = ref(null)
		
		const files = ref([])
		function inputFilter(newFile, oldFile, prevent) {
			if (newFile && !oldFile) {
				// Before adding a file
				// 添加文件前
				// Filter system files or hide files
				// 过滤系统文件 和隐藏文件
				if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
				return prevent()
				}
				
				// Filter php html js file
				// 过滤 php html js 文件
				if (/\.(php?|php5?|html?|jsx?)$/i.test(newFile.name)) {
				return prevent()
				}
			}
		}

		function inputFile(newFile, oldFile) {
				//console.log('response', newFile.response)
				if (newFile && !oldFile) {
					// add
					//console.log('add', newFile)
					newFile.sizeView=niceBytes(newFile.size);
				}
				if (newFile && oldFile) {
					// update
					//console.log('update', newFile)
					newFile.sizeView=niceBytes(newFile.size);
				}
				if (!newFile && oldFile) {
					// remove
					//console.log('remove', oldFile)
				}

				emit('changeAfterUpload');
							
			}
			return {
			files,
			upload,
			inputFilter,
			inputFile,
			toast
		}

		function niceBytes(x){
			const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
			let l = 0, n = parseInt(x, 10) || 0;
			while(n >= 1024 && ++l){
				n = n/1024;
			}
			return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
		}
	},

	
	data() {
		return {
			prefix:'',
			
			params:[],
			accData: [],
			secData: [],
			preloader:true,
			
			//form
			project:[],
			author:'',
			access:'10',
			accessError:false,
						
			optionsAccessLevels:[],

			//files:[]
		}
	},
	
	mounted:function(){
		
	},
	
	methods: {
		// load data form
		loadDefaultValues:function(){
			//console.log(this.projectId)
			this.preloader=true
			
			var bodyFormData = new FormData();
			bodyFormData.set('projectId',this.projectId);
		
			var self=this;
			axios({
				method: 'post',
				url: '/admins/app/customWorking/WorkingProjects/projectPhotoUpload/getUploadData.php',
				data: bodyFormData
			})
			.then(function (response) {
			
				//console.log(response.data);
				self.accData = response.data.accData;
				
				//hide loader
				self.preloader=false
				
				self.project = response.data.project;
				self.access = response.data.access;
				self.optionsAccessLevels = response.data.optionsAccessLevels;
		
			})
				.catch(function (error) {
				console.log(error);
				
				
			});
		
		},
		
		closeModalBoxSecureHelper:function(myform){
		
			this.closeModalBoxSecure(myform);
			this.gallery=[];
			this.author='';
			
			//this.showUploadSpace = false;
		},
		
	

		checkUpload:function(){
			
			if(!this.access){this.accessError = true; }else{this.accessError = false;}
			
			if(!this.accessError){
				return true;
			}else{
				this.toast.warning(this.$t('msgCore.fillInAllData'));
				return false;
			}
		},

		
		
	},
	
	watch: {
		projectId() {
			this.loadDefaultValues();
		}
	},

	computed:{
		
	},
	
}

</script>

<template>
	<div id="msg"></div>
	<div id="myModalUploadPhotos" class="fade show " role="dialog" style="z-index: 1040;  display: none;">
		<div class="modal-dialog modal-lg" style="width: 100%; max-width: none;">
			<div class="modal-content">
				<div class="modal-header">
					<h6 class="modal-title">{{ $t('msgCore.Upload') }} <span><b>{{ project.title }}</b> </span></h6>
				</div>
		
				<div class="modal-body">
					
					<div class="row">
								
						<div class="col-12">
					
							<div class="form-row">
								
								<div class="form-group col-md-4 col-12">
									<label><b>{{ $t('msgCore.AccessLevel') }}</b></label>
									<select v-model='access' class="form-control form-control-sm" :class="{'is-invalid':accessError}" v-on:change="checkUpload()" >
										<option  value="">{{ $t('msgCore.selectLevel') }}</option>
										<option v-for="(option) in optionsAccessLevels" :value="option.value" :key="option.value">{{ option.title }}</option>
									</select>
									<div v-show="accessError" class="error-input">Vyberte úroveň</div>
								</div>

								<div class="form-group col-md-4 col-12">
									<label><b>{{ $t('msgCore.author') }}</b> (voliteľný)</label>
									<div class="input-group">
										<input type="text" class="form-control form-control-sm" v-model='author'>
										
									</div>
								</div>	
								
							</div>
					
						
					
							<div class="row" v-if="this.checkUpload()">
								<div class="col-12">
									<file-upload
									
									class="btn btn-primary btn-sm"
									post-action="/admins/app/customWorking/WorkingProjects/projectPhotoUpload/controlPhotoUpload.php"
									extensions="jpg,jpeg,png,webp"
									accept="image/png,image/jpeg,image/webp"
									:data="{projectId:this.projectId,author:this.author,access:this.access}"
									
									:multiple="true"
									
									v-model="files"
									@input-filter="inputFilter"
									@input-file="inputFile"
									ref="upload">
									<i class="fa fa-plus"></i>
									Vyberte súbory
									</file-upload>
								</div>
							</div>

							<div class="row">
								<div class="col-12">
									<table class="table table-hover">
											<tr>
												<th>Názov</th>	
												<th>Typ</th>	
												<th>Veľkosť</th>	
												<th>Chyba</th>	
												<th>Progress</th>	
												<th>Stav nahratia</th>
											</tr>
											<tr v-for="file in files" :key="file">
												<td>{{file.name}}</td>
												<td>{{file.type}}</td>
												<td>{{file.sizeView}}</td>
												<td>{{file.error}}</td>
												<td>
													<div class="progress" v-if="file.active || file.progress !== '0.00'">
													<div :class="{'progress-bar': true, 'progress-bar-striped': true, 'bg-danger': file.error, 'progress-bar-animated': file.active}" role="progressbar" :style="{width: file.progress + '%'}">{{file.progress}}%</div>
													</div>
												</td>	
												<td><i v-if="file.success" class="fa fa-check text-success"></i><i v-if="!file.success" class="fa fa-times text-danger"></i></td>
											</tr>
										
									</table>
								</div>
							</div>

							<div class="row">
								<div class="col-12">
									<button type="button" class="btn btn-success btn-sm" v-if="this.checkUpload() && (!$refs.upload || !$refs.upload.active)" @click.prevent="$refs.upload.active = true">
									<i class="fa fa-arrow-up" aria-hidden="true"></i>
									Začať nahrávať
									</button>
							
								</div>
							</div>
					
						</div>	
										
												
						
						
					</div>
					
				</div>
				
				<div class="modal-footer">
					<div class="btn-group">
						<button type="button" v-on:click="closeModalBoxSecureHelper('myModalUploadPhotos',null)" class="btn btn-default btn-sm">{{ $t('msgCore.btnClose') }}</button>
					</div>	
				</div>
					
			</div>

		</div>
		
	</div>
	
</template>


