<script>


//import $ from 'jquery'; 

import Popper from "vue3-popper";
import { useToast } from "vue-toastification";

//import TaskSelectIntoPhoto from '@/components/Core/Tasks/TaskSelectIntoPhoto'





import axios from 'axios';

//general functions
import MixinGeneralFunct from '@/mixins/GeneralFunctions';
import { defineComponent } from 'vue'



export default defineComponent ({
	setup() {
		const toast = useToast();
		return { toast }
	},
	name: 'TaskEdit',
	mixins: [ MixinGeneralFunct ],
	components: {
		Popper
	},
	props: {'taskId': String, 'auth':Object, 'secData':Object},
	emits: ["taskId","changeRecord"],
	data() {
	
		return {
			prefix:'',
			
			params:[],
			accData: [],
			
			preloader:true,
			
			isNewRecord:true,

			optionsAccessLevels: [],
			optionsTypes:[],
			optionsTemplates:[],
			optionsStates:[],
			optionsLanguages:[],
			
						
						
			//form
			task:[],

			optionsTaskTypes: [],
			optionsProjects: [],
			optionsTaskAccountStates: [],
			
		}
	},
	
	mounted:function(){
		
	},
	
	methods: {
		// load data form
		loadDefaultValues:function(){
			
			this.preloader=true
			//console.log(this.taskId)		
			if(this.taskId=='null'){
				this.isNewRecord=true;
			}else if(this.taskId && this.taskId>0){
				this.isNewRecord=false;
			}
			
			var bodyFormData = new FormData();
			bodyFormData.set('taskId',this.taskId);
			
			
			var self=this;
			axios({
				method: 'post',
				url: '/admins/app/customWorking/WorkingTasks/getTaskData.php',
				data: bodyFormData
			})
			.then(function (response) {
			
				//console.log(response.data);
				self.accData = response.data.accData;
				
				//hide loader
				self.preloader=false
				
				self.accData = response.data.accData;
								
				
				self.task = response.data.task;
			
				self.optionsTaskTypes = response.data.optionsTaskTypes;
				self.optionsProjects = response.data.optionsProjects;
				self.optionsTaskAccountStates = response.data.optionsTaskAccountStates;
		
			})
				.catch(function (error) {
				console.log(error);
				
				
			});
		
		},

		
		
		closeModalBoxSecureHelper:function(myform,taskId){
			this.unlockTask(taskId);
			//this.task=[];
			//this.loadDefaultValues();
			this.closeModalBoxSecure(myform);
		},
		
		
		
		
		

		checkSave:function(){
								
			if(!this.task.title){this.task.titleError = true; }else{this.task.titleError = false;}
			if(!this.task.status){this.task.statusError = true; }else{this.task.statusError = false;}
			if(!this.task.type){this.task.typeError = true; }else{this.task.typeError = false;}
			if(!this.task.projectId){this.task.projectError = true; }else{this.task.projectError = false;}

			if(!this.task.titleError && !this.task.statusError && !this.task.typeError && !this.task.projectError){
				return true;
			}else{
				return false;
			}
		},	
		
		saveTask:function(){

			if(this.checkSave()){
				
				var bodyFormData = new FormData();
				bodyFormData.set('myTask',JSON.stringify(this.task));
				
				
				var self=this;
				axios({
					method: 'post',
					url: '/admins/app/customWorking/WorkingTasks/controlTaskSave.php',
					data: bodyFormData
				})
				.then(response => {
					if (response.data.error) {
							console.log('error', response.data.error)
					} else {
						//console.log(response.data);
						if(response.data.resCode==1){
							self.toast.success(this.$t('msgCore.dataWasSaved'));
							self.$emit('changeRecord', '')
						
							self.closeModalBoxSecure('myModalEditTask');
							
						}else if(response.data.resCode==0){
							self.toast.warning(this.$t('msgCore.dataCantBeSaved'));
							self.closeModalBoxSecure('myModalEditTask');
						}else if(response.data.resCode==2){
							self.toast.error(this.$t('msgCore.dataWasNotSaved'));
							self.closeModalBoxSecure('myModalEditTask');
						}else if(response.data.resCode==3){
							self.toast.error(this.$t('msgCore.accessDenied'));
							self.closeModalBoxSecure('myModalEditTask');
						}
						self.task = [];
					}
				})
				.catch(error => {
					console.log(error.response)
				}); 
				
				
			}else{
				this.toast.warning(this.$t('msgCore.fillInAllData'));
			}
			
		},
		
		//odomknutie zaznamu
		unlockTask:function(taskId){
			
			this.cSession();
			var bodyFormData = new FormData();
			bodyFormData.set('myRecordID',taskId);
				
			var self = this;
			axios({
				method: 'post',
				url: '/admins/app/customWorking/WorkingTasks/controlTaskUnlock.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
					//console.log(response.data);
					if(response.data==1){
						self.$emit('changeRecord', '')
					}else if(response.data==0){
						//xx
					}else if(response.data==2){
						//xx
					}else if(response.data==3){
						//xx
					}
					
				}
			})
			.catch(error => {
				console.log(error.response)
			}); 
			
		},

		//get count chars
	/*	getCountChars:function(){
			this.countCharQuestion=this.countCharQuestionLimit - this.question.title.length;
			if(this.countCharQuestion<0){this.question.countCharError = true; }else{this.question.countCharError = false;}
		},*/

	},
	
	watch: {
		taskId() {
			this.loadDefaultValues();
		}
	},

	computed:{
		
	},
	
})

</script>

<template>
	<div id="msg"></div>
	<div id="myModalEditTask" class="fade show" role="dialog" style="z-index: 1040;  display: none;">
			<div class="modal-dialog modal-lg" style="width: 100%; max-width: none;">
				<div v-show="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
				<div class="modal-content">
					<div class="modal-header">
						<h6 class="modal-title">{{ $t('msgCustomWorking.Task') }} <span v-if="!task.isNewRecord"><b>{{ task.title }}</b> id: {{task.id}}</span></h6>
					</div>
			
							<div v-if="accData.modulAcc && (auth.tasksEdit || auth.tasksNew)" class="modal-body">
											
								<div class="row">
									<div class="col-12">
										<div v-if="task.isCheckedOut && secData.userId != task.isCheckedOutId" class="angle">
											<span class="angle-inner" :class="{'angle-warning':task.isCheckedOut}"><i class="fa" :class="{'angle-warning':task.isCheckedOut, 'fa-exclamation-triangle':task.isCheckedOut}" aria-hidden="true"></i> {{$t('msgCore.Edited')}} </span >
											
											<Popper v-if="task.isCheckedOut" :content="task.isCheckedOutName+', '+task.isCheckedOutDate">
												<button class="personCircle v-center" v-bind:style="{background:task.isCheckedOutColor}">{{ task.isCheckedOutLetterName+''+task.isCheckedOutLetterSurname }}</button>
											</Popper>
											
										</div>
									</div>
								</div>

									<div class="form-row">
										
										<div class="form-group col-md-2 col-3">
											<label><b>{{ $t('msgCore.ID') }}</b></label><br>
											{{task.id}}
										</div>
										
										
										<div class="form-group col-md-10 col-12">
											
											<label :class="{'text-danger':task.titleError}"><b>{{ $t('msgCore.title') }}</b> </label>
											<input type="text" class="form-control form-control-sm" :class="{'is-invalid':task.titleError}" v-model="task.title" v-on:change="checkSave()" >
											<div v-show="task.titleError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.fillIn')}}</span></div>
										</div>
										
										<div class="form-group col-12">
											<label><b>{{ $t('msgCore.request') }}</b> </label>
											<textarea class="form-control form-control-sm" v-model="task.request" rows="4"></textarea>
										</div>
										
										<div class="form-group col-md-12 col-12">
											<label><b>{{ $t('msgCore.description') }}</b> </label>
											<input type="text" class="form-control form-control-sm" v-model="task.description" >
										</div>
										
																					
										<div class="form-group col-md-4 col-12">
											<label :class="{'text-danger':task.typeError}"><b>{{ $t('msgCore.Type') }}</b></label>
																								
											<select v-model='task.type' class="form-control form-control-sm "  :class="{'is-invalid':task.typeError}" v-on:change="checkSave()">
												<option  value="">{{ $t('msgCustomWorking.selectType') }}</option>
												<option v-for="(option) in optionsTaskTypes" :key="option" :value="option.value">{{ $t('msgCustomWorking.'+option.title) }}</option>
											</select>
											<div v-show="task.typeError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.selectType')}}</span></div>
										</div>
										
										<div class="form-group col-md-4 col-12">
											<label :class="{'text-danger':task.projectError}"><b>{{ $t('msgCustomWorking.Project') }}</b></label>
																								
											<select v-model='task.projectId' class="form-control form-control-sm "  :class="{'is-invalid':task.projectError}" v-on:change="checkSave()">
												<option  value="">{{ $t('msgCustomWorking.selectProject') }}</option>
												<option v-for="(option) in optionsProjects" :key="option" :value="option.value">{{ option.title }}</option>
											</select>
											<div v-show="task.projectError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCustomWorking.selectProject')}}</span></div>
										</div>
										
										<div class="form-group col-md-4 col-12">
											<label :class="{'text-danger':task.statusError}"><b>{{ $t('msgCore.Status') }}</b></label>
																								
											<select v-model='task.status' class="form-control form-control-sm "  :class="{'is-invalid':task.statusError}" v-on:change="checkSave()">
												<option  value="">{{ $t('msgCustomWorking.selectStatus') }}</option>
												<option v-for="(option) in optionsTaskAccountStates" :key="option" :value="option.value">{{ $t('msgCustomWorking.'+option.title) }}</option>
											</select>
											<div v-show="task.statusError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCustomWorking.selectStatus')}}</span></div>
										</div>
										
										
									</div>	
								
									
							</div>
							
							<div v-else class="modal-body">
								<div class="row">
									<div class="col-12">
										<div class="alert alert-warning" role="alert">
											{{$t('msgCore.accessDenied')}}
										</div>
									</div>
								</div>
							</div>
							
							
							
							
							<div class="modal-footer">
							
								<div class="btn-group">
									<button type="button" v-on:click="closeModalBoxSecureHelper('myModalEditTask')" class="btn btn-default btn-sm">{{ $t('msgCore.btnClose') }}</button>
									
									<button type="button" :disabled="((!auth.tasksEdit) || (!auth.tasksNew)) || !accData.modulAcc" v-on:click="saveTask()" class="btn btn-warning btn-sm">{{ $t('msgCore.btnSave') }}</button>	
								</div>	
								
							</div>
						
				
					
					
				
				</div>

			</div>
			
		</div>
		

	
	

</template>


