
<script>

import { defineComponent } from 'vue'

import Editor from '@tinymce/tinymce-vue'

export default defineComponent({
  name: 'ComponentTextEditor',
	data() {
		return {
      obsahClanku:""
			,initObj: {
				relative_urls: false
				,convert_urls: false
				,remove_script_host:false
				,height:400
				,entity_encoding : "raw" 
				,language:'sk'
				,extended_valid_elements: 'i[class]'
				,iconfonts_selector: '.fa, .fab, .fal, .far, .fas, .glyphicon'
        ,plugins: 'wordcount, advlist, autolink, link, image, lists, charmap, print, preview, hr, anchor, pagebreak, spellchecker, searchreplace, visualblocks, visualchars, code, fullscreen, insertdatetime, media, nonbreaking, save, table, directionality, emoticons, template, paste'
        ,toolbar: 'alignjustify aligncenter'
			},
		}
	},
  props: {
	'content': Object
  },
  emits: ["content","changeContent"],
  components: {
    'editor': Editor
  }
  ,mounted () {
    this.obsahClanku = this.content.fields.textEditor;
  },
  
	methods: {
    loadContent:function(){
  
      this.content.fields.textEditor=this.obsahClanku;
      
      this.content.content = JSON.stringify(this.content.fields);
    
      this.$emit('changeContent', this.content)
    }
	},
	
	watch: {
    
	},
	
	created() {

	}
  
})
</script>

<template>
  <div>
    <editor tinymce-script-src="tinymce/tinymce.min.js" v-model="obsahClanku" :init="initObj"  @selectionChange="loadContent" />
  </div>
</template>