<script>

//import { library } from '@fortawesome/fontawesome-svg-core';
//import { faBars, faVideo, faUserFriends, faListAlt, faFileAlt, faGlobe, faPlayCircle, faHome, faPen, faArrowAltCircleRight, faSignOutAlt, faSignInAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faEye, faEyeSlash, faUser, faQrcode, faCamera, faThList, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
//library.add( faBars, faVideo, faUserFriends, faListAlt, faFileAlt, faGlobe, faPlayCircle, faHome, faPen, faArrowAltCircleRight, faSignOutAlt, faSignInAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faEye, faEyeSlash, faUser, faQrcode, faCamera, faThList, faSyncAlt)
//import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import $ from 'jquery'; 
import { useToast } from "vue-toastification";
//general functions
import MixinGeneralFunct from '@/mixins/GeneralFunctions';

import axios from 'axios';
var sha512 = require('js-sha512');

export default {
	setup() {
		const toast = useToast();
		return { toast }
	},
	name: 'LoginPage',
	mixins: [ MixinGeneralFunct ],
	components: {
		//"font-awesome-icon":FontAwesomeIcon
		
	},
	props: {'auth':Object, 'secData':Object, 'params':Object},
	data() {
		return {
			user:[],
		}
	},
	
	mounted:function(){
		
		this.loadDefaultValues();
		
		
	},
	
	methods: {
		
		//default data
		loadDefaultValues:function(){
			var self = this;
			axios.get('/admins/app/core/Common/getDefaultDataLogin.php')
			.then(function (response) {
				$('#preloader').fadeOut(1000);
				
				self.userData = response.data.userData;
				
				self.user = response.data.user;
				
				if(self.user.isReg){
					self.$router.push({ path: '/' })
				}
				
			})
				.catch(function (error) {
				console.log(error);
			});
		},
		
		//zobrazi heslo
		showPswd: function(){
			this.user.showInput = this.user.showInput===true?false:true;
		},
		
		
		checkData:function(){
			if(this.user.email && this.user.password){
				return true;
			}else{
				this.toast.warning(this.$t('msgCore.fillInAllData'));
				return false;
			}
		},
		
		//default data
		getStoken:function(){
			var self = this;
			axios.get('/admins/app/core/Common/getStoken.php')
			.then(function (response) {
				//console.log(response.data);
				self.user.sToken = response.data.user.sToken;
				self.login();
			})
				.catch(function (error) {
				console.log(error);
			});
		},

		//prihlasenie
		login:function(){
			
			if(this.checkData()){

			
				//console.log(this.user.sToken);

				var bodyFormData = new FormData();
				this.user.p = sha512(this.user.password)
				this.user.password = '';
				bodyFormData.set('userData',JSON.stringify(this.user));
				
				var self = this;
				axios({
					method: 'post',
					url: '/admins/app/core/Common/controlLogin.php',
					data: bodyFormData
				})
				.then(response => {
					if(response.data.error){
							console.log('error', response.data.error)
					}else{
						//console.log(response.data);
						
						if(response.data.resCode == 3){
							self.toast.warning(this.$t('msgCore.badInputData'));
						}else if(response.data.resCode == 1){
							self.toast.success(this.$t('msgCore.successLogin'));

							//console.log(localStorage.LastUrl)

							if(localStorage.LastUrl){
								setTimeout(function(){ window.location.href = localStorage.LastUrl; }, 800);
								//localStorage.LastUrl='';
							}else{
								setTimeout(function(){ location.reload(); }, 800);
							}
								
							//setTimeout(function(){ self.$router.push({ path: '/login' }) }, 800);
						}else if(response.data.resCode == 2){
							self.toast.error(this.$t('msgCore.UnsuccessLogin'));
						}else if(response.data.resCode == 0){
							self.toast.error(this.$t('msgCore.badLoginRefreshForm'));
						}else if(response.data.resCode == 4){
							self.toast.error(this.$t('msgCore.AccountIsNotActivated'));
						}else if(response.data.resCode == 5){
							self.toast.error(this.$t('msgCore.AccountIsBlocked'));
						}else if(response.data.resCode == 6){
							self.toast.error(this.$t('msgCore.AccountExpired'));
						}else if(response.data.resCode == 7){
							self.toast.error(this.$t('msgCore.NumberOfLoginsExceeded'));
						}else if(response.data.resCode == 8){
							self.toast.error(this.$t('msgCore.EmailDoesNotExist'));
						}else if(response.data.resCode == 9){
							self.toast.error(this.$t('msgCore.BadPasswordLength'));
						}
						
						/*if(response.data.resCode==0 || response.data.resCode>1){
							var lineDrawingd = anime({
								targets: '#login-box',
								translateX: 20,
								duration: 50,
								easing: 'linear',
								direction: 'alternate',
								loop: 4
							});	
							this.loadDefaultValues();
						}*/
						
					}
				})
				.catch(error => {
					console.log(error.response)
				}); 
			
			}
			
		},

		//reload
		reloadMe: function(){
			location.reload();
		}
		
	},	
	
			
	watch: {
		//myEventReg() {
		//	this.loadDefaultValues();
		//}
	},

	computed:{
		
	},
	
}

</script>

<template>
	
	<div id="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
	
		
			<section id="login-box" class="box-flex">
				<div class="container-fluid">
					<div v-if="!user.isReg" class="row">
						<div class="col-12 col-md-4 offset-md-4 col-lg-3 offset-lg-4 bck">
						
							<div class="form-row">
								<div class="form-group col-md-12 col-12">
									<label><b>{{ $t('msgCore.Email') }}</b> </label>
									<input class="form-control form-control-sm" type="text" name="meno" v-model="user.email"/>
								</div>
							</div>
							
							<div class="form-row">
								<div class="form-group col-md-12 col-12">
									<label><b>{{ $t('msgCore.Password') }}</b> </label>
									<input :type="user.showInput ? 'text' : 'password'" name="heslo" autocomplete="new-heslo" class="form-control form-control-sm" v-model="user.password" :placeholder="$t('msgCore.Password')" v-on:keyup.enter="getStoken">
								</div>
							</div>

						
							<!--<div class="form-row">
								<div class="form-group col-12 text-right" >
									
									<span class="btn btn-link btn-sm" v-on:click="showPswd()">
										
										<span v-if="!user.showInput">{{ $t('msgCore.showPassword') }} <font-awesome-icon :icon="['fas', 'eye']" /></span>
										
										<span v-else>{{ $t('msgCore.hidePassword') }} <font-awesome-icon  :title="$t('msgCore.hidePassword')" :icon="['fas', 'eye-slash']" /></span>
									</span>
								</div>
							</div>-->
							
									
							<div class="form-row">
								<div class="input-group box-flex-right" >
									<button type="button" v-on:click="getStoken()" class="btn btn-primary btn-sm">{{ $t('msgCore.Login') }}</button>
									<!--<button type="button" v-on:click="reloadMe()" class="btn btn-link btn-sm text-right"><font-awesome-icon :icon="['fas', 'sync-alt']" /></button>-->
								</div>
							</div>
							
						</div>
					</div>	
				</div>
			</section>
		

</template>


