
<script>

import { defineComponent } from 'vue'

import { useToast } from "vue-toastification";

import TextEditor from './textEditor'
import ComponentGallery from './gallery'
import ComponentLibrary from './library'

import axios from 'axios';

export default defineComponent({
	setup() {
		const toast = useToast();
		return { toast }
	},
	name: 'ComponentOptions',
		data() {
			return {
				options:[]
				,workComponents:{}
			}
		},
	props: {
		'components': Object, 'articleId': String
	},
	emits: ["deleteRecord","orderRecordUp"],
	components: {
		TextEditor
		,ComponentGallery
		,ComponentLibrary
	}
	,mounted () {
		this.reloadComponents();
	},
	
	methods: {
		reloadComponents:function(){
			this.workComponents = this.components;
		},
		saveComponent:function(component,content,id){
			var bodyFormData = new FormData();
			bodyFormData.set('content',content);
			bodyFormData.set('componentId',id);

			var self=this;
			axios({
				method: 'post',
				url: '/admins/app/core/Article/controlArticleSaveComponentContent.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
					if(response.data.resCode==1){
						self.toast.success(this.$t('msgCore.dataWasSaved'));
						component.contentWasChanged=false;
					}else if(response.data.resCode==0){
						self.toast.warning(this.$t('msgCore.dataCantBeSaved'));
					}else if(response.data.resCode==2){
						self.toast.error(this.$t('msgCore.dataWasNotSaved'));
					}else if(response.data.resCode==3){
						self.toast.error(this.$t('msgCore.accessDenied'));
					}
				}
			})
			.catch(error => {
				console.log(error.response)
				self.toast.error(error.response.status + '|' + error.response.statusText );
			}); 
		},

		deleteComponent:function(componentId){
			
			if(componentId){
				
				var bodyFormData = new FormData();
				bodyFormData.set('componentId',componentId);
				
				var self=this;
				axios({
					method: 'post',
					url: '/admins/app/core/Article/controlComponentDelete.php',
					data: bodyFormData
				})
				.then(response => {
					if (response.data.error) {
							console.log('error', response.data.error)
					} else {
						console.log(response.data);
						if(response.data==1){
							self.toast.success(this.$t('msgCore.dataWasDeleted'));
							self.$emit('deleteRecord', '')
							const index = this.workComponents.findIndex((object) => object.id === componentId);
							if (index >= 0) {
								this.workComponents.splice(index, 1);
							}
						}else if(response.data==0){
							self.toast.warning(this.$t('msgCore.dataCantBeDeleted'));
						}else if(response.data==2){
							self.toast.error(this.$t('msgCore.dataWasNotDeleted'));
						}else if(response.data==3){
							self.toast.error(this.$t('msgCore.accessDenied'));
						}
					}
				})
				.catch(error => {
					console.log(error.response)
					self.toast.error(error.response.status + '|' + error.response.statusText );
				}); 
				
			}
			
		},


		orderUpComponent:function(componentMaster,ordering,id){
			var bodyFormData = new FormData();
			bodyFormData.set('currentOrdering',ordering);
			bodyFormData.set('componentId',id);
			bodyFormData.set('articleId',this.articleId);

			//var self=this;
			axios({
				method: 'post',
				url: '/admins/app/core/Article/controlComponentOrderUp.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
					console.log(response.data)
					if(response.data.resCode==1){
						this.toast.success(this.$t('msgCore.dataWasSaved'));
						this.$emit('orderRecordUp', '')

						//slave
						const indexSlave = this.workComponents.findIndex((object) => object.id === response.data.componentIdSlave);
						const componentSlave = this.workComponents[indexSlave];

						//master
						const indexMaster = this.workComponents.findIndex((object) => object.id === Number(response.data.componentIdMaster));
						
						console.log(indexSlave)
						console.log(indexMaster)
						
						if (indexSlave >= 0) {
							this.workComponents[indexSlave]=componentMaster;
						//	this.components[indexSlave].id=Number(response.data.componentIdMaster);
							this.workComponents[indexSlave].ordering=Number(response.data.orderingSlave); 
							
						}
						
						if (indexMaster >= 0) {
							this.workComponents[indexMaster]=componentSlave;
						//	this.components[indexMaster].id=Number(response.data.componentIdSlave);
							this.workComponents[indexMaster].ordering=Number(response.data.orderingMaster);
						}

						console.log(this.workComponents)
					}else if(response.data.resCode==0){
						this.toast.warning(this.$t('msgCore.dataCantBeSaved'));
					}else if(response.data.resCode==2){
						this.toast.error(this.$t('msgCore.dataWasNotSaved'));
					}else if(response.data.resCode==3){
						this.toast.error(this.$t('msgCore.accessDenied'));
					}
				}
			})
			.catch(error => {
				console.log(error.response)
				this.toast.error(error.response.status + '|' + error.response.statusText );
			}); 
		},

		orderDownComponent:function(componentMaster,ordering,id){
			var bodyFormData = new FormData();
			bodyFormData.set('currentOrdering',ordering);
			bodyFormData.set('componentId',id);
			bodyFormData.set('articleId',this.articleId);

			//var self=this;
			axios({
				method: 'post',
				url: '/admins/app/core/Article/controlComponentOrderDown.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
					console.log(response.data)
					if(response.data.resCode==1){
						this.toast.success(this.$t('msgCore.dataWasSaved'));
						this.$emit('orderRecordUp', '')

						//slave
						const indexSlave = this.components.findIndex((object) => object.id === response.data.componentIdSlave);
						const componentSlave = this.components[indexSlave];

						//master
						const indexMaster = this.components.findIndex((object) => object.id === Number(response.data.componentIdMaster));
						
						console.log(indexSlave)
						console.log(indexMaster)
						
						if (indexSlave >= 0) {
							this.workComponents[indexSlave]=componentMaster;
						//	this.components[indexSlave].id=Number(response.data.componentIdMaster);
							this.workComponents[indexSlave].ordering=Number(response.data.orderingSlave); 
							
						}
						
						if (indexMaster >= 0) {
							this.workComponents[indexMaster]=componentSlave;
						//	this.components[indexMaster].id=Number(response.data.componentIdSlave);
							this.workComponents[indexMaster].ordering=Number(response.data.orderingMaster);
						}

						console.log(this.workComponents)
					}else if(response.data.resCode==0){
						this.toast.warning(this.$t('msgCore.dataCantBeSaved'));
					}else if(response.data.resCode==2){
						this.toast.error(this.$t('msgCore.dataWasNotSaved'));
					}else if(response.data.resCode==3){
						this.toast.error(this.$t('msgCore.accessDenied'));
					}
				}
			})
			.catch(error => {
				console.log(error.response)
				this.toast.error(error.response.status + '|' + error.response.statusText );
			}); 
		},

		handleChangeEditor(e) {
			//kontrola zmeny obsahu
			e.contentWasChanged=true;
		},

		handleChangeGallery(e) {
			//kontrola zmeny obsahu
			e.contentWasChanged=true;
		},

		handleChangeLibrary(e) {
			//kontrola zmeny obsahu
			e.contentWasChanged=true;
		},
	},
	
	watch: {
		components() {
			this.reloadComponents();
		},
	},
	
	created() {

	}
})
</script>

<template>
	<div>

		<div v-for="(component, index) in components" :key="component.id" class="row">

			<div class="col-12 pt-1 component-block">

				<div class="row">

					<div class="col-md-1">
						{{(index+1)}} 
					</div>

					<div class="col-md-5">
						<h4 v-if="component.componentId==1">
							Textový editor
						</h4>

						<h4 v-else-if="component.componentId==2">
							Galéria
						</h4>

						<h4 v-else-if="component.componentId==3">
							Knižnica
						</h4>
					</div>

					<div class="col-md-6 col-12 text-right">
						<button type="button" v-on:click="saveComponent(component,component.content,component.id)" class="btn btn-warning btn-sm">{{ $t('msgCore.btnSave') }}</button>
						<button type="button" v-on:click="deleteComponent(component.id)" class="btn btn-danger btn-sm">{{ $t('msgCore.btnDelete') }}</button>
						<button :disabled="index==0" type="button" v-on:click="orderUpComponent(component,component.ordering,component.id)" class="btn btn-secondary btn-sm">{{ $t('msgCore.btnOrderUp') }}</button>
						<button :disabled="(index+1==components.length)" type="button" v-on:click="orderDownComponent(component,component.ordering,component.id)" class="btn btn-secondary btn-sm">{{ $t('msgCore.btnOrderDown') }}</button>
					</div>

					<div v-if="component.contentWasChanged" class="col-12">
						<div class="alert alert-warning">
							Obsah bol zmenený
						</div>
					</div>

					<div v-if="component.componentId==1" class="col-12 pb-5  component-row">
						<TextEditor :content="component" @changeContent="handleChangeEditor" />
					</div>

					<div v-else-if="component.componentId==2" class="col-12 pb-5  component-row">
						<ComponentGallery :content="component"  @changeContent="handleChangeGallery" />
					</div>
					
					<div v-else-if="component.componentId==3" class="col-12 pb-5  component-row">
						<ComponentLibrary :content="component" @changeContent="handleChangeLibrary" />
					</div>

				</div>
			</div>

		</div>
	
	</div>
</template>