<script>


import Popper from "vue3-popper";
import { useToast } from "vue-toastification";

import axios from 'axios';

	
//general functions
import MixinGeneralFunct from '@/mixins/GeneralFunctions';
import { defineComponent } from 'vue'
//import tinymce from 'tinymce';
//import axios from 'axios';


//modal box preview photos to article


export default defineComponent ({
	setup() {
		const toast = useToast();
		return { toast }
	},
	name: 'CategoryEdit',
	mixins: [ MixinGeneralFunct ],
	components: {
		//"font-awesome-icon":FontAwesomeIcon
		//,"video-player":VideoPlayer
		
		//,ArticleSelectIntoPhoto
		Popper
		
	},
	props: {'categoryId': String,'auth':Object, 'secData':Object},
	emits: ["categoryId","changeRecord"],
	data() {
		
		return {
			prefix:'',
			
			params:[],
			accData: [],
			
			preloader:true,
			
			recordID:null,
			isNewRecord:true,

			
			
			optionsAccessLevels: [],
			
			optionsStates:[],
			optionsLanguages:[],
			
			
			
			//form
			category:[]
			
		}
	},
	
	mounted:function(){
		
	},
	
	methods: {
		// load data form
		loadDefaultValues:function(){
			
			this.preloader=true
			
			if(this.categoryId=='null'){
				this.isNewRecord=true;
			}else if(this.categoryId && this.categoryId>0){
				this.isNewRecord=false;
			}
			
			var bodyFormData = new FormData();
			bodyFormData.set('categoryId',this.categoryId);
			
			var self=this;
			axios({
				method: 'post',
				url: '/admins/app/core/LibraryCategory/getLibraryCategoryData.php',
				data: bodyFormData
			})
			.then(function (response) {
			
				//console.log(response.data);
				self.accData = response.data.accData;
				
				//hide loader
				self.preloader=false
				
				self.accData = response.data.accData;
								
				self.category = response.data.category;
				
				self.optionsAccessLevels = response.data.optionsAccessLevels;
				self.optionsStates = response.data.optionsStates;
				
				
			})
				.catch(function (error) {
				console.log(error);
				
				
			});
		
		},
		
		closeModalBoxSecureHelper:function(myform,categoryId){
			this.unlockCategory(categoryId);
			//this.loadDefaultValues();
			this.closeModalBoxSecure(myform);
		},
		
		
		
		
		checkSave:function(){
								
			if(!this.category.title){this.category.titleError = true; }else{this.category.titleError = false;}
				if(!this.category.alias){this.category.aliasError = true; }else{this.category.aliasError = false;}
				if(!this.category.state){this.category.stateError = true; }else{this.category.stateError = false;}
				if(!this.category.access){this.category.accessError = true; }else{this.category.accessError = false;}
				
				if(this.category.title && this.category.alias && this.category.state && this.category.access){
				return true;
			}else{
				this.toast.warning(this.$t('msgCore.fillInAllData'));
				return false;
			}
		},	
		
		saveCategory:function(){
							
			if(this.checkSave()){
				
				var bodyFormData = new FormData();
				bodyFormData.set('myCategory',JSON.stringify(this.category));
				
				
				var self=this;
				axios({
					method: 'post',
					url: '/admins/app/core/LibraryCategory/controlLibraryCategorySave.php',
					data: bodyFormData
				})
				.then(response => {
					if (response.data.error) {
							console.log('error', response.data.error)
					} else {
						//console.log('success2', response.data);
						if(response.data.resCode==1){
							self.toast.success(this.$t('msgCore.dataWasSaved'));
							self.$emit('changeRecord', '')
						
							self.closeModalBoxSecure('myModalEditCategory');
							
						}else if(response.data.resCode==0){
							self.toast.warning(this.$t('msgCore.dataCantBeSaved'));
							self.closeModalBoxSecure('myModalEditCategory');
						}else if(response.data.resCode==2){
							self.toast.error(this.$t('msgCore.dataWasNotSaved'));
							self.closeModalBoxSecure('myModalEditCategory');
						}else if(response.data.resCode==3){
							self.toast.error(this.$t('msgCore.accessDenied'));
							self.closeModalBoxSecure('myModalEditCategory');
						}
						self.category = [];
					}
				})
				.catch(error => {
					console.log(error.response)
				}); 
				
				
			}
			
		},

		createAlias:function(){
			
			if(this.category.title && this.isNewRecord){
				
				var bodyFormData = new FormData();
				bodyFormData.set('title',this.category.title);
				
				var self=this;
				axios({
					method: 'post',
					url: '/admins/app/core/LibraryCategory/controlLibraryCategoryCreateAlias.php',
					data: bodyFormData
				})
				.then(response => {
					if (response.data.error) {
							console.log('error', response.data.error)
					} else {
						console.log(response.data);
						self.category.alias = response.data.category.alias;
					}
				})

				.catch(error => {
					console.log(error.response)
				}); 
			}
		},
		
		//odomknutie zaznamu
		unlockCategory:function(categoryId){
			
			this.cSession();
			var bodyFormData = new FormData();
			bodyFormData.set('myRecordID',categoryId);
				
			var self = this;
			axios({
				method: 'post',
				url: '/admins/app/core/LibraryCategory/controlLibraryCategoryUnlock.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
					//console.log(response.data);
					if(response.data==1){
						self.$emit('changeRecord', '')
					}else if(response.data==0){
						//xx
					}else if(response.data==2){
						//xx
					}else if(response.data==3){
						//xx
					}
					
				}
			})
			.catch(error => {
				console.log(error.response)
			}); 
			
		}


	},
	
	watch: {
		categoryId() {
			this.loadDefaultValues();
		},
		'category.title': function () {
			this.createAlias();
		}
	},

	computed:{
		
	},
	
})

</script>

<template>
	<div id="msg"></div>
	<div id="myModalEditCategory" class="fade show" role="dialog" style="z-index: 1040;  display: none;">
		<div class="modal-dialog modal-lg" style="width: 100%; max-width: none;">
		<div v-show="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
			<div class="modal-content">
				<div class="modal-header">
					<h6 class="modal-title">{{ $t('msgCore.LibraryCategory') }} <span v-if="!category.isNewRecord"><b>{{ category.title }}</b> id: {{category.id}}</span></h6>
				</div>
		
						<div v-if="accData.modulAcc && (auth.libraryCategoriesNew || auth.libraryCategoriesEdit)" class="modal-body">
							
							<div class="row">
								<div class="col-12">
									<div v-if="category.isCheckedOut && secData.userId != category.isCheckedOutId" class="angle">
										<span class="angle-inner" :class="{'angle-warning':category.isCheckedOut}"><i class="fa" :class="{'angle-warning':category.isCheckedOut, 'fa-exclamation-triangle':category.isCheckedOut}" aria-hidden="true"></i> {{$t('msgCore.Edited')}} </span >
										
										<Popper v-if="category.isCheckedOut" :content="category.isCheckedOutName+', '+category.isCheckedOutDate">
											<button class="personCircle v-center" v-bind:style="{background:category.isCheckedOutColor}">{{ category.isCheckedOutLetterName+''+category.isCheckedOutLetterSurname }}</button>
										</Popper>
									</div>
								</div>
							</div>
							
							
							<div class="row">
								
								<div class="col-md-8 col-12">
						
									<div class="form-row">
										
										<div class="form-group col-md-6 col-12">
											<label :class="{'text-danger':category.titleError}"><b>{{ $t('msgCore.Title') }}</b> </label>
											<input type="text" class="form-control form-control-sm" :class="{'is-invalid':category.titleError}" v-model="category.title" v-on:change="checkSave()" >
											<div v-show="category.titleError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.fillIn')}}</span></div>
										</div>
										
										<div class="form-group col-md-6 col-12">
											<label :class="{'text-danger':category.aliasError}"><b>{{ $t('msgCore.alias') }}</b> </label>
											<input type="text" :disabled="!isNewRecord" class="form-control form-control-sm" :class="{'is-invalid':category.aliasError}" v-model="category.alias" v-on:change="checkSave()" >
											<div v-show="category.aliasError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.fillIn')}}</span></div>
										</div>
									</div>												
								</div>

								<div class="col-md-4 col-12 form-control-system-data">
									<div class="form-row">	
									
										<div class="form-group col-12">
											<label :class="{'text-danger':category.accessError}"><b>{{ $t('msgCore.AccessLevel') }}</b></label>
																								
											<select v-model='category.access' class="form-control form-control-sm " :class="{'is-invalid':category.accessError}" v-on:change="checkSave()">
												<option value="">{{ $t('msgCore.selectLevel') }}</option>
												<option v-for="(option) in optionsAccessLevels" :value="option.value" :key="option">{{ option.title }}</option>
											</select>
											<div v-show="category.accessError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.selectLevel')}}</span></div>
										</div>
										
										<div class="form-group col-12">
											<label :class="{'text-danger':category.stateError}"><b>{{ $t('msgCore.State') }}</b></label>
											
												<div  v-for="(option) in optionsStates" :key="option" class="form-check">
												<label class="form-check-label">
													<input class="form-check-input" type="radio" name="optionsStates"  v-model='category.state' v-on:change="checkSave()" :value="option.value">
													{{ $t('msgCore.'+option.title) }}
													<span class="circle">
													<span class="check"></span>
													</span>
												</label>
												</div>
												<div v-show="category.stateError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.selectState')}}</span></div>
										</div>
																			
										
									</div>	
									
								</div>	
								
							</div>
							
						</div>
						
						<div v-else class="modal-body">
							<div class="row">
								<div class="col-12">
									<div class="alert alert-warning" role="alert">
										{{$t('msgCore.accessDenied')}}
									</div>
								</div>
							</div>
						</div>
						
						<div class="modal-footer">
						
							<div class="btn-group">
								<button type="button" v-on:click="closeModalBoxSecureHelper('myModalEditCategory',category.id)" class="btn btn-default btn-sm">{{ $t('msgCore.btnClose') }}</button>
								
								<button type="button" :disabled="((!auth.libraryCategoriesEdit) || (!auth.libraryCategoriesNew)) || !accData.modulAcc" v-on:click="saveCategory()" class="btn btn-warning btn-sm">{{ $t('msgCore.btnSave') }}</button>	
							</div>	
							
						</div>
					
			
				
				
			
			</div>

		</div>
		
	</div>
		


</template>


