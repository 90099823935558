
//sk
import skDataCore from './languages/sk/msgCore.json'
import skDataMenu from './languages/sk/msgMenu.json'
import skDataCustomMailing from './languages/sk/msgCustomMailing.json'
import skDataCustomWorking from './languages/sk/msgCustomWorking.json'
import skDataCustomWeather from './languages/sk/msgCustomWeather.json'
const skData = {msgCore:skDataCore, msgMenu:skDataMenu, msgCustomMailing:skDataCustomMailing, msgCustomWorking:skDataCustomWorking, msgCustomWeather:skDataCustomWeather};

//en
import enDataCore from './languages/en/msgCore.json'
import enDataMenu from './languages/en/msgMenu.json'
import enDataCustomMailing from './languages/en/msgCustomMailing.json'
import enDataCustomWorking from './languages/en/msgCustomWorking.json'
import enDataCustomWeather from './languages/en/msgCustomWeather.json'
const enData = {msgCore:enDataCore, msgMenu:enDataMenu, msgCustomMailing:enDataCustomMailing, msgCustomWorking:enDataCustomWorking, msgCustomWeather:enDataCustomWeather};

//de
import deDataCore from './languages/de/msgCore.json'
import deDataMenu from './languages/de/msgMenu.json'
import deDataCustomMailing from './languages/de/msgCustomMailing.json'
import deDataCustomWorking from './languages/de/msgCustomWorking.json'
import deDataCustomWeather from './languages/de/msgCustomWeather.json'
const deData = {msgCore:deDataCore, msgMenu:deDataMenu, msgCustomMailing:deDataCustomMailing, msgCustomWorking:deDataCustomWorking, msgCustomWeather:deDataCustomWeather};

//cz
import czDataCore from './languages/cz/msgCore.json'
import czDataMenu from './languages/cz/msgMenu.json'
import czDataCustomMailing from './languages/cz/msgCustomMailing.json'
import czDataCustomWorking from './languages/cz/msgCustomWorking.json'
import czDataCustomWeather from './languages/cz/msgCustomWeather.json'
const czData = {msgCore:czDataCore, msgMenu:czDataMenu, msgCustomMailing:czDataCustomMailing, msgCustomWorking:czDataCustomWorking, msgCustomWeather:czDataCustomWeather};

export const defaultLocale = 'sk'
export const languages = {
 
  sk: skData,
  en: enData,
  cz: czData,
  de: deData
}