<script>

//import { library } from '@fortawesome/fontawesome-svg-core';
//import { faPen, faArrowAltCircleRight, faSignOutAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faComment } from '@fortawesome/free-solid-svg-icons';
//library.add(faPen,faArrowAltCircleRight,faSignOutAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faComment)
//import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
//import $ from 'jquery'; 
import { useToast } from "vue-toastification";
import axios from 'axios';
//import { VideoPlayer } from 'vue-video-player'

//import datePicker from 'vue-bootstrap-datetimepicker';
//import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

//import Datepicker from 'vue3-date-time-picker';
//import 'vue3-date-time-picker/dist/main.css'
	
//general functions
import MixinGeneralFunct from '@/mixins/GeneralFunctions';
import { defineComponent } from 'vue'
//import axios from 'axios';

export default defineComponent ({
	setup() {
		const toast = useToast();
		return { toast }
	},
	name: 'LevelEdit',
	mixins: [ MixinGeneralFunct ],
	components: {
		//"font-awesome-icon":FontAwesomeIcon
		//,"video-player":VideoPlayer
		//Datepicker
	},
	props: {'levelId': String, 'auth':Object, 'secData':Object},
	emits: ["levelId","changeRecord"],
	data() {
		return {
			prefix:'',
			
			params:[],
			accData: [],
			
			preloader:true,
			
			recordID:null,
			isNewRecord:true,
			
			optionsAccessLevels:[],
			optionsStates:[],
			
			//form
			accesslevel:[]
		}
	},
	
	mounted:function(){
		
		
		
		//console.log(this.levelId)
		
		//console.log(this.$route.path)
	},
	
	methods: {
		// load data form
		loadDefaultValues:function(){
			
			this.preloader=true
			
			//console.log(this.levelId);				
			if(this.levelId){
				this.isNewRecord=false;
			}else{
				this.isNewRecord=true;
			}
			
			var bodyFormData = new FormData();
			bodyFormData.set('recordID',this.levelId);
			
			var self=this;
			axios({
				method: 'post',
				url: '/admins/app/core/Level/getLevelData.php',
				data: bodyFormData
			})
			.then(function (response) {
			
				//console.log(response.data);
				self.accData = response.data.accData;
				
				//hide loader
				self.preloader=false
				
				self.accData = response.data.accData;
								
				self.accesslevel = response.data.accesslevel;
				
				self.optionsAccessLevels = response.data.optionsAccessLevels;
				self.optionsStates = response.data.optionsStates;
		
			})
				.catch(function (error) {
				console.log(error);
				
				
			});
		
		},
		
		closeModalBoxSecureHelper:function(myform,levelId){
			this.unlockLevel(levelId);
			//this.loadDefaultValues();
			this.closeModalBoxSecure(myform);
		},
		
		
		
		
		checkSave:function(){
				
			if(!this.accesslevel.title){this.accesslevel.titleError = true;}else{this.accesslevel.titleError = false;}
			if(!this.accesslevel.access){this.accesslevel.accessError = true;}else{this.accesslevel.accessError = false;}
			if(!this.accesslevel.state){this.accesslevel.stateError = true;}else{this.accesslevel.stateError = false;}
			
			if(!this.accesslevel.titleError && !this.accesslevel.accessError && !this.accesslevel.stateError){
				return true;
			}else{
				this.toast.warning(this.$t('msgCore.fillInAllData'));
				return false;
			}
		},	
		
		saveLevel:function(){
				
			if(this.checkSave()){
				
				var bodyFormData = new FormData();
				bodyFormData.set('myAccessLevel',JSON.stringify(this.accesslevel));
				
				var self=this;
				axios({
					method: 'post',
					url: '/admins/app/core/Level/controlLevelSave.php',
					data: bodyFormData
				})
				.then(response => {
					if (response.data.error) {
							console.log('error', response.data.error)
					} else {
						//console.log('success2', response.data);
						if(response.data==1){
							self.toast.success(this.$t('msgCore.dataWasSaved'));
							self.$emit('changeRecord', '')
							self.closeModalBoxSecure('myModalEditLevel');
						}else if(response.data==0){
							self.toast.warning(this.$t('msgCore.dataCantBeSaved'));
							self.closeModalBoxSecure('myModalEditLevel');
						}else if(response.data==2){
							self.toast.error(this.$t('msgCore.dataWasNotSaved'));
							self.closeModalBoxSecure('myModalEditLevel');
						}else if(response.data==3){
							self.toast.error(this.$t('msgCore.accessDenied'));
							self.closeModalBoxSecure('myModalEditLevel');
						}
						self.accesslevel = [];
					}
				})
				.catch(error => {
					console.log(error.response)
				}); 		
				
			}
			
		},
		
		//odomknutie zaznamu
		unlockLevel:function(levelId){
			this.cSession();
			var bodyFormData = new FormData();
			bodyFormData.set('myRecordID',levelId);
				
			var self = this;
			axios({
				method: 'post',
				url: '/admins/app/core/Level/controlLevelUnlock.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
				//	console.log(response.data);
					if(response.data==1){
						self.$emit('changeRecord', '')
					}else if(response.data==0){
						//xx
					}else if(response.data==2){
						//xx
					}else if(response.data==3){
						//xx
					}
				}
			})
			.catch(error => {
				console.log(error.response)
			}); 
			
		}
		
	},
	
	watch: {
		levelId() {
			this.loadDefaultValues();
		}
	},

	computed:{
		
	},
	
})

</script>

<template>
	<div id="msg"></div>
		<div id="myModalEditLevel" class="fade show" role="dialog" style="z-index: 1040;  display: none;">
			<div class="modal-dialog modal-lg" style="width:100%; max-width: none;">
				<div v-show="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
				<div class="modal-content">
					<div class="modal-header">
						<h6 class="modal-title">{{ $t('msgCore.AccessLevel') }} <span v-if="accesslevel.isNewRecord">{{ $tc('msgCore.new',1) }}</span><span v-else><b>{{ accesslevel.title }}</b> id: {{accesslevel.id}}</span></h6>
						
						
					</div>
			
							<div v-if="accData.modulAcc && (auth.levelsEdit || auth.levelsNew)" class="modal-body">
								
								<div class="row">
									<div class="col-12">
										<div v-if="accesslevel.isCheckedOut && secData.userId != accesslevel.isCheckedOutId" class="angle">
											<span class="angle-inner" :class="{'angle-warning':accesslevel.isCheckedOut}"><i class="fa" :class="{'angle-warning':accesslevel.isCheckedOut, 'fa-exclamation-triangle':accesslevel.isCheckedOut}" aria-hidden="true"></i> {{$t('msgCore.Edited')}} </span >
											
											<span v-if="accesslevel.isCheckedOut" class="personCircle v-center" v-bind:style="{background:accesslevel.isCheckedOutColor}" v-bind:title="accesslevel.isCheckedOutName+', '+accesslevel.isCheckedOutDate">{{ accesslevel.isCheckedOutLetterName+''+accesslevel.isCheckedOutLetterSurname }}</span>
										</div>
									</div>
								</div>	
								
								<div class="row">
									
									<div class="col-md-8 col-12">

											<div class="form-row">
												
												<div class="form-group col-md-4 col-12">
													<label :class="{'text-danger':accesslevel.titleError}"><b>{{ $t('msgCore.Title') }}</b> </label>
													<input type="text" class="form-control form-control-sm" :class="{'is-invalid':accesslevel.titleError}" v-on:change="checkSave()" v-model="accesslevel.title" >
													<div v-show="accesslevel.titleError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.fillIn')}}</span></div>
												</div>
												
											</div>	
									</div>
									
									<div class="col-md-4 col-12 form-control-system-data">
									
										<div class="form-group col-12">
											<label :class="{'text-danger':accesslevel.accessError}"><b>{{ $t('msgCore.AccessLevel') }}</b></label>
																								
											<select v-model='accesslevel.access' class="form-control form-control-sm " :class="{'is-invalid':accesslevel.accessError}" v-on:change="checkSave()">
												<option  value="">{{ $t('msgCore.selectLevel') }}</option>
												<option v-for="(option) in optionsAccessLevels" :key="option" :value="option.value">{{ option.title }}</option>
											</select>
											<div v-show="accesslevel.accessError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.selectLevel')}}</span></div>
										</div>
										
										<div class="form-group col-12">
											<label :class="{'text-danger':accesslevel.stateError}"><b>{{ $t('msgCore.State') }}</b></label>
											
												<div  v-for="(option) in optionsStates" :key="option" class="form-check">
													<label class="form-check-label">
														<input class="form-check-input" type="radio" name="optionsStates"  v-model='accesslevel.state' v-on:change="checkSave()" :value="option.value">
														{{ $t('msgCore.'+option.title) }}
														<span class="circle">
															<span class="check"></span>
														</span>
													</label>
												</div>
											<div v-show="accesslevel.stateError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.selectState')}}</span></div>
										</div>
												
									</div>
									
								</div>		
							
							</div>
							
							<div v-else class="modal-body">
								<div class="row">
									<div class="col-12">
										<div class="alert alert-warning" role="alert">
											{{$t('msgCore.accessDenied')}}
										</div>
									</div>
								</div>
							</div>
							
							
							
							<div class="modal-footer">
							
								<div class="btn-group">
									<button type="button" v-on:click="closeModalBoxSecureHelper('myModalEditLevel',accesslevel.id)" class="btn btn-default btn-sm">{{ $t('msgCore.btnClose') }}</button>
									
									<button type="button" :disabled="((!auth.levelsEdit) || (!auth.levelsNew)) || !accData.modulAcc" v-on:click="saveLevel()" class="btn btn-warning btn-sm">{{ $t('msgCore.btnSave') }}</button>	
								</div>	
								
							</div>
						
				
					
					
				
				</div>

			</div>
			
		</div>
		
		
</template>


