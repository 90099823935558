
<script>

import { defineComponent } from 'vue'

import axios from 'axios';

export default defineComponent({
  name: 'ComponentGallery',
	data() {
		return {
			result: [],
			gallery: [],
			photos: [],
		}
	},
  props: {
	'content': Object
  },
  emits: ["content","changeContent"],
  components: {
    
  }
  ,mounted () {
   	this.loadOptions();
	if(this.content.fields.galleryId){
		this.loadPreview();
	}
  },
  
	methods: {
		// load data form
		loadOptions:function(){
			
			var self=this;
			axios({
				method: 'post',
				url: '/admins/app/core/Gallery/getGalleryOptions.php'				
			})
			.then(function (response) {
			
				console.log(response.data);
				
				//hide loader
				self.preloader=false
				
				self.accData = response.data.accData;
								
				self.result = response.data.result;
						
			})
				.catch(function (error) {
				console.log(error);
			
			});
		
		},

		loadPreview:function(){
			
			var bodyFormData = new FormData();
			bodyFormData.set('galleryId',this.content.fields.galleryId);

			var self=this;
			axios({
				method: 'post',
				url: '/admins/app/core/Gallery/getGalleryPreviewData.php',
				data: bodyFormData
			})
			.then(function (response) {

				console.log(response.data);

				self.gallery = response.data.gallery;
				self.photos = response.data.photos;

			})
				.catch(function (error) {
				console.log(error);
			
			});
		
		},

		loadContent:function(){
		
			this.content.content = JSON.stringify(this.content.fields);

			this.$emit('changeContent', this.content)
		}
	},
	
	watch: {
	
	},
	
	created() {

	}
})
</script>

<template>
  <div>
    <div class="form-group col-md-4 col-12">
		<label><b>{{ $t('msgCore.Gallery') }}</b></label>
		<select v-model='content.fields.galleryId' class="form-control form-control-sm" v-on:change="loadContent();loadPreview();" >
			<option  value="">{{ $t('msgCore.selectGallery') }}</option>
			<option v-for="(option) in result.optionsGalleries" :value="option.value" :key="option">{{ option.title }}</option>
		</select>
	</div>

		<div class="col-12">
			<div v-for="photo in photos" class="crop_gallery2" :key="photo">
				<img :src="photo.fotoData" :title="photo.title">
			</div>
		</div>

  </div>
</template>