<script>


//import $ from 'jquery'; 

import Popper from "vue3-popper";
import { useToast } from "vue-toastification";

//import TaskSelectIntoPhoto from '@/components/Core/Tasks/TaskSelectIntoPhoto'


import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css'


import axios from 'axios';

//general functions
import MixinGeneralFunct from '@/mixins/GeneralFunctions';
import { defineComponent } from 'vue'



export default defineComponent ({
	setup() {
		const toast = useToast();
		return { toast }
	},
	name: 'TaskItemEdit',
	mixins: [ MixinGeneralFunct ],
	components: {
		Popper,Datepicker
	},
	props: {'taskItemId': String, 'taskId': String, 'auth':Object, 'secData':Object},
	emits: ["taskItemId", "taskId", "changeRecord"],
	data() {
	
		return {
			prefix:'',
			
			params:[],
			accData: [],
			
			preloader:true,
			
			isNewRecord:true,

			optionsAccessLevels: [],
			optionsTypes:[],
			optionsTemplates:[],
			optionsStates:[],
			optionsLanguages:[],
			
						
						
			//form
			taskItem:[],

			optionsTaskTypes: [],
			optionsProjects: [],
			optionsTaskAccountStates: [],
			
		}
	},
	
	mounted:function(){
		
	},
	
	methods: {
		// load data form
		loadDefaultValues:function(){
			
			this.preloader=true
			//console.log(this.taskItemId)		
			if(this.taskItemId=='null'){
				this.isNewRecord=true;
			}else if(this.taskItemId && this.taskItemId>0){
				this.isNewRecord=false;
			}
			
			var bodyFormData = new FormData();
			bodyFormData.set('taskItemId',this.taskItemId);
			
			
			var self=this;
			axios({
				method: 'post',
				url: '/admins/app/customWorking/WorkingTasks/getTaskItemData.php',
				data: bodyFormData
			})
			.then(function (response) {
			
				//console.log(response.data);
				self.accData = response.data.accData;
				
				//hide loader
				self.preloader=false
				
				self.accData = response.data.accData;
								
				
				self.taskItem = response.data.taskItem;
			
				
				self.optionsTaskTypes = response.data.optionsTaskTypes;
				self.optionsTaskStates = response.data.optionsTaskStates;
			})
				.catch(function (error) {
				console.log(error);
				
				
			});
		
		},

		
		
		closeModalBoxSecureHelper:function(myform,taskItemId){
			this.unlockTaskItem(taskItemId);
			//this.loadDefaultValues();
			this.closeModalBoxSecure(myform);
		},
		
		
		
		
		
		checkSave:function(){
		
			if(!this.taskItem.title){this.taskItem.titleError = true; }else{this.taskItem.titleError = false;}
			if(!this.taskItem.status){this.taskItem.statusError = true; }else{this.taskItem.statusError = false;}
			if(!this.taskItem.type){this.taskItem.typeError = true; }else{this.taskItem.typeError = false;}

			if(this.taskItem.work_start && this.taskItem.work_end){
				
				var timeStart = new Date(this.taskItem.work_start);
			
				var timeEnd = new Date(this.taskItem.work_end);
			
				var diffMs = (timeEnd - timeStart); // milliseconds between 
				
				var minutes = Math.floor(diffMs / 60000);
			
				if(minutes>0){
					this.taskItem.timeIsDisabled = true;
					this.taskItem.work_time = minutes;
				}else{
					this.taskItem.timeIsDisabled = false;
					
				}
				
			}else{
				this.taskItem.timeIsDisabled = false;
				
			}
			
			if(!this.taskItem.titleError && !this.taskItem.statusError && !this.taskItem.typeError){
				return true;
			}else{
				return false;
			}
			
		},

				
		saveTaskItem:function(){

			if(this.checkSave()){
				
				var bodyFormData = new FormData();
				bodyFormData.set('myTask',JSON.stringify(this.taskItem));
				bodyFormData.set('taskId',this.taskId);
				
				
				var self=this;
				axios({
					method: 'post',
					url: '/admins/app/customWorking/WorkingTasks/controlTaskItemSave.php',
					data: bodyFormData
				})
				.then(response => {
					if (response.data.error) {
							console.log('error', response.data.error)
					} else {
						//console.log('success2', response.data);
						if(response.data.resCode==1){
							self.toast.success(this.$t('msgCore.dataWasSaved'));
							self.$emit('changeRecord', '')
						
							self.closeModalBoxSecure('myModalEditTaskItem');
							
						}else if(response.data.resCode==0){
							self.toast.warning(this.$t('msgCore.dataCantBeSaved'));
							self.closeModalBoxSecure('myModalEditTaskItem');
						}else if(response.data.resCode==2){
							self.toast.error(this.$t('msgCore.dataWasNotSaved'));
							self.closeModalBoxSecure('myModalEditTaskItem');
						}else if(response.data.resCode==3){
							self.toast.error(this.$t('msgCore.accessDenied'));
							self.closeModalBoxSecure('myModalEditTaskItem');
						}
						self.task = [];
					}
				})
				.catch(error => {
					console.log(error.response)
				}); 
				
				
			}else{
				this.toast.warning(this.$t('msgCore.fillInAllData'));
			}
			
		},
		
		//odomknutie zaznamu
		unlockTaskItem:function(taskItemId){
			
			this.cSession();
			var bodyFormData = new FormData();
			bodyFormData.set('myRecordID',taskItemId);
				
			var self = this;
			axios({
				method: 'post',
				url: '/admins/app/customWorking/WorkingTasks/controlTaskItemUnlock.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
					//console.log(response.data);
					if(response.data==1){
						self.$emit('changeRecord', '')
					}else if(response.data==0){
						//xx
					}else if(response.data==2){
						//xx
					}else if(response.data==3){
						//xx
					}
					
				}
			})
			.catch(error => {
				console.log(error.response)
			}); 
			
		},

		//get count chars
	/*	getCountChars:function(){
			this.countCharQuestion=this.countCharQuestionLimit - this.question.title.length;
			if(this.countCharQuestion<0){this.question.countCharError = true; }else{this.question.countCharError = false;}
		},*/

	},
	
	watch: {
		taskItemId() {
			this.loadDefaultValues();
		},
		'taskItem.work_start'() {
			this.checkSave();
		},
		'taskItem.work_end'() {
			this.checkSave();
		}
	},

	computed:{
		
	},
	
})

</script>

<template>
	<div id="msg"></div>
	<div id="myModalEditTaskItem" class="fade show" role="dialog" style="z-index: 1040;  display: none;">
			<div class="modal-dialog modal-lg" style="width: 100%; max-width: none;">
				<div v-show="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
				<div class="modal-content">
					<div class="modal-header">
						<h6 class="modal-title">{{ $t('msgCustomWorking.TaskItem') }} <span v-if="!taskItem.isNewRecord"><b>{{ taskItem.title }}</b> id: {{taskItem.id}}</span></h6>
					</div>
			
							<div v-if="accData.modulAcc && (auth.tasksEdit || auth.tasksNew)" class="modal-body">
											
								<div class="row">
									<div class="col-12">
										<div v-if="taskItem.isCheckedOut && secData.userId != taskItem.isCheckedOutId" class="angle">
											<span class="angle-inner" :class="{'angle-warning':task.isCheckedOut}"><i class="fa" :class="{'angle-warning':taskItem.isCheckedOut, 'fa-exclamation-triangle':taskItem.isCheckedOut}" aria-hidden="true"></i> {{$t('msgCore.Edited')}} </span >
											
											<Popper v-if="taskItem.isCheckedOut" :content="taskItem.isCheckedOutName+', '+taskItem.isCheckedOutDate">
												<button class="personCircle v-center" v-bind:style="{background:taskItem.isCheckedOutColor}">{{ taskItem.isCheckedOutLetterName+''+v.isCheckedOutLetterSurname }}</button>
											</Popper>
											
										</div>
									</div>
								</div>

									<div class="form-row">
										
										<div class="form-group col-md-2 col-3">
											<label><b>{{ $t('msgCore.ID') }}</b></label><br>
											{{taskItem.id}}
										</div>
										
										
										<div class="form-group col-md-10 col-12">
											<label :class="{'text-danger':taskItem.titleError}"><b>{{ $t('msgCore.title') }}</b> </label>
											<input type="text" class="form-control form-control-sm" :class="{'is-invalid':taskItem.titleError}" v-model="taskItem.title" v-on:change="checkSave()" >
											<div v-show="taskItem.titleError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.fillIn')}}</span></div>
										</div>
										
										<div class="form-group col-md-12 col-12">
											<label><b>{{ $t('msgCore.description') }}</b> </label>
											<input type="text" class="form-control form-control-sm"  v-model="taskItem.description" >
										</div>
									</div>

									
									<div class="form-row">
										<div class="form-group col-md-6 col-12">
											<label><b>{{ $t('msgCustomWorking.WorkStart') }}</b> </label>
										
											<Datepicker v-model="taskItem.work_start" format="yyyy-MM-dd HH:mm" :locale="$i18n.locale"  :cancelText="$t('msgCore.Cancel')" :selectText="$t('msgCore.Select')" @input="checkSave()"  ></Datepicker>
										</div>
										
										<div class="form-group col-md-6 col-12">
											<label><b>{{ $t('msgCustomWorking.WorkEnd') }}</b> </label>
											
											<Datepicker v-model="taskItem.work_end" format="yyyy-MM-dd HH:mm" :locale="$i18n.locale"  :cancelText="$t('msgCore.Cancel')" :selectText="$t('msgCore.Select')"  @update="checkSave()" ></Datepicker>
										</div>
									</div>	
										
									<div class="form-row">	
										<div class="form-group col-md-6 col-12">
											<label><b>{{ $t('msgCore.Time') }} [min]</b> </label>
											<input type="text" class="form-control form-control-sm" :disabled="taskItem.timeIsDisabled" v-model="taskItem.work_time" >
										</div>
										
										<div class="form-group col-md-6 col-12">
											<label><b>{{ $t('msgCore.Price') }} [€/hod]</b> </label>
											<input type="text" class="form-control form-control-sm"  v-model="taskItem.price" >
										</div>
									
																					
										<div class="form-group col-md-6 col-12">
											<label :class="{'text-danger':taskItem.typeError}"><b>{{ $t('msgCore.Type') }}</b></label>
																								
											<select v-model='taskItem.type' class="form-control form-control-sm " :class="{'is-invalid':taskItem.typeError}"  v-on:change="checkSave()" >
												<option  value="">{{ $t('msgCore.selectType') }}</option>
												<option v-for="(option ) in optionsTaskTypes" :key="option" :value="option.value">{{ $t('msgCustomWorking.'+option.title) }}</option>
											</select>
											<div v-show="taskItem.typeError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.selectType')}}</span></div>
										</div>
										
										<div class="form-group col-md-6 col-12">
											<label :class="{'text-danger':taskItem.statusError}"><b>{{ $t('msgCore.Status') }}</b></label>
																								
											<select v-model='taskItem.status' class="form-control form-control-sm " :class="{'is-invalid':taskItem.statusError}"   v-on:change="checkSave()" >
												<option  value="">{{ $t('msgCore.selectStatus') }}</option>
												<option v-for="(option) in optionsTaskStates" :key="option" :value="option.value">{{ $t('msgCustomWorking.'+option.title) }}</option>
											</select>
											<div v-show="taskItem.statusError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.selectStatus')}}</span></div>
										</div>
										
									</div>	
								
									
							</div>
							
							<div v-else class="modal-body">
								<div class="row">
									<div class="col-12">
										<div class="alert alert-warning" role="alert">
											{{$t('msgCore.accessDenied')}}
										</div>
									</div>
								</div>
							</div>
							
							
							
							
							<div class="modal-footer">
							
								<div class="btn-group">
									<button type="button" v-on:click="closeModalBoxSecureHelper('myModalEditTaskItem')" class="btn btn-default btn-sm">{{ $t('msgCore.btnClose') }}</button>
									
									<button type="button" :disabled="((!auth.tasksEdit) || (!auth.tasksNew)) || !accData.modulAcc" v-on:click="saveTaskItem()" class="btn btn-warning btn-sm">{{ $t('msgCore.btnSave') }}</button>	
								</div>	
								
							</div>
						
				
					
					
				
				</div>

			</div>
			
		</div>
		

	
	

</template>


