<script>


//import $ from 'jquery'; 

import Popper from "vue3-popper";
import { useToast } from "vue-toastification";

//import ProjectSelectIntoPhoto from '@/components/Core/Projects/ProjectSelectIntoPhoto'





import axios from 'axios';

//general functions
import MixinGeneralFunct from '@/mixins/GeneralFunctions';
import { defineComponent } from 'vue'



export default defineComponent ({
	setup() {
		const toast = useToast();
		return { toast }
	},
	name: 'ProjectEdit',
	mixins: [ MixinGeneralFunct ],
	components: {
		Popper
	},
	props: {'projectId': String, 'auth':Object, 'secData':Object},
	emits: ["projectId","changeRecord"],
	data() {
	
		return {
			prefix:'',
			
			params:[],
			accData: [],
			
			preloader:true,
			
			isNewRecord:true,

			optionsAccessLevels: [],
			optionsTypes:[],
			optionsTemplates:[],
			optionsStates:[],
			optionsLanguages:[],
			
			initObj: {
				
				relative_urls: false
				,convert_urls: false
				,remove_script_host:false
				,height:450
				,entity_encoding : "raw" 
				,language:'sk'
				,extended_valid_elements: 'i[class]'
				,iconfonts_selector: '.fa, .fab, .fal, .far, .fas, .glyphicon'
			},
			
						
			//form
			project:[],
			template:[]
			
		}
	},
	
	mounted:function(){
		
	},
	
	methods: {
		// load data form
		loadDefaultValues:function(){
			
			this.preloader=true
			//console.log(this.projectId)		
			if(this.projectId=='null'){
				this.isNewRecord=true;
			}else if(this.projectId && this.projectId>0){
				this.isNewRecord=false;
			}
			
			var bodyFormData = new FormData();
			bodyFormData.set('projectId',this.projectId);
			
			
			var self=this;
			axios({
				method: 'post',
				url: '/admins/app/customWorking/WorkingProjects/getProjectData.php',
				data: bodyFormData
			})
			.then(function (response) {
			
				//console.log(response.data);
				self.accData = response.data.accData;
				
				//hide loader
				self.preloader=false
				
				self.accData = response.data.accData;
								
				self.project = response.data.project;
				self.template = response.data.template;
				
				self.optionsAccessLevels = response.data.optionsAccessLevels;
				self.optionsStates = response.data.optionsStates;
				self.optionsProjectTypes = response.data.optionsProjectTypes;
		
			})
				.catch(function (error) {
				console.log(error);
				
				
			});
		
		},

		
		
		closeModalBoxSecureHelper:function(myform,projectId){
			this.unlockProject(projectId);
			//this.loadDefaultValues();
			this.closeModalBoxSecure(myform);
		},
		
		
		
		
		

		checkSave:function(){
								
			if(!this.project.title){this.project.titleError = true; }else{this.project.titleError = false;}
							
			if(this.project.title){
				return true;
			}else{
				this.toast.warning(this.$t('msgCore.fillInAllData'));
				return false;
			}
		},	
		
		saveProject:function(){

			if(this.checkSave()){
				
				var bodyFormData = new FormData();
				bodyFormData.set('myProject',JSON.stringify(this.project));
				
				
				var self=this;
				axios({
					method: 'post',
					url: '/admins/app/customWorking/WorkingProjects/controlProjectSave.php',
					data: bodyFormData
				})
				.then(response => {
					if (response.data.error) {
							console.log('error', response.data.error)
					} else {
						//console.log(response.data);
						if(response.data.resCode==1){
							self.toast.success(this.$t('msgCore.dataWasSaved'));
							self.$emit('changeRecord', '')
						
							self.closeModalBoxSecure('myModalEditProject');
							
						}else if(response.data.resCode==0){
							self.toast.warning(this.$t('msgCore.dataCantBeSaved'));
							self.closeModalBoxSecure('myModalEditProject');
						}else if(response.data.resCode==2){
							self.toast.error(this.$t('msgCore.dataWasNotSaved'));
							self.closeModalBoxSecure('myModalEditProject');
						}else if(response.data.resCode==3){
							self.toast.error(this.$t('msgCore.accessDenied'));
							self.closeModalBoxSecure('myModalEditProject');
						}
						self.project = [];
					}
				})
				.catch(error => {
					console.log(error.response)
				}); 
				
				
			}
			
		},
		
		//odomknutie zaznamu
		unlockProject:function(projectId){
			
			this.cSession();
			var bodyFormData = new FormData();
			bodyFormData.set('myRecordID',projectId);
				
			var self = this;
			axios({
				method: 'post',
				url: '/admins/app/customWorking/WorkingProjects/controlProjectUnlock.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
					//console.log(response.data);
					if(response.data==1){
						self.$emit('changeRecord', '')
					}else if(response.data==0){
						//xx
					}else if(response.data==2){
						//xx
					}else if(response.data==3){
						//xx
					}
					
				}
			})
			.catch(error => {
				console.log(error.response)
			}); 
			
		},

		//get count chars
	/*	getCountChars:function(){
			this.countCharQuestion=this.countCharQuestionLimit - this.question.title.length;
			if(this.countCharQuestion<0){this.question.countCharError = true; }else{this.question.countCharError = false;}
		},*/

	},
	
	watch: {
		projectId() {
			this.loadDefaultValues();
		}
	},

	computed:{
		
	},
	
})

</script>

<template>
	<div id="msg"></div>
	<div id="myModalEditProject" class="fade show" role="dialog" style="z-index: 1040;  display: none;">
			<div class="modal-dialog modal-lg" style="width: 100%; max-width: none;">
				<div v-show="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
				<div class="modal-content">
					<div class="modal-header">
						<h6 class="modal-title">{{ $t('msgCustomWorking.Project') }} <span v-if="!project.isNewRecord"><b>{{ project.title }}</b> id: {{project.id}}</span></h6>
					</div>
			
							<div v-if="accData.modulAcc && (auth.projectsEdit || auth.projectsNew)" class="modal-body">
											
								<div class="row">
									<div class="col-12">
										<div v-if="project.isCheckedOut && secData.userId != project.isCheckedOutId" class="angle">
											<span class="angle-inner" :class="{'angle-warning':project.isCheckedOut}"><i class="fa" :class="{'angle-warning':project.isCheckedOut, 'fa-exclamation-triangle':project.isCheckedOut}" aria-hidden="true"></i> {{$t('msgCore.Edited')}} </span >
											
											<Popper v-if="project.isCheckedOut" :content="project.isCheckedOutName+', '+project.isCheckedOutDate">
												<button class="personCircle v-center" v-bind:style="{background:project.isCheckedOutColor}">{{ project.isCheckedOutLetterName+''+project.isCheckedOutLetterSurname }}</button>
											</Popper>
											
										</div>
									</div>
								</div>

									<div class="form-row">
										
										<div class="form-group col-md-6 col-12">
											<label><b>{{ $t('msgCore.title') }}</b> </label>
											<input type="text" class="form-control form-control-sm" :class="{'is-invalid':project.titleError}" v-on:change="checkSave()" v-model="project.title" >
											<div v-show="project.titleError" class="error-input">vyplňte</div>
										</div>
										
										
										<div class="form-group col-md-12 col-12">
											<label><b>{{ $t('msgCore.description') }}</b></label>
											<input type="text" class="form-control form-control-sm" v-model="project.description" >
										</div>
										
										<div class="form-group col-12">
											<label><b>{{ $t('msgCore.url') }}</b></label>
											<input type="text" class="form-control form-control-sm" v-model="project.url">
										</div>
										
										<div class="form-group col-12">
											<label><b>{{ $t('msgCustomWorking.UrlAdmin') }}</b> </label>
											<input type="text" class="form-control form-control-sm" v-model="project.url_admin" >
										</div>
										
										<div class="form-group col-md-6 col-12">
											<label><b>{{ $t('msgCustomWorking.Hosting') }}</b> </label>
											<input type="text" class="form-control form-control-sm" v-model="project.hosting" >
										</div>
										
										<div class="form-group col-md-6 col-12">
											<label><b>{{ $t('msgCustomWorking.Platform') }}</b></label>
											<input type="text" class="form-control form-control-sm" v-model="project.platform">
										</div>
										
									</div>	
								
									
							</div>
							
							<div v-else class="modal-body">
								<div class="row">
									<div class="col-12">
										<div class="alert alert-warning" role="alert">
											{{$t('msgCore.accessDenied')}}
										</div>
									</div>
								</div>
							</div>
							
							
							
							
							<div class="modal-footer">
							
								<div class="btn-group">
									<button type="button" v-on:click="closeModalBoxSecureHelper('myModalEditProject')" class="btn btn-default btn-sm">{{ $t('msgCore.btnClose') }}</button>
									
									<button type="button" :disabled="((!auth.projectsEdit) || (!auth.projectsNew)) || !accData.modulAcc" v-on:click="saveProject()" class="btn btn-warning btn-sm">{{ $t('msgCore.btnSave') }}</button>	
								</div>	
								
							</div>
						
				
					
					
				
				</div>

			</div>
			
		</div>
		

	
	

</template>


