<script>


//import $ from 'jquery'; 

import Popper from "vue3-popper";
import { useToast } from "vue-toastification";

//import CampaignSelectIntoPhoto from '@/components/Core/Campaigns/CampaignSelectIntoPhoto'



import Editor from '@tinymce/tinymce-vue'
//var Editor = require('@tinymce/tinymce-vue').default;



/* Import TinyMCE */
 //import tinymce from 'tinymce';

 /* Default icons are required for TinyMCE 5.3 or above */
 import 'tinymce/icons/default';

 /* A theme is also required */
 import 'tinymce/themes/silver';

 /* Import the skin */
 import 'tinymce/skins/ui/oxide/skin.css';

 /* Import plugins */
 import 'tinymce/plugins/advlist';
 import 'tinymce/plugins/code';
 import 'tinymce/plugins/emoticons';
 import 'tinymce/plugins/emoticons/js/emojis';
 import 'tinymce/plugins/link';
 import 'tinymce/plugins/lists';
 import 'tinymce/plugins/table';


import axios from 'axios';

//general functions
import MixinGeneralFunct from '@/mixins/GeneralFunctions';
import { defineComponent } from 'vue'



export default defineComponent ({
	setup() {
		const toast = useToast();
		return { toast }
	},
	name: 'CampaignEdit',
	mixins: [ MixinGeneralFunct ],
	components: {
		'editor': Editor
		,Popper
	},
	props: {'campaignId': String, 'auth':Object, 'secData':Object},
	emits: ["campaignId","changeRecord"],
	data() {
	
		return {
			prefix:'',
			
			params:[],
			accData: [],
			
			preloader:true,
			
			isNewRecord:true,

			optionsAccessLevels: [],
			optionsTypes:[],
			optionsTemplates:[],
			optionsStates:[],
			optionsLanguages:[],
			
			initObj: {
				
				relative_urls: false
				,convert_urls: false
				,remove_script_host:false
				,height:450
				,entity_encoding : "raw" 
				,language:'sk'
				,extended_valid_elements: 'i[class]'
				,iconfonts_selector: '.fa, .fab, .fal, .far, .fas, .glyphicon'
			},
			
						
			//form
			campaign:[],
			template:[]
			
		}
	},
	
	mounted:function(){
		
	},
	
	methods: {
		// load data form
		loadDefaultValues:function(){
			
			this.preloader=true
			//console.log(this.campaignId)		
			if(this.campaignId=='null'){
				this.isNewRecord=true;
			}else if(this.campaignId && this.campaignId>0){
				this.isNewRecord=false;
			}
			
			var bodyFormData = new FormData();
			bodyFormData.set('campaignId',this.campaignId);
			bodyFormData.set('templateId',this.campaign.templateId);
			
			var self=this;
			axios({
				method: 'post',
				url: '/admins/app/customMailing/MailingCampaigns/getCampaignData.php',
				data: bodyFormData
			})
			.then(function (response) {
			
				console.log(response.data);
				self.accData = response.data.accData;
				
				//hide loader
				self.preloader=false
				
				self.accData = response.data.accData;
								
				self.campaign = response.data.campaign;
				self.template = response.data.template;
				
				self.optionsAccessLevels = response.data.optionsAccessLevels;
				self.optionsStates = response.data.optionsStates;
				self.optionsTypes = response.data.optionsTypes;
				self.optionsTemplates = response.data.optionsTemplates;
				self.optionsLanguages = response.data.optionsLanguages;
		
			})
				.catch(function (error) {
				console.log(error);
				
				
			});
		
		},

		// load template data
		loadTemplateData:function(){
			
						
			var bodyFormData = new FormData();
			bodyFormData.set('templateId',this.campaign.templateId);
			
			var self=this;
			axios({
				method: 'post',
				url: '/admins/app/customMailing/MailingCampaigns/loadTemplateDataToPreview.php',
				data: bodyFormData
			})
			.then(function (response) {
			
			//	console.log(response.data);
				
				self.template = response.data.template;
				
			})
				.catch(function (error) {
				console.log(error);
				
				
			});
		
		},
		
		closeModalBoxSecureHelper:function(myform,campaignId){
			this.unlockCampaign(campaignId);
			//this.loadDefaultValues();
			this.closeModalBoxSecure(myform);
		},
		
		
		
		
		checkSave:function(){
								
			if(!this.campaign.name){this.campaign.nameError = true; }else{this.campaign.nameError = false;}
			if(!this.campaign.access){this.campaign.accessError = true; }else{this.campaign.accessError = false;}
			if(!this.campaign.typeId){this.campaign.typeError = true; }else{this.campaign.typeError = false;}
			if(!this.campaign.templateId && this.campaign.typeId == 1){this.campaign.templateError = true; }else{this.campaign.templateError = false;}
			
			if(this.campaign.name && this.campaign.access && this.campaign.typeId && !this.campaign.templateError){
				return true;
			}else{
				return false;
			}
		},	
		
		saveCampaign:function(){

			if(this.checkSave()){
				
				var bodyFormData = new FormData();
				console.log(this.campaign)
				bodyFormData.set('myCampaign',JSON.stringify(this.campaign));
				
				
				var self=this;
				axios({
					method: 'post',
					url: '/admins/app/customMailing/MailingCampaigns/controlCampaignSave.php',
					data: bodyFormData
				})
				.then(response => {
					if (response.data.error) {
						console.log('error', response.data.error)
					} else {
						console.log(response.data);
						
						if(response.data.resCode==1){
							self.toast.success(this.$t('msgCore.dataWasSaved'));
							self.$emit('changeRecord', '')
							self.closeModalBoxSecure('myModalEditCampaign');
						}else if(response.data.resCode==0){
							self.toast.warning(this.$t('msgCore.dataCantBeSaved'));
							self.closeModalBoxSecure('myModalEditCampaign');
						}else if(response.data.resCode==2){
							self.toast.error(this.$t('msgCore.dataWasNotSaved'));
							self.closeModalBoxSecure('myModalEditCampaign');
						}else if(response.data.resCode==3){
							self.toast.error(this.$t('msgCore.accessDenied'));
							self.closeModalBoxSecure('myModalEditCampaign');
						}
						self.campaign = [];
					}
				})
				.catch(error => {
					console.log(error.response)
					self.toast.error(error.response.status + '|' + error.response.statusText );
				}); 
				
				
			}else{
				this.toast.warning(this.$t('msgCore.fillInAllData'));
			}
			
		},
		
		//odomknutie zaznamu
		unlockCampaign:function(campaignId){
			
			this.cSession();
			var bodyFormData = new FormData();
			bodyFormData.set('myRecordID',campaignId);
				
			var self = this;
			axios({
				method: 'post',
				url: '/admins/app/customMailing/MailingCampaigns/controlCampaignUnlock.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
					//console.log(response.data);
					if(response.data==1){
						self.$emit('changeRecord', '')
					}else if(response.data==0){
						//xx
					}else if(response.data==2){
						//xx
					}else if(response.data==3){
						//xx
					}
					
				}
			})
			.catch(error => {
				console.log(error.response)
			}); 
			
		},

		//get count chars
	/*	getCountChars:function(){
			this.countCharQuestion=this.countCharQuestionLimit - this.question.title.length;
			if(this.countCharQuestion<0){this.question.countCharError = true; }else{this.question.countCharError = false;}
		},*/

	},
	
	watch: {
		campaignId() {
			this.loadDefaultValues();
		}
	},

	computed:{
		
	},
	
})

</script>

<template>
	<div id="msg"></div>
	<div id="myModalEditCampaign" class="fade show" role="dialog" style="z-index: 1040;  display: none;">
			<div class="modal-dialog modal-lg" style="width: 100%; max-width: none;">
				<div v-show="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
				<div class="modal-content">
					<div class="modal-header">
						<h6 class="modal-title">{{ $t('msgCustomMailing.Campaign') }} <span v-if="!campaign.isNewRecord"><b>{{ campaign.title }}</b> id: {{campaign.id}}</span></h6>
					</div>
			
							<div v-if="accData.modulAcc && (auth.campaignsNew || auth.campaignsEdit)" class="modal-body">
								
								<div class="row">
									<div class="col-12">
										<div v-if="campaign.isCheckedOut && secData.userId != campaign.isCheckedOutId" class="angle">
											<span class="angle-inner" :class="{'angle-warning':campaign.isCheckedOut}"><i class="fa" :class="{'angle-warning':campaign.isCheckedOut, 'fa-exclamation-triangle':campaign.isCheckedOut}" aria-hidden="true"></i> {{$t('msgCore.Edited')}} </span >
											
											<Popper v-if="campaign.isCheckedOut" :content="campaign.isCheckedOutName+', '+campaign.isCheckedOutDate">
												<button class="personCircle v-center" v-bind:style="{background:campaign.isCheckedOutColor}">{{ campaign.isCheckedOutLetterName+''+campaign.isCheckedOutLetterSurname }}</button>
											</Popper>
											
										</div>
									</div>
								</div>
							
								<div class="row">
									
									<div class="col-md-8 col-12">
								
										<ul class="nav nav-tabs" role="tablist">
							
											<li class="nav-item">
												<a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">{{ $t('msgCore.basicData') }}</a>
											</li>
											
											<li v-if="campaign.typeId==1" class="nav-item">
												<a class="nav-link" id="menu3-tab" data-toggle="tab" href="#menu3" role="tab" aria-controls="menu3" aria-selected="false">Email text</a>
											</li>

											<li v-if="campaign.typeId==1" class="nav-item">
												<a class="nav-link" id="menu33-tab" data-toggle="tab" href="#menu33" role="tab" aria-controls="menu33" aria-selected="false">Email náhľad</a>
											</li>

											<li v-if="campaign.typeId==2" class="nav-item">
												<a class="nav-link" id="menu4-tab" data-toggle="tab" href="#menu4" role="tab" aria-controls="menu4" aria-selected="false">SMS text</a>
											</li>
											
										</ul>

										<div class="tab-content" id="myTabContent">
								
											<div id="home" class="tab-pane fade show active" role="tabpanel">
															
									
												<div class="form-row">
													
																									
													<div class="form-group col-md-4 col-12">
														<label :class="{'text-danger':campaign.nameError}"><b>{{ $t('msgCore.Title') }}</b> </label>
														<input type="text" class="form-control form-control-sm" :class="{'is-invalid':campaign.nameError}" v-on:change="checkSave()" v-model="campaign.name" >
														<div v-show="campaign.nameError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.fillIn')}}</span></div>
													</div>
													
													<div class="form-group col-md-4 col-12">
														<label :class="{'text-danger':campaign.typeError}"><b>{{ $t('msgCore.Type') }}</b> </label>								
														<select :disabled="!isNewRecord"  v-model='campaign.typeId' class="form-control form-control-sm" :class="{'is-invalid':campaign.typeError}" v-on:change="checkSave()">
															<option  value="">{{ $t('msgCore.selectType') }}</option>
															<option v-for="(options) in optionsTypes" :value="options.value" :key="options.value" >{{ options.title }}</option>
														</select>
														<div v-show="campaign.typeError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.fillIn')}}</span></div>
													</div>

													<div v-if="campaign.typeId==1" class="form-group col-md-4 col-12">
														<label :class="{'text-danger':campaign.templateError}"><b>{{ $t('msgCustomMailing.Template') }}</b> </label>								
														<select v-model='campaign.templateId' class="form-control form-control-sm" :class="{'is-invalid':campaign.templateError}" v-on:change="checkSave();loadTemplateData();">
															<option  value="">{{ $t('msgCustomMailing.selectTemplate') }}</option>
															<option v-for="(options) in optionsTemplates" :value="options.value" :key="options.value" >{{ options.title }}</option>
														</select>
														<div v-show="campaign.templateError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.fillIn')}}</span></div>
													</div>
													
												</div>	
											</div>
											
											<div v-if="campaign.typeId==1" id="menu3" class="tab-pane fade" role="tabpanel">
												<div class="form-group">
													<label ><b>{{ $t('msgCustomMailing.Subject') }}</b> </label>
													<input type="text" class="form-control form-control-sm" v-on:change="checkSave()" v-model="campaign.subject" >
													
												</div>

												<div class="form-group">
													<label ><b>{{ $t('msgCustomMailing.BodyMessage') }}</b> </label>
													<editor  plugins="wordcount, advlist, autolink, link, image, lists, charmap, print, preview, hr, anchor, pagebreak, spellchecker, searchreplace, wordcount, visualblocks, visualchars, code, fullscreen, insertdatetime, media, nonbreaking, save, table, directionality, emoticons, template, paste" v-model="campaign.full_text" :init="initObj" toolbar="alignjustify aligncenter myPhotoButton myGalleryButton myGalleryPluginButton myLibraryPluginButton myDocumentButton" class="form-control form-control-sm" />
												</div>
											</div>

											<div v-if="campaign.typeId==1" id="menu33" class="tab-pane fade" role="tabpanel">
												<div class="row">
													<div class="col-12" v-html="template.logo"></div>
												</div>
												<div class="row">
													<div class="col-12">
														<table>
															<tr>
																<td v-html="campaign.full_text">

																</td>
															</tr>
														</table>
													</div>
												</div>
												<div class="row">
													<div class="col-12" v-html="template.pata"></div>
												</div>
											</div>

											<div v-if="campaign.typeId==2" id="menu4" class="tab-pane fade" role="tabpanel">
												<div class="form-group">
													<label ><b>SMS</b> ({{campaign.sms_text.length}})</label>
													<textarea class="form-control form-control-sm" v-model="campaign.sms_text" ></textarea>
													
												</div>
											</div>
											
											
												
										</div>
										
									</div>
									
									<div class="col-md-4 col-12 form-control-system-data">
											
										<div class="form-row">
										
											<div class="form-group col-12">
												<label :class="{'text-danger':campaign.accessError}"><b>{{ $t('msgCore.AccessLevel') }}</b></label>
																									
												<select v-model='campaign.access' class="form-control form-control-sm " :class="{'is-invalid':campaign.accessError}" v-on:change="checkSave()">
													<option value="">{{ $t('msgCore.selectLevel') }}</option>
													<option v-for="(option) in optionsAccessLevels" :value="option.value" :key="option">{{ option.title }}</option>
												</select>
												<div v-show="campaign.accessError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.selectLevel')}}</span></div>
											</div>
											
											<div class="form-group col-12">
												<label :class="{'text-danger':campaign.stateError}"><b>{{ $t('msgCore.State') }}</b></label>
												
												<div  v-for="(option) in optionsStates" :key="option.value" class="form-check">
													<label class="form-check-label">
														<input class="form-check-input" type="radio" name="optionsStates"  v-model='campaign.state' v-on:change="checkSave()" :value="option.value">
														{{ $t('msgCore.'+option.title) }}
														<span class="circle">
															<span class="check"></span>
														</span>
													</label>
												</div>
												<div v-show="campaign.stateError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.selectState')}}</span></div>
											</div>
											
											
										
										</div>
										
									</div>										
																		
								</div>
								
							</div>
							
							<div v-else class="modal-body">
								<div class="row">
									<div class="col-12">
										<div class="alert alert-warning" role="alert">
											{{$t('msgCore.accessDenied')}}
										</div>
									</div>
								</div>
							</div>
							
							<div class="modal-footer">
							
								<div class="btn-group">
									<button type="button" v-on:click="closeModalBoxSecureHelper('myModalEditCampaign',campaign.id)" class="btn btn-default btn-sm">{{ $t('msgCore.btnClose') }}</button>
									
									<button type="button" :disabled="!auth.campaignsEdit || !accData.modulAcc" v-on:click="saveCampaign()" class="btn btn-warning btn-sm">{{ $t('msgCore.btnSave') }}</button>	
								</div>	
								
							</div>
						
				
					
					
				
				</div>

			</div>
			
		</div>
		

	
	

</template>


