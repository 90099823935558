<script>

import $ from 'jquery'; 
import { useToast } from "vue-toastification";
import MyPagination from '@/components/SystemElements/Pagination.vue'
import MyCountRecords from '@/components/SystemElements/CountRecordsOptions.vue'

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";
Fancybox.bind("[data-fancybox]", {
  // Your options go here
});

import Popper from "vue3-popper";


import ProjectEdit from '@/components/CustomWorking/WorkingProjects/ProjectEdit.vue'
import ProjectDelete from '@/components/CustomWorking/WorkingProjects/ProjectDelete.vue'
import ProjectPhotos from '@/components/CustomWorking/WorkingProjects/ProjectPhotos.vue'


//general functions
import MixinGeneralFunct from '@/mixins/GeneralFunctions';

import axios from 'axios';

export default {
	setup() {
		const toast = useToast();
		return { toast }
	},
	name: 'Projects',
	mixins: [ MixinGeneralFunct ],
	components: {
		//"font-awesome-icon":FontAwesomeIcon
		ProjectEdit,ProjectDelete,ProjectPhotos
		,Popper
		,MyPagination,MyCountRecords
 
	},
	props: {'auth':Object, 'secData':Object, 'params':Object},
	data() {
		return {
			prefix:'',
		
            
            accData:[],
            
            preloader:true,

            menuItems:[],
            actLangs:[],
            
            helpboxData: [],
            
            basicDataCount:0,
            basicData:[],
            selectedFilterText:'',
            
            selectedType:'',
            optionsTypes:[],

            selectedProjectId:'',

            countPages:0,

			selectedPage:1,
			selectedRecords:10,
			selectedCol:'a.id',
			selectedSort:'desc',
			
			selectedIds:[],
			selectAllIds:false,
			
			
		
			showFilter:false,
			filterExtended:true,
			
			showedHelpbox:false


		}
	},
	
	mounted:function(){
		
	},
	
	methods: {
				
		loadDefaultValues:function(){
			//console.log(this.auth)
			//show loader
			this.preloader=true
			
			var self = this;
			axios.get('/admins/app/customWorking/WorkingProjects/getProjectList.php',{
				params:{
					filterText: this.selectedFilterText,
					filterType: this.selectedType,
					
					//system
					selectedIds: this.selectedIds,
					orderCol: this.selectedCol,
					orderSort: this.selectedSort,
					page: this.selectedPage,
					records: this.selectedRecords
					
				}
			})
			.then(function (response) {
				//console.log(response.data)
			
                //hide loader
				self.preloader=false

                //access data
				self.accData = response.data.accData;
				
				//helpbox data
				self.helpboxData = 'guidebook.guideProjects';
										
			//	if(app.accessToEmployee){
					

					self.countPages = response.data.countPages;

					self.basicDataCount = response.data.basicDataCount;
					self.basicData = response.data.basicData;
					
					self.optionsTypes = response.data.optionsTypes;
					
					//oznacenie poloziek cez strankovac
					var indexToSelect;
					var selectedIdsTEMP = [];
					selectedIdsTEMP = response.data.selectedIds; 
					self.selectedIds = [];
					for(let i = 0; i < selectedIdsTEMP.length; i++){
						self.selectedIds.push(Number(selectedIdsTEMP[i]));
					}
					for(let i = 0; i < self.basicData.length; i++){
						indexToSelect = self.selectedIds.indexOf(self.basicData[i].id);
						if(indexToSelect>=0){
							self.basicData[i].selectedId=true;
						}
					}
					self.selectAllIds = false;
					
					
					
					//app.countLimit();
					
			//	}
				
			})
				.catch(function (error) {
				console.log(error);
				
				
			});
		
		},
		
				
		//odomknutie zaznamu
		unlockProject:function(groupId){
			
			this.cSession();
			var bodyFormData = new FormData();
			bodyFormData.set('myRecordID',groupId);
				
			var self = this;
			axios({
				method: 'post',
				url: '/admins/app/customWorking/WorkingProjects/controlProjectUnlock.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
					//console.log(response.data);
					if(response.data==1){
						self.toast.success(this.$t('msgCore.dataWasUnlocked'));
						self.loadDefaultValues();
					}else if(response.data==0){
						self.toast.warning(this.$t('msgCore.dataCantBeUnlocked'));
						self.loadDefaultValues();
					}else if(response.data==2){
						self.toast.error(this.$t('msgCore.dataWasNotUnlocked'));
						self.loadDefaultValues();
					}else if(response.data==3){
						self.toast.error(this.$t('msgCore.accessDenied'));
						self.loadDefaultValues();
					}
					
				}
			})
			.catch(error => {
				console.log(error.response)
			}); 
			
		},
		
		
		
		
		
		//systemove funkcie k zoznamu
		//(od)oznacenie vsetkych poloziek
		unSelectIds:function(){
			if(this.selectAllIds){
				for(let i = 0; i < this.basicData.length; i++){
					this.basicData[i].selectedId=true;
					this.selectedIds.push(this.basicData[i].id);
				}
				this.selectedIds = this.selectedIds.filter( this.onlyUnique );
			}else{
				for(let i = 0; i < this.basicData.length; i++){
					this.basicData[i].selectedId=false;
					var indexToRemove = this.selectedIds.indexOf(this.basicData[i].id);
					if(indexToRemove>=0){
						this.selectedIds.splice(indexToRemove, 1);
						
					}
					//this.selectedIds = [];
				}
				this.selectedIds = this.selectedIds.filter( this.onlyUnique );
			}
			//console.log(this.selectedIds);
		},
		
		//oznacenie jednej polozky
		checkSelectIds:function(index,id){
			if(this.basicData[index].selectedId==true){
				this.selectedIds.push(id);
			}else{
				var indexToRemove = this.selectedIds.indexOf(id);
				this.selectedIds.splice(indexToRemove, 1);
			}
			
			this.selectedIds = this.selectedIds.filter( this.onlyUnique );	
			//console.log(this.selectedIds);
		},
		
		//radenie zoznamu - orders by
		orderByCol:function(col){
			
			if(col === this.selectedCol) {
				this.selectedSort = this.selectedSort==='asc'?'desc':'asc';
			}
			this.selectedCol = col;
			
			this.loadDefaultValues();
		},
		
		//select page 
		selPage:function(x){
			
			if(x=='prev' || x=='next'){
				if(x=='prev'&&this.selectedPage!=1){this.selectedPage=this.selectedPage-1}
				if(x=='next'&&this.selectedPage!=this.countPages){this.selectedPage=this.selectedPage+1}
			}else{
				if(this.selectedPage != x){
					this.selectedPage = Number(x);
				}
			}
			//console.log(this.selectedPage);
			this.loadDefaultValues();
			
		},

		//active menu
		isActive: function (col) {
			return this.selectedCol === col;
		},
		isAsc: function (col) {
			if(this.selectedCol === col && this.selectedSort === 'asc'){
				return true;
			}
		},
		isDesc: function (col) {
			if(this.selectedCol === col && this.selectedSort === 'desc'){
				return true;
			}
		},
		
		//zobrazi filtre
		showFilters: function(){
			if(!this.showFilter) {
				this.showFilter = true;
			}else{
				this.showFilter = false;
			}
		},
		
		//vycisti text filter
		resetTextFilter: function(){
			this.selectedFilterText = '';
			this.loadDefaultValues();
		},
		
		// zobrazi napovedu
		showHelpbox: function(){
			if(!this.showedHelpbox) {
				this.showedHelpbox = true;
			}else{
				this.showedHelpbox = false;
			}
		},
		// systemove funkcie k zoznamu KONIEC
		
		
		//modal box  editacia
		loadModalFormProjectEdit:function(recordID){
			this.cSession();
			//console.log(recordID)
			this.selectedProjectId=String(recordID);
			$('#myModalEditProject').addClass('modal');
			$('#myModalEditProject').modal({backdrop: "static"});
		},
		
		//modal box  delete
		loadModalFormProjectDelete:function(recordID){
			this.cSession();
			//console.log(recordID)
			this.selectedProjectId=String(recordID);
			$('#myModalDeleteProject').addClass('modal');
			$('#myModalDeleteProject').modal({backdrop: "static"});
		},

		//modal box  preview photos
		loadModalFormProjectPhotosPreview:function(recordID){
			this.cSession();
			//console.log(recordID)
			this.selectedProjectId=String(recordID);
			$('#myModalPreviewProjectPhoto').addClass('modal');
			$('#myModalPreviewProjectPhoto').modal({backdrop: "static"});
		},
		
		

		
		reloadAfterChange() {
			//console.log(e);
			this.loadDefaultValues();
			this.selectedProjectId='';
			//this.cart.push(e);
			//this.total = this.shoppingCartTotal;
		},

		//vyber stranky v strankovaci
		reloadAfterChangeNrPage(selectedPage) {
			this.selectedPage=selectedPage
			this.loadDefaultValues();
		},

		//vyber poctu zaznamov
		reloadAfterChangeCountRecords(selectedCount) {
			this.selectedRecords=selectedCount
			this.loadDefaultValues();
		},
		
		//zmena stavu
		changeState:function(recordId,state){
			this.cSession();
			var bodyFormData = new FormData();
			bodyFormData.set('recordId',recordId);
			bodyFormData.set('state',state);
			
			var self = this;
			axios({
				method: 'post',
				url: '/admins/app/customWorking/WorkingProjects/controlProjectChangeState.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
					//console.log('success2', response.data);
					if(response.data.resCode==1){
						self.toast.success(this.$t('msgCore.dataWasSaved'));
						self.loadDefaultValues();
					}else if(response.data.resCode==0){
						self.toast.warning(this.$t('msgCore.dataCantBeSaved'));
					}else if(response.data.resCode==2){
						self.toast.warning(this.$t('msgCore.dataCantBeSaved'));
					}else if(response.data.resCode==3){
						self.toast.error(this.$t('msgCore.accessDenied'));
					}
				}
			})
			.catch(error => {
				console.log(error.response)
			}); 
	
		}
		
	},	
	
			
	watch: {
		'auth.projectsList': function () {
			this.loadDefaultValues();
		}
	},

	computed:{
		
	},
	
}

</script>

<template>
	<div>

		<div v-show="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
		<div id="version" class="version"><p>{{accData.version}}</p></div>

		<div v-if="accData.modulAcc" > 
					
            <div class="row">
                <div class="col-12">
                                    
					<div class="row">
						<div class="col-6">
							<h5>{{ $t('msgCustomWorking.Projects') }}</h5>	 
						</div> 
						
						<div class="col-6 right">
							<button type="button" :disabled="!auth.projectsNew" v-on:click="loadModalFormProjectEdit(null)" class="btn btn-primary btn-sm"><i class="smire-icon fas fa-plus"></i> {{ $t('msgCore.btnAdd') }}</button>
						</div> 
					</div>

					<!-- filters basic -->
					<div class="row">
						<div class="input-group col-md-4 col-12">
							<button type="button" :title="$t('msgCore.btnFilter')" class="btn btn-info btn-sm my-btn" v-on:click="showFilters()">
								<span v-if="filterExtended">
									<i v-if="!showFilter" class="smire-icon fas fa-search-plus"></i> 
									<i v-else class="smire-icon fas fa-search-minus"></i>
								</span>
								<span v-else>
									<i class="smire-icon fas fa-search"></i> 
								</span>
								{{ $t('msgCore.btnFilter') }}
							</button>
							<input type="text" class="form-control form-control-sm" v-model='selectedFilterText' :placeholder="$t('msgCore.searchTitle')" v-on:change="loadDefaultValues()">
							<button type="button" :title="$t('msgCore.btnStorno')" class="btn btn-outline-info btn-sm my-btn" v-on:click="resetTextFilter()">
								<i class="smire-icon fas fa-times"></i>
							</button>
						</div>
					</div>
					

					<!-- filters extended -->
					<div v-show="showFilter && filterExtended" class="row">
						<div class="input-group form-group-filter col-4">
					
							<select v-model='selectedType' class="form-control form-control-sm" v-on:change="loadDefaultValues()">
								<option value="">{{ $t('msgCore.selectType') }}</option>
								<option v-for="(options) in optionsTypes" :value="options.value" :key="options.value">{{ options.title }}</option>
							</select>
							
						</div>					
					</div>
                        
                    
                </div>
            </div>	

            
            <!-- zakladny zoznam -->
            <div v-if="basicDataCount>0">

				<div class="row">
					<div class="col-12">
	
						<div class="table-responsive">
							<table class="table table-hover table-sm">
							
								<thead>
									<tr>
										<th class="orderBy" v-on:click="sort('id')" :class="{ active: isActive('id'), asc: isAsc('id'), desc: isDesc('id')}">id</th>

										<th width="10" class="align-middle"><div class="form-check"><label class="form-check-label"><input v-model="selectAllIds" v-on:change="unSelectIds()" class="form-check-input" type="checkbox"><span class="form-check-sign"><span class="check"></span></span></label></div></th>

										<th>{{$t('msgCore.Action')}}</th>

										<th colspan="2">{{$t('msgCore.State')}}</th>

										<th>Náhľad</th>
										
										<th v-if="auth.tasksItemNew"><span class="green">{{$t('msgCustomWorking.paid')}}</span>/<span class="red">{{$t('msgCustomWorking.gratis')}}</span></th>
										
										<th>{{$t('msgCore.Title')}}</th>
										
										<th>{{$t('msgCore.Description')}}</th>
										
										<th>{{$t('msgCore.Url')}}</th>
										
										<th>{{$t('msgCustomWorking.UrlAdmin')}}</th>
										
										<th>{{$t('msgCustomWorking.Hosting')}}</th>
										
										<th>{{$t('msgCustomWorking.Platform')}}</th>
										
										<th>{{$t('msgCore.Created')}}</th>
                                            
                                        <th>{{$t('msgCore.Modified')}}</th>							
									</tr>
								</thead>
								
								
								<tr v-for="(basicObj, index) in basicData" :key="basicObj" >
								
									<td>{{ basicObj.id }}</td>

									<td class="align-middle">
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                <input v-model.number='basicObj.selectedId' class="form-check-input" type="checkbox" v-on:change="checkSelectIds(index,basicObj.id);">
                                                <span class="form-check-sign"><span class="check"></span></span>
                                                </label>
                                            </div>	
                                        </td>
								
									<td class="align-middle">
										<div class="input-group">	
											
												<button v-if="basicObj.isCheckedOut" type="button" :disabled="!auth.campaignsUnlock" v-on:click="unlockCampaign(basicObj.id)" v-bind:title="basicObj.checkedOutName+', '+basicObj.isCheckedOutDate" class="btn btn-sm"><i class="smire-icon fas fa-lock"></i></button>	
																				
											<div class="btn-group">				
												<button type="button" :disabled="!auth.projectsEdit && !secData.isSA" v-on:click="loadModalFormProjectEdit(basicObj.id)" :title="$t('msgCore.btnEdit')" id="myModalBtn" class="btn btn-secondary btn-sm"><i class="smire-icon fas fa-edit"></i></button>
										
												<button type="button" :disabled="!auth.projectsDelete && !secData.isSA" v-on:click="loadModalFormProjectDelete(basicObj.id)" :title="$t('msgCore.btnDelete')" id="myModalBtn" class="btn btn-danger btn-sm"><i class="smire-icon fas fa-trash-alt"></i></button>
											</div>	
											
											<div class="btn-group">				
												<button type="button" :disabled="!auth.projectDocumentView" v-on:click="loadModalFormDocumentsList(basicObj.id)" :title="$t('msgCore.Documents')" class="btn btn-warning btn-sm"><i class="smire-icon far fa-file"></i>
												<span class="btn-number-top-right">{{ basicObj.countDocuments }}</span>
												</button>
												
												<button type="button" :disabled="!auth.projectPhotoView" v-on:click="loadModalFormProjectPhotosPreview(basicObj.id)" :title="$t('msgCore.btnPreview')" class="btn btn-info btn-sm"><i class="smire-icon fas fa-images"></i>
												<span class="btn-number-top-right">{{ basicObj.countPhotos }}</span>
												</button>
											</div>
											
										</div>	
									</td>

									<td  :title="$t('msgCore.' + basicObj.state_name)">
										<i class="fas fa-circle" :class="{'text-success':basicObj.state==1,'text-danger':basicObj.state==2}" ></i>
									</td>

									<td>
										<div class="togglebutton">
											<label>
												<input v-model='basicObj.isActive' :disabled="!auth.projectsChangeState" type="checkbox" v-on:change="changeState(basicObj.id,basicObj.state)">
												<span class="toggle"></span>
											</label>
										</div>
									</td>
									
									<td>
										<a v-if="basicObj.photoDataPreview" class="fancybox" data-fancybox="gallery" :href="basicObj.photoData"><img :src="basicObj.photoDataPreview" style="height:80px;"></a>
										
									</td>
									
									<td v-if="auth.tasksItemNew" class="align-middle">
								
										
										<span v-for="(priceItem) in basicObj.priceData" :key="priceItem" >
											<span :class="priceItem.color">{{ priceItem.paid }}</span> /
											<span :class="priceItem.color">{{ priceItem.free }}</span>
										<br></span>
										
									</td>									
									
									<td >{{ basicObj.title }}</td>
									
									<td >{{ basicObj.description }}</td>
									
									<td >
										<a :href="basicObj.url" target="_blank">{{ basicObj.url }}</a>
									</td>
									
									<td >
										<a :href="basicObj.url_admin" target="_blank">{{ basicObj.url_admin }}</a>
									</td>
									
									<td >{{ basicObj.hosting }}</td>
									
									<td >{{ basicObj.platform }}</td>

										<td>
											<Popper :content="basicObj.creator+', '+basicObj.createdView">
												<button class="personCircle v-center" v-bind:style="{background:basicObj.creatorColor}">{{ basicObj.creatorNameLetter+''+basicObj.creatorSurnameLetter }}</button>
											</Popper>
                                        </td>

										<td>
											<Popper v-if="basicObj.editor" :content="basicObj.editor+', '+basicObj.modifiedView">
												<button class="personCircle v-center" v-bind:style="{background:basicObj.editorColor}">{{ basicObj.editorNameLetter+''+basicObj.editorSurnameLetter }}</button>
											</Popper>
                                        </td>
								</tr>
									

							
							</table>	
						</div>		
					
					</div>
				</div>

				<div class="row row-pagination">
					<div class="col-6 col-md-3 col-lg-2">
						<MyCountRecords :selectedRecords="selectedRecords" @changeAfterSelectCount="reloadAfterChangeCountRecords"></MyCountRecords>
					</div>
					
					<div class="col-6 col-md-3 col-lg-2">
						<div class="form-group form-group-filter">
							<span class="btn btn-default btn-sm">Počet: {{basicDataCount}}</span>
						</div>
					</div>
					
					<div class="col-12 col-md-6 col-lg-8">	
						<MyPagination :countPages="countPages" :selectedPage="selectedPage" @changeAfterSelectPage="reloadAfterChangeNrPage"></MyPagination>
					</div>
				</div>	
                    
                
            </div>	
            
            <div v-else class="row">
                <div class="col-12">
                    <div  class="alert alert-warning" role="alert">
                        {{$t('msgCore.forThisSelectExistAnyData')}}
                    </div>	
                </div>							
            </div>
            
        </div>	
        
        <div v-else class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="alert alert-warning" role="alert">
                        {{$t('msgCore.accessDenied')}}
                    </div>
                </div>
            </div>
        </div>
	
		<ProjectEdit :auth="auth" :secData="secData" :projectId="selectedProjectId" @changeRecord="reloadAfterChange"></ProjectEdit>
		<ProjectDelete :auth="auth" :secData="secData" :projectId="selectedProjectId" @changeRecord="reloadAfterChange"></ProjectDelete>
		<ProjectPhotos :auth="auth" :secData="secData" :projectId="selectedProjectId" @changeRecord="reloadAfterChange"></ProjectPhotos>
	</div>	
		
</template>


