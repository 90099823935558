<script>

var sha512 = require('js-sha512');

//general functions
import MixinGeneralFunct from '@/mixins/GeneralFunctions';

import axios from 'axios';
import { useToast } from "vue-toastification";
export default {
	setup() {
		const toast = useToast();
		return { toast }
	},
	name: 'UserChangePass',
	mixins: [ MixinGeneralFunct ],
	components: {

	},
	props: {'auth':Object, 'secData':Object, 'params':Object},
	data() {
		return {
			prefix:'',
		
			
			accData:[],
		
			preloader:true,
			
			basicData:[],
			userInputs:[]
		}
	},
	
	mounted:function(){
		
		//this.loadDefaultValues();
	
	},
	
	methods: {
		
		//default data
		loadDefaultValues:function(){
			
			//show loader
			this.preloader=true
			
			var self = this;
			axios.get('/admins/app/core/UserChangePass/getUserPswdData.php',{
				params:{
				
				}
			})
			.then(function (response) {
				//console.log(response.data)
				//hide loader
				self.preloader=false
				
				//access data
				self.accData = response.data.accData;
				
				//helpbox data
				self.helpboxData = 'guidebook.guideMods';
				
				self.basicData = response.data.basicData;
				self.userInputs = response.data.userInputs;
			})
				.catch(function (error) {
				console.log(error);
			});
		
		},
		
		
		checkPasswordV2:function()
		{
			//1.
			if(this.userInputs.password.length < 6){
				this.userInputs.passwordError = true;
				this.toast.error(this.$t('msgCore.passwordIsTooShort'));
				return false;
			}
			
			//2.
			var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/; 
			if (!re.test(this.userInputs.password)) {
				this.userInputs.passwordError = true;	
				this.toast.error(this.$t('msgCore.passwordMustHaveSmallBigLettersAndNumbers'));
				return false;
			}
				
			
			//3.
			if (this.userInputs.password != this.userInputs.passwordRepeat){
				this.userInputs.passwordError = true;		
				this.userInputs.passwordRepeatError = true;	
				this.toast.error(this.$t('msgCore.passwordsDoNotMatch'));
				return false;
			}
			
			this.userInputs.passwordError = false;
			this.userInputs.passwordRepeatError = false;
			return true;

		},
			
		//zobrazi heslo
		showPswd: function(){
			this.userInputs.showInput = this.userInputs.showInput===true?false:true;
		},

		//odhlasenie reg ucastnika
		logOutMe:function(){
			
			//var self = this;
			axios.get('/admins/app/core/Common/controlLogout.php')
			.then(function (response) {
				
				var user = response.data.userData;
				
				if(!user.isReg){
					//self.$router.push({ path: '/login' })
					location.reload();
				}
			})
				.catch(function (error) {
				console.log(error);
			});
		},

		savePswd:function(){
				
			this.cSession();
			
			if(this.checkPasswordV2()){
				
				var bodyFormData = new FormData();
				bodyFormData.set('p',sha512(this.userInputs.password));
				this.userInputs.password='';

				var self=this;
				axios({
					method: 'post',
					url: '/admins/app/core/UserChangePass/controlUserPswdSave.php',
					data: bodyFormData
				})
				.then(response => {
					if (response.data.error) {
							console.log('error', response.data.error)
					} else {
						//console.log(response.data);
						if(response.data.resCode==1){
							self.toast.success(this.$t('msgCore.dataWasSaved'));
							setTimeout(self.logOutMe, 2000);
							
						}else if(response.data.resCode==0){
							self.toast.warning(this.$t('msgCore.dataCantBeSaved'));
						}else if(response.data.resCode==2){
							self.toast.warning(this.$t('msgCore.dataWasNotSaved'));
						}
					}
				})
				.catch(error => {
					console.log(error.response)
				}); 

			}
			
		},
		
	},	
			
	watch: {
		auth(){
			this.loadDefaultValues();
		}
	},

	computed:{
		
	},
	
}

</script>

<template>
	
	<div v-show="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
		<div id="version" class="version"><p>{{accData.version}}</p></div>
				
		<div v-if="accData.modulAcc"> 
				
			<div class="row">
				<div class="col-12">
						
					<div class="row">
						<div class="col-12">
							<h5>{{ $t('msgCore.setPassword') }}</h5>
							<h6 class="modal-title"><b><span>{{ basicData.name }} {{ basicData.surname }}</span></b></h6>
						</div> 
					</div>
					
					<div class="form-row">
			
						<div class="form-group col-md-3 col-12">
							<div class="input-group">
								<input :type="userInputs.showInput ? 'text' : 'password'" autocomplete="new-password" v-on:change="checkPasswordV2()" class="form-control form-control-sm" value='' v-model="userInputs.password" :placeholder="$t('msgCore.Password')" >
								<button type="button" class="btn btn-outline-info btn-sm my-btn" v-on:click="showPswd()">
									<i v-if="!userInputs.showInput" :title="$t('msgCore.showPassword')" class="smire-icon far fa-eye"></i>
									<i v-else :title="$t('msgCore.hidePassword')" class="smire-icon far fa-eye-slash"></i>
								</button>
							</div>
							<div v-show="userInputs.passwordError" class="error-input">chyba</div>
						</div>
						
						<div class="form-group col-md-3 col-12">
							<div class="input-group">
								<input :type="userInputs.showInput ? 'text' : 'password'" autocomplete="new-password" v-on:change="checkPasswordV2()" class="form-control form-control-sm" value='' v-model="userInputs.passwordRepeat" :placeholder="$t('msgCore.passwordRepeat')" >
								<button type="button"  class="btn btn-outline-info btn-sm my-btn" v-on:click="showPswd()">
									<i v-if="!userInputs.showInput" :title="$t('msgCore.showPassword')" class="smire-icon far fa-eye"></i>
									<i v-else :title="$t('msgCore.hidePassword')" class="smire-icon far fa-eye-slash"></i>
								</button>								
							</div>
							<div v-show="userInputs.passwordRepeatError" class="error-input">chyba</div>
						</div>	
					</div>	
					
					<div class="btn-group">
						<button type="button" v-on:click="savePswd()" class="btn btn-warning btn-sm">{{ $t('msgCore.btnSave') }}</button>	
					</div>
					
				</div>	
			</div>
			
			
			<div class="row">
				<div class="col-12">
					<h6 class="modal-title"><b><span class="text-danger">{{ $t('msgCore.YouWillBeLoggedOutAfterSuccessChangPassword') }}</span></b></h6>
				</div>	
			</div>
							
		</div>
		
		<div v-else >
			<div class="row">
				<div class="col-12">
					<div class="alert alert-warning" role="alert">
						{{$t('msgCore.accessDenied')}}
					</div>
				</div>
			</div>
		</div>
	
</template>


