<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
library.add( faExclamationCircle)
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
	name: 'MainVersion',
	
	components: {
		"font-awesome-icon":FontAwesomeIcon
	},
	
	data() {
		return {
			
		}
	},
	
	props: {'version': String, 'versionApp': String, 'versionDB': String,'versionCMS': String,'versionAlert': Boolean},
	
	mounted:function(){
		
	},
	
	methods: {
			
	},	
	
	watch: {
		
	},

	computed:{
		
	},
	
}

</script>

<template>
	<p v-if="versionAlert"><font-awesome-icon :icon="['fas', 'exclamation-circle']" class="text-warning"  /><br>
	<span class="very-small">{{versionCMS}}</span><br>
	<span class="very-small">{{versionDB}}</span><br>
	<span class="very-small">{{versionApp}}</span>
	</p><p v-else>{{version}}</p>
</template>


