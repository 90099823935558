<script>

import $ from 'jquery'; 
import { useToast } from "vue-toastification";
import MyPagination from '@/components/SystemElements/Pagination.vue'
import MyCountRecords from '@/components/SystemElements/CountRecordsOptions.vue'

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";
Fancybox.bind("[data-fancybox]", {
  // Your options go here
});

import Popper from "vue3-popper";


import TaskEdit from '@/components/CustomWorking/WorkingTasks/TaskEdit.vue'
import TaskDelete from '@/components/CustomWorking/WorkingTasks/TaskDelete.vue'

import TaskItemEdit from '@/components/CustomWorking/WorkingTasks/TaskItemEdit.vue'
import TaskItemDelete from '@/components/CustomWorking/WorkingTasks/TaskItemDelete.vue'


//general functions
import MixinGeneralFunct from '@/mixins/GeneralFunctions';

import axios from 'axios';

export default {
	setup() {
		const toast = useToast();
		return { toast }
	},
	name: 'Tasks',
	mixins: [ MixinGeneralFunct ],
	components: {
		//"font-awesome-icon":FontAwesomeIcon
		TaskEdit,TaskDelete
		,TaskItemEdit,TaskItemDelete
		,Popper
		,MyPagination,MyCountRecords
 
	},
	props: {'auth':Object, 'secData':Object, 'params':Object},
	data() {
		return {
			prefix:'',
		
            
            accData:[],
            
            preloader:true,

            menuItems:[],
            actLangs:[],
            
            helpboxData: [],
            
            basicDataCount:0,
            basicData:[],
            selectedFilterText:'',
            
            selectedType:'',
            optionsTypes:[],

			selectedTaskId:'',
			selectedTaskItemId:'',

            countPages:0,

			selectedPage:1,
			selectedRecords:10,
			selectedCol:'a.id',
			selectedSort:'desc',
			
			selectedIds:[],
			selectAllIds:false,
			
			
		
			showFilter:false,
			filterExtended:true,
			
			showedHelpbox:false


		}
	},
	
	mounted:function(){
		
	},
	
	methods: {
				
		loadDefaultValues:function(){
			//console.log(this.auth)
			//show loader
			this.preloader=true
			
			var self = this;
			axios.get('/admins/app/customWorking/WorkingTasks/getTaskList.php',{
				params:{
					filterText: this.selectedFilterText,
					filterType: this.selectedType,
					
					//system
					selectedIds: this.selectedIds,
					orderCol: this.selectedCol,
					orderSort: this.selectedSort,
					page: this.selectedPage,
					records: this.selectedRecords
					
				}
			})
			.then(function (response) {
				//console.log(response.data)
			
                //hide loader
				self.preloader=false

                //access data
				self.accData = response.data.accData;
				
				//helpbox data
				self.helpboxData = 'guidebook.guideTasks';
										
			//	if(app.accessToEmployee){
					

					self.countPages = response.data.countPages;

					self.basicDataCount = response.data.basicDataCount;
					self.basicData = response.data.basicData;
					
					self.optionsTypes = response.data.optionsTypes;
					
					//oznacenie poloziek cez strankovac
					var indexToSelect;
					var selectedIdsTEMP = [];
					selectedIdsTEMP = response.data.selectedIds; 
					self.selectedIds = [];
					for(let i = 0; i < selectedIdsTEMP.length; i++){
						self.selectedIds.push(Number(selectedIdsTEMP[i]));
					}
					for(let i = 0; i < self.basicData.length; i++){
						indexToSelect = self.selectedIds.indexOf(self.basicData[i].id);
						if(indexToSelect>=0){
							self.basicData[i].selectedId=true;
						}
					}
					self.selectAllIds = false;
					
					
					
					//app.countLimit();
					
			//	}
				
			})
				.catch(function (error) {
				console.log(error);
				
				
			});
		
		},
		
				
		//odomknutie zaznamu
		unlockTask:function(groupId){
			
			this.cSession();
			var bodyFormData = new FormData();
			bodyFormData.set('myRecordID',groupId);
				
			var self = this;
			axios({
				method: 'post',
				url: '/admins/app/customWorking/WorkingTasks/controlTaskUnlock.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
					//console.log(response.data);
					if(response.data==1){
						self.toast.success(this.$t('msgCore.dataWasUnlocked'));
						self.loadDefaultValues();
					}else if(response.data==0){
						self.toast.warning(this.$t('msgCore.dataCantBeUnlocked'));
						self.loadDefaultValues();
					}else if(response.data==2){
						self.toast.error(this.$t('msgCore.dataWasNotUnlocked'));
						self.loadDefaultValues();
					}else if(response.data==3){
						self.toast.error(this.$t('msgCore.accessDenied'));
						self.loadDefaultValues();
					}
					
				}
			})
			.catch(error => {
				console.log(error.response)
			}); 
			
		},
		
		
		
		
		
		//systemove funkcie k zoznamu
		//(od)oznacenie vsetkych poloziek
		unSelectIds:function(){
			if(this.selectAllIds){
				for(let i = 0; i < this.basicData.length; i++){
					this.basicData[i].selectedId=true;
					this.selectedIds.push(this.basicData[i].id);
				}
				this.selectedIds = this.selectedIds.filter( this.onlyUnique );
			}else{
				for(let i = 0; i < this.basicData.length; i++){
					this.basicData[i].selectedId=false;
					var indexToRemove = this.selectedIds.indexOf(this.basicData[i].id);
					if(indexToRemove>=0){
						this.selectedIds.splice(indexToRemove, 1);
						
					}
					//this.selectedIds = [];
				}
				this.selectedIds = this.selectedIds.filter( this.onlyUnique );
			}
			//console.log(this.selectedIds);
		},
		
		//oznacenie jednej polozky
		checkSelectIds:function(index,id){
			if(this.basicData[index].selectedId==true){
				this.selectedIds.push(id);
			}else{
				var indexToRemove = this.selectedIds.indexOf(id);
				this.selectedIds.splice(indexToRemove, 1);
			}
			
			this.selectedIds = this.selectedIds.filter( this.onlyUnique );	
			//console.log(this.selectedIds);
		},
		
		//radenie zoznamu - orders by
		orderByCol:function(col){
			
			if(col === this.selectedCol) {
				this.selectedSort = this.selectedSort==='asc'?'desc':'asc';
			}
			this.selectedCol = col;
			
			this.loadDefaultValues();
		},
		
		//select page 
		selPage:function(x){
			
			if(x=='prev' || x=='next'){
				if(x=='prev'&&this.selectedPage!=1){this.selectedPage=this.selectedPage-1}
				if(x=='next'&&this.selectedPage!=this.countPages){this.selectedPage=this.selectedPage+1}
			}else{
				if(this.selectedPage != x){
					this.selectedPage = Number(x);
				}
			}
			//console.log(this.selectedPage);
			this.loadDefaultValues();
			
		},

		//active menu
		isActive: function (col) {
			return this.selectedCol === col;
		},
		isAsc: function (col) {
			if(this.selectedCol === col && this.selectedSort === 'asc'){
				return true;
			}
		},
		isDesc: function (col) {
			if(this.selectedCol === col && this.selectedSort === 'desc'){
				return true;
			}
		},
		
		//zobrazi filtre
		showFilters: function(){
			if(!this.showFilter) {
				this.showFilter = true;
			}else{
				this.showFilter = false;
			}
		},
		
		//vycisti text filter
		resetTextFilter: function(){
			this.selectedFilterText = '';
			this.loadDefaultValues();
		},
		
		// zobrazi napovedu
		showHelpbox: function(){
			if(!this.showedHelpbox) {
				this.showedHelpbox = true;
			}else{
				this.showedHelpbox = false;
			}
		},
		// systemove funkcie k zoznamu KONIEC
		
		
		//modal box  editacia
		loadModalFormTaskEdit:function(recordID){
			this.cSession();
			//console.log(recordID)
			this.selectedTaskId=String(recordID);
			$('#myModalEditTask').addClass('modal');
			$('#myModalEditTask').modal({backdrop: "static"});
		},
		
		//modal box  delete
		loadModalFormTaskDelete:function(recordID){
			this.cSession();
			//console.log(recordID)
			this.selectedTaskId=String(recordID);
			$('#myModalDeleteTask').addClass('modal');
			$('#myModalDeleteTask').modal({backdrop: "static"});
		},

		//modal box  editacia
		loadModalFormTaskItemEdit:function(taskId,taskItemId){
			this.cSession();
			//console.log(recordID)
			this.selectedTaskId=String(taskId);
			this.selectedTaskItemId=String(taskItemId);
			$('#myModalEditTaskItem').addClass('modal');
			$('#myModalEditTaskItem').modal({backdrop: "static"});
		},
		
		//modal box  delete
		loadModalFormTaskItemDelete:function(recordID){
			this.cSession();
			//console.log(recordID)
			this.selectedTaskItemId=String(recordID);
			$('#myModalDeleteTaskItem').addClass('modal');
			$('#myModalDeleteTaskItem').modal({backdrop: "static"});
		},
		
		

		
		reloadAfterChange() {
			//console.log(e);
			this.loadDefaultValues();
			this.selectedTaskId='';
			this.selectedTaskItemId='';
			//this.cart.push(e);
			//this.total = this.shoppingCartTotal;
		},

		//vyber stranky v strankovaci
		reloadAfterChangeNrPage(selectedPage) {
			this.selectedPage=selectedPage
			this.loadDefaultValues();
		},

		//vyber poctu zaznamov
		reloadAfterChangeCountRecords(selectedCount) {
			this.selectedRecords=selectedCount
			this.loadDefaultValues();
		},
		
		//zmena stavu
		changeState:function(recordId,state){
			this.cSession();
			var bodyFormData = new FormData();
			bodyFormData.set('recordId',recordId);
			bodyFormData.set('state',state);
			
			var self = this;
			axios({
				method: 'post',
				url: '/admins/app/customWorking/WorkingTasks/controlTaskChangeState.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
					//console.log('success2', response.data);
					if(response.data.resCode==1){
						self.toast.success(this.$t('msgCore.dataWasSaved'));
						self.loadDefaultValues();
					}else if(response.data.resCode==0){
						self.toast.warning(this.$t('msgCore.dataCantBeSaved'));
					}else if(response.data.resCode==2){
						self.toast.warning(this.$t('msgCore.dataCantBeSaved'));
					}else if(response.data.resCode==3){
						self.toast.error(this.$t('msgCore.accessDenied'));
					}
				}
			})
			.catch(error => {
				console.log(error.response)
			}); 
	
		}
		
	},	
	
			
	watch: {
		'auth.tasksList': function () {
			this.loadDefaultValues();
		}
	},

	computed:{
		
	},
	
}

</script>

<template>
	<div>

		<div v-show="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
		<div id="version" class="version"><p>{{accData.version}}</p></div>

		<div v-if="accData.modulAcc" > 
					
            <div class="row">
                <div class="col-12">
                                    
					<div class="row">
						<div class="col-6">
							<h5>{{ $t('msgCustomWorking.Tasks') }}</h5>	 
						</div> 
						
						<div class="col-6 right">
							<button type="button" :disabled="!auth.tasksItemNew" v-on:click="loadModalFormTaskEdit(null)" class="btn btn-primary btn-sm"><i class="smire-icon fas fa-plus"></i> {{ $t('msgCore.btnAdd') }}</button>
						</div> 
					</div>

					<!-- filters basic -->
					<div class="row">
						<div class="input-group col-md-4 col-12">
							<button type="button" :title="$t('msgCore.btnFilter')" class="btn btn-info btn-sm my-btn" v-on:click="showFilters()">
								<span v-if="filterExtended">
									<i v-if="!showFilter" class="smire-icon fas fa-search-plus"></i> 
									<i v-else class="smire-icon fas fa-search-minus"></i>
								</span>
								<span v-else>
									<i class="smire-icon fas fa-search"></i> 
								</span>
								{{ $t('msgCore.btnFilter') }}
							</button>
							<input type="text" class="form-control form-control-sm" v-model='selectedFilterText' :placeholder="$t('msgCore.searchTitle')" v-on:change="loadDefaultValues()">
							<button type="button" :title="$t('msgCore.btnStorno')" class="btn btn-outline-info btn-sm my-btn" v-on:click="resetTextFilter()">
								<i class="smire-icon fas fa-times"></i>
							</button>
						</div>
					</div>
					

					<!-- filters extended -->
					<div v-show="showFilter && filterExtended" class="row">
						<div class="input-group form-group-filter col-4">
					
							<select v-model='selectedType' class="form-control form-control-sm" v-on:change="loadDefaultValues()">
								<option value="">{{ $t('msgCore.selectType') }}</option>
								<option v-for="(options) in optionsTypes" :value="options.value" :key="options.value">{{ options.title }}</option>
							</select>
							
						</div>					
					</div>
                        
                    
                </div>
            </div>	

            
            <!-- zakladny zoznam -->
            <div v-if="basicDataCount>0">

				<div class="row">
					<div class="col-12">
	
						<div class="table-responsive">
							<table class="table table-hover table-sm">
							
								<thead>
									<tr>
										<th class="orderBy" v-on:click="sort('id')" :class="{ active: isActive('id'), asc: isAsc('id'), desc: isDesc('id')}">id</th>

										<th width="10" class="align-middle"><div class="form-check"><label class="form-check-label"><input v-model="selectAllIds" v-on:change="unSelectIds()" class="form-check-input" type="checkbox"><span class="form-check-sign"><span class="check"></span></span></label></div></th>

										<th>
											
											{{$t('msgCore.action')}}
										</th>
										
										<th>{{$t('msgCore.Project')}}</th>
										
										<th>{{$t('msgCore.title')}}</th>
										
										<th>{{$t('msgCore.description')}}</th>
										
										<th>{{$t('msgCore.Status')}}</th>
										
										
										
										<th>{{$t('msgCore.Type')}}</th>
										
										<th v-if="auth.tasksItemNew">{{$t('msgCore.Time')}}</th>
										
										<th v-if="auth.tasksItemNew">{{$t('msgCore.Price')}}</th>
										
										<th v-if="auth.tasksItemNew">{{$t('msgCore.Price')}} celkom</th>	

										<th>{{$t('msgCore.Created')}}</th>
                                            
                                        <th>{{$t('msgCore.Modified')}}</th>									
									</tr>
								</thead>
								
							<tbody v-for="(basicObj, index) in basicData" :key="basicObj" >	
								<tr>
								
									<td class="blue">{{ basicObj.id }}</td>

									<td class="align-middle">
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                <input v-model.number='basicObj.selectedId' class="form-check-input" type="checkbox" v-on:change="checkSelectIds(index,basicObj.id);">
                                                <span class="form-check-sign"><span class="check"></span></span>
                                                </label>
                                            </div>	
                                        </td>
								
									<td class="align-middle">
										<div class="input-group">	
											
												
																				
											<div class="btn-group">				
												<button type="button" :disabled="!auth.tasksEdit" v-on:click="loadModalFormTaskEdit(basicObj.id)" :title="$t('msgCore.btnEdit')" id="myModalBtn" class="btn btn-secondary btn-sm"><i class="fas fa-edit"></i></button>
										
												<button :disabled="!auth.tasksDelete" type="button"  v-on:click="loadModalFormTaskDelete(basicObj.id)" :title="$t('msgCore.btnDelete')" id="myModalBtn" class="btn btn-danger btn-sm"><i class="fas fa-trash-alt"></i></button>
														
												<button v-if="auth.tasksItemNew" type="button"  v-on:click="loadModalFormTaskItemEdit(basicObj.id,null)" :title="$t('msgCore.btnAdd')" id="myModalBtn" class="btn btn-primary btn-sm"><i class="fas fa-plus"></i></button>				
											</div>	
										</div>	
									</td>
													
									<td class="blue" >{{ basicObj.project_name }}</td>							
									
									<td class="blue">{{ basicObj.title }}</td>
									
									<td class="blue" >{{ basicObj.description }}</td>
									
									<td ><span class="badge badge-secondary" :class="basicObj.status_color">{{ $t('msgCustomWorking.'+basicObj.status_name) }}</span></td>
									
									
								
									<td class="blue" >{{ $t('msgCustomWorking.'+basicObj.type_name) }}</td>
									
									
									
									<td v-if="auth.tasksItemNew" class="blue" ><span class="green">{{ basicObj.totalTimePaid }}</span>/<span class="red">{{ basicObj.totalTimeFree }}</span> min</td>
									
									<td v-if="auth.tasksItemNew"></td>
									
									<td v-if="auth.tasksItemNew" class="blue" ><span class="green">{{ basicObj.totalPricePaid  }}</span>/<span class="red">{{ basicObj.totalPriceFree  }}</span> €</td>

										<td>
											<Popper :content="basicObj.creator+', '+basicObj.createdView">
												<button class="personCircle v-center" v-bind:style="{background:basicObj.creatorColor}">{{ basicObj.creatorNameLetter+''+basicObj.creatorSurnameLetter }}</button>
											</Popper>
                                        </td>

										<td>
											<Popper v-if="basicObj.editor" :content="basicObj.editor+', '+basicObj.modifiedView">
												<button class="personCircle v-center" v-bind:style="{background:basicObj.editorColor}">{{ basicObj.editorNameLetter+''+basicObj.editorSurnameLetter }}</button>
											</Popper>
                                        </td>
									
								</tr>
								<tr  v-for="(taskItem,) in basicObj.taskItems" :key="taskItem" >
									<td colspan="2">{{ basicObj.id }} - {{ taskItem.id }}</td>
									<td >
									
										<div class="btn-group">				
											<button type="button" :disabled="basicObj.isClosed"  v-on:click="loadModalFormTaskItemEdit(basicObj.id,taskItem.id)" :title="$t('msgCore.btnEdit')" id="myModalBtn" class="btn btn-secondary btn-sm"><i class="fas fa-edit"></i></button>
									
											<button type="button" :disabled="basicObj.isClosed"  v-on:click="loadModalFormTaskItemDelete(taskItem.id)" :title="$t('msgCore.btnDelete')" id="myModalBtn" class="btn btn-danger btn-sm"><i class="fas fa-trash-alt"></i></button>
										</div>
										
									</td>
									
									<td ><span class="badge badge-secondary" :class="taskItem.type_color">{{ $t('msgCustomWorking.'+taskItem.type_name) }}</span></td>
									
									<td >{{ taskItem.title }}</td>
									<td >{{ taskItem.description }}</td>
									
									
									<td ><span class="badge badge-secondary" :class="taskItem.status_color">{{ $t('msgCustomWorking.'+taskItem.status_name) }}</span></td>
									
									
									<td></td>
									
									<td >{{ taskItem.work_time }} min</td>
									<td >{{ taskItem.price }} €/hod</td>
									<td >{{ taskItem.editTime }} hod = {{ taskItem.realPrice }} €</td>			
								</tr>	
							</tbody>
							
							</table>	
						</div>		
					
					</div>
				</div>

				<div class="row row-pagination">
					<div class="col-6 col-md-3 col-lg-2">
						<MyCountRecords :selectedRecords="selectedRecords" @changeAfterSelectCount="reloadAfterChangeCountRecords"></MyCountRecords>
					</div>
					
					<div class="col-6 col-md-3 col-lg-2">
						<div class="form-group form-group-filter">
							<span class="btn btn-default btn-sm">Počet: {{basicDataCount}}</span>
						</div>
					</div>
					
					<div class="col-12 col-md-6 col-lg-8">	
						<MyPagination :countPages="countPages" :selectedPage="selectedPage" @changeAfterSelectPage="reloadAfterChangeNrPage"></MyPagination>
					</div>
				</div>	
                    
                
            </div>	
            
            <div v-else class="row">
                <div class="col-12">
                    <div  class="alert alert-warning" role="alert">
                        {{$t('msgCore.forThisSelectExistAnyData')}}
                    </div>	
                </div>							
            </div>
            
        </div>	
        
        <div v-else class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="alert alert-warning" role="alert">
                        {{$t('msgCore.accessDenied')}}
                    </div>
                </div>
            </div>
        </div>
	
		<TaskEdit :auth="auth" :secData="secData" :taskId="selectedTaskId" @changeRecord="reloadAfterChange"></TaskEdit>
		<TaskDelete :auth="auth" :secData="secData" :taskId="selectedTaskId" @changeRecord="reloadAfterChange"></TaskDelete>

		<TaskItemEdit :auth="auth" :secData="secData" :taskItemId="selectedTaskItemId" :taskId="selectedTaskId" @changeRecord="reloadAfterChange"></TaskItemEdit>
		<TaskItemDelete :auth="auth" :secData="secData" :taskItemId="selectedTaskItemId" @changeRecord="reloadAfterChange"></TaskItemDelete>
	</div>	
		
</template>


