<script>


import $ from 'jquery'; 
import { useToast } from "vue-toastification";


//import ProjectSelectIntoPhoto from '@/components/Core/Projects/ProjectSelectIntoPhoto'
import UploadPhoto from '@/components/CustomWorking/WorkingProjects/uploadPhoto/uploadPhoto'




import axios from 'axios';

//general functions
import MixinGeneralFunct from '@/mixins/GeneralFunctions';
import { defineComponent } from 'vue'



export default defineComponent ({
	setup() {
		const toast = useToast();
		return { toast }
	},
	name: 'ProjectPhotos',
	mixins: [ MixinGeneralFunct ],
	components: {
		UploadPhoto
	},
	props: {'projectId': String, 'auth':Object, 'secData':Object},
	emits: ["projectId","changeRecord"],
	data() {
	
		return {
			prefix:'',
			
			params:[],
			accData: [],
			
			preloader:true,
			
			isNewRecord:true,

			optionsAccessLevels: [],
			optionsTypes:[],
			optionsTemplates:[],
			optionsStates:[],
			optionsLanguages:[],
					
			
			//form	
			project:[],
			photos:[]
			
		}
	},
	
	mounted:function(){
		
	},
	
	methods: {
		// load data form
		loadDefaultValues:function(){
			
			this.preloader=true
			//console.log(this.projectId)		
			if(this.projectId=='null'){
				this.isNewRecord=true;
			}else if(this.projectId && this.projectId>0){
				this.isNewRecord=false;
			}
			
			var bodyFormData = new FormData();
			bodyFormData.set('projectId',this.projectId);
			
			
			var self=this;
			axios({
				method: 'post',
				url: '/admins/app/customWorking/WorkingProjects/projectPhoto/getProjectPhotoPreviewData.php',
				data: bodyFormData
			})
			.then(function (response) {
			
				//console.log(response.data);
				self.accData = response.data.accData;
				
				//hide loader
				self.preloader=false
				
				self.accData = response.data.accData;
								
				self.project = response.data.project;
				self.photos = response.data.photos;
				
			})
				.catch(function (error) {
				console.log(error);
				
				
			});
		
		},

		
		
		closeModalBoxSecureHelper:function(myform,projectId){
			this.unlockProject(projectId);
			this.$emit('changeRecord', '')
			//this.loadDefaultValues();
			this.closeModalBoxSecure(myform);
		},
		
		
		
		
		

		checkSave:function(){
								
			if(!this.project.title){this.project.titleError = true; }else{this.project.titleError = false;}
							
			if(this.project.title){
				return true;
			}else{
				this.toast.warning(this.$t('msgCore.fillInAllData'));
				return false;
			}
		},	
		
		saveProject:function(){

			if(this.checkSave()){
				
				var bodyFormData = new FormData();
				bodyFormData.set('myProject',JSON.stringify(this.project));
				
				
				var self=this;
				axios({
					method: 'post',
					url: '/admins/app/customWorking/WorkingProjects/controlProjectSave.php',
					data: bodyFormData
				})
				.then(response => {
					if (response.data.error) {
							console.log('error', response.data.error)
					} else {
					//	console.log('success2', response.data);
						if(response.data.resCode==1){
							self.toast.success(this.$t('msgCore.dataWasSaved'));
							self.$emit('changeRecord', '')
						
							self.closeModalBoxSecure('myModalEditProject');
							
						}else if(response.data.resCode==0){
							self.toast.warning(this.$t('msgCore.dataCantBeSaved'));
							self.closeModalBoxSecure('myModalEditProject');
						}else if(response.data.resCode==2){
							self.toast.error(this.$t('msgCore.dataWasNotSaved'));
							self.closeModalBoxSecure('myModalEditProject');
						}else if(response.data.resCode==3){
							self.toast.error(this.$t('msgCore.accessDenied'));
							self.closeModalBoxSecure('myModalEditProject');
						}
						self.project = [];
					}
				})
				.catch(error => {
					console.log(error.response)
				}); 
				
				
			}
			
		},
		
		//odomknutie zaznamu
		unlockProject:function(projectId){
			
			this.cSession();
			var bodyFormData = new FormData();
			bodyFormData.set('myRecordID',projectId);
				
			var self = this;
			axios({
				method: 'post',
				url: '/admins/app/customWorking/WorkingProjects/controlProjectUnlock.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
					//console.log(response.data);
					if(response.data==1){
						self.$emit('changeRecord', '')
					}else if(response.data==0){
						//xx
					}else if(response.data==2){
						//xx
					}else if(response.data==3){
						//xx
					}
					
				}
			})
			.catch(error => {
				console.log(error.response)
			}); 
			
		},
//pozri povodny get upload data!!!!!

		//modal box upload photos
		loadModalFormProjectPhotosUpload:function(){
			//this.loadDefaultValuesPhotoToArticle();
			$('#myModalUploadPhotos').addClass('modal');
			$('#myModalUploadPhotos').modal({backdrop: "static"});
		},

		reloadAfterAddedPhoto() {
			//console.log(e);
			this.loadDefaultValues();
			//this.selectedArticleId='';
			//this.cart.push(e);
			//this.total = this.shoppingCartTotal;
		},

		//otocenie fotky o 90
		rotatePhoto:function(photoId){
			
			var bodyFormData = new FormData();
			bodyFormData.set('photoId',photoId);
			
			var self=this;
			axios({
				method: 'post',
				url: '/admins/app/customWorking/WorkingProjects/projectPhoto/controlPhotoRotate.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
					//console.log('success2', response.data);
					if(response.data==1){
						self.toast.success(this.$t('msgCore.photoWasRotated'));
						self.loadDefaultValues();
						
					}else if(response.data==0){
						self.toast.warning(this.$t('msgCore.photoCantBeRotated'));
					}else if(response.data==2){
						self.toast.error(this.$t('msgCore.photoWasNotRotated'));
					}else if(response.data==3){
						self.toast.error(this.$t('msgCore.accessDenied'));
					}
					
					
				}
			})
			.catch(error => {
					console.log(error.response)
			}); 
		},

		//vymazanie fotky
		deletePhoto:function(photoId){
			var bodyFormData = new FormData();
			bodyFormData.set('photoId',photoId);
			
			var self=this;
			axios({
				method: 'post',
				
				url: '/admins/app/customWorking/WorkingProjects/projectPhoto/controlPhotoDelete.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
					//console.log('success2', response.data);
					if(response.data==1){
						self.toast.success(this.$t('msgCore.photoWasDeleted'));
						self.loadDefaultValues();
						
					}else if(response.data==0){
						self.toast.warning(this.$t('msgCore.photoCantBeDeleted'));
					}else if(response.data==2){
						self.toast.error(this.$t('msgCore.photoWasNotDeleted'));
					}else if(response.data==3){
						self.toast.error(this.$t('msgCore.accessDenied'));
					}
					
					
				}
			})
			.catch(error => {
					console.log(error.response)
			}); 
		},
		
		//oznacenie hlavnej fotky
		selectPhoto:function(photoId){
			var bodyFormData = new FormData();
			bodyFormData.set('myPhotoID',photoId);
			bodyFormData.set('myProjectID',this.projectId);
			
			var self=this;
			axios({
				method: 'post',
				
				url: '/admins/app/customWorking/WorkingProjects/projectPhoto/controlPhotoSelectMain.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
					//console.log('success2', response.data);
					if(response.data==1){
						self.toast.success(this.$t('msgCore.photoWasSelected'));
						self.loadDefaultValues();
						
					}else if(response.data==0){
						self.toast.warning(this.$t('msgCore.photoCantBeSelected'));
					}else if(response.data==2){
						self.toast.error(this.$t('msgCore.photoWasNotSelected'));
					}else if(response.data==3){
						self.toast.error(this.$t('msgCore.accessDenied'));
					}
					
					
				}
			})
			.catch(error => {
					console.log(error.response)
			}); 
		}

		//get count chars
	/*	getCountChars:function(){
			this.countCharQuestion=this.countCharQuestionLimit - this.question.title.length;
			if(this.countCharQuestion<0){this.question.countCharError = true; }else{this.question.countCharError = false;}
		},*/

	},
	
	watch: {
		projectId() {
			this.loadDefaultValues();
		}
	},

	computed:{
		
	},
	
})

</script>

<template>
	<div id="msg"></div>
	<div id="myModalPreviewProjectPhoto" class="fade show" role="dialog" style="z-index: 1040;  display: none;">
			<div class="modal-dialog modal-lg" style="width: 100%; max-width: none;">
				<div v-show="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
				<div class="modal-content">
					<div class="modal-header">
						<h6 class="modal-title">{{ $t('msgCore.Preview') }} <span><b>{{ project.title }}</b> </span></h6>
						
						<button type="button" :disabled="!auth.projectPhotoUpload" v-on:click="loadModalFormProjectPhotosUpload(project.id)" :title="$t('msgCore.btnUpload')" class="btn btn-primary btn-sm"><i class="smire-icon fas fa-upload"></i> {{ $t('msgCore.Upload') }}</button>
						
					</div>
			
					<div class="modal-body">
						<div class="row">
							<div class="col-12">
								<div v-for="photo in photos" :key="photo" class="crop_gallery2">
									<img :src="photo.fotoData" :title="photo.title">
									<button type="button" :disabled="!auth.projectPhotoUpload" v-on:click="rotatePhoto(photo.id)" :title="$t('msgCore.btnRotate')" class="btn btn-primary btn-sm"><i class="smire-icon fas fa-sync"></i></button>
									<button type="button" :disabled="!auth.projectPhotoDelete" v-on:click="deletePhoto(photo.id)" :title="$t('msgCore.btnRotate')" class="btn btn-danger btn-sm"><i class="smire-icon fas fa-trash-alt"></i></button>
									<button type="button" :disabled="!auth.projectPhotoUpload" v-on:click="selectPhoto(photo.id)" title="Vyberte hlavnú fotku" class="btn  btn-sm" :class="{'btn-success':project.mainPhoto==photo.id,'btn-primary':project.mainPhoto!=photo.id}"><i class="smire-icon fas fa-check"></i></button>
								
								</div>
							</div>
						</div>						
					</div>
					
					<div class="modal-footer">
						<div class="btn-group">
							<button type="button" v-on:click="closeModalBoxSecureHelper('myModalPreviewProjectPhoto',null)" class="btn btn-default btn-sm">{{ $t('msgCore.btnClose') }}</button>

							
						</div>	
					</div>
						
				
					
					
				
				</div>

			</div>
			
		</div>
		

	
	<UploadPhoto :auth="auth" :projectId="String(projectId)" @changeAfterUpload="reloadAfterAddedPhoto"></UploadPhoto>

</template>


