<script>

export default {
	name: 'LoadingButton',
	
	components: {
	
	},
	
	data() {
		return {
			
		}
	},
	
	props: {'isDisabled':Boolean,  'isSending': Boolean, 'color': String, 'label': String},

	mounted:function(){
		
	},
	
	methods: {
		handleClick(){
			this.$emit('onClick')
		}
	},	
	
	watch: {
		
	},

	computed:{
		
	},
	
}

</script>

<template>
	<div>
		<button type="button" :disabled="isDisabled" @click="handleClick" class="btn btn-sm" :class="{'btn-warning':color=='warning'}"><span v-if="isSending" class="spinner-btn"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></span> {{$t(label)}} </button>
	</div>
</template>


