<script>

//import { library } from '@fortawesome/fontawesome-svg-core';
//import { faPen, faArrowAltCircleRight, faSignOutAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faComment } from '@fortawesome/free-solid-svg-icons';
//library.add(faPen,faArrowAltCircleRight,faSignOutAlt, faThumbsUp, faEdit, faTrashAlt, faTimes, faComment)
//import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import Popper from "vue3-popper";
import { useToast } from "vue-toastification";
import axios from 'axios';

//general functions
import MixinGeneralFunct from '@/mixins/GeneralFunctions';
import { defineComponent } from 'vue'
//import tinymce from 'tinymce';
//import axios from 'axios';


//modal box preview photos to article


export default defineComponent ({
	setup() {
		const toast = useToast();
		return { toast }
	},
	name: 'PhotoEdit',
	mixins: [ MixinGeneralFunct ],
	components: {
		//"font-awesome-icon":FontAwesomeIcon
		//,"video-player":VideoPlayer
		Popper
		
	},
	props: {'photoId': String, 'auth':Object, 'secData':Object},
	emits: ["photoId","changeRecord"],
	data() {
		return {
			prefix:'',
			
			params:[],
			accData: [],
			
			preloader:true,
			
			recordID:null,
			isNewRecord:true,
		
			optionsAccessLevels: [],
			optionsStates:[],
			
			
			//form
			photo:[]
			
		}
	},
	
	mounted:function(){
	
		//console.log(this.photoId)
		
		//console.log(this.$route.path)
	},
	
	methods: {
		// load data form
		loadDefaultValues:function(){
			
			this.preloader=true
			
			//console.log(this.photoId);				
			if(this.photoId){
				this.isNewRecord=false;
			}else{
				this.isNewRecord=true;
			}
			
			var bodyFormData = new FormData();
			bodyFormData.set('photoId',this.photoId);
			
			var self=this;
			axios({
				method: 'post',
				url: '/admins/app/core/Photo/getPhotoData.php',
				data: bodyFormData
			})
			.then(function (response) {
			
				//console.log(response.data);
				self.accData = response.data.accData;
				
				//hide loader
				self.preloader=false
				
				self.accData = response.data.accData;
								
				self.photo = response.data.photo;
				
				self.optionsAccessLevels = response.data.optionsAccessLevels;
				self.optionsStates = response.data.optionsStates;
				
		
			})
				.catch(function (error) {
				console.log(error);
				
				
			});
		
		},
		
		closeModalBoxSecureHelper:function(myform,photoId){
			this.unlockPhoto(photoId);
			//this.loadDefaultValues();
			this.closeModalBoxSecure(myform);
		},
		
		
		
		
		checkSave:function(){
				
			if(!this.photo.title){this.photo.titleError = true; }else{this.photo.titleError = false;}
			if(!this.photo.state){this.photo.stateError = true; }else{this.photo.stateError = false;}
			if(!this.photo.access){this.photo.accessError = true; }else{this.photo.accessError = false;}
			
			if(this.photo.title && this.photo.state && this.photo.access){
				return true;
			}else{
				this.toast.warning(this.$t('msgCore.fillInAllData'));
				return false;
			}
		},	
	
		savePhoto:function(){
							
			if(this.checkSave()){
				
				var bodyFormData = new FormData();
				bodyFormData.set('myPhoto',JSON.stringify(this.photo));
				
				
				var self=this;
				axios({
					method: 'post',
					url: '/admins/app/core/Photo/controlPhotoSave.php',
					data: bodyFormData
				})
				.then(response => {
					if (response.data.error) {
							console.log('error', response.data.error)
					} else {
						//console.log('success2', response.data);
						if(response.data.resCode==1){
							self.toast.success(this.$t('msgCore.dataWasSaved'));
							self.$emit('changeRecord', '')
						
							self.closeModalBoxSecure('myModalEditPhoto');
							
						}else if(response.data.resCode==0){
							self.toast.warning(this.$t('msgCore.dataCantBeSaved'));
							self.closeModalBoxSecure('myModalEditPhoto');
						}else if(response.data.resCode==2){
							self.toast.error(this.$t('msgCore.dataWasNotSaved'));
							self.closeModalBoxSecure('myModalEditPhoto');
						}else if(response.data.resCode==3){
							self.toast.error(this.$t('msgCore.accessDenied'));
							self.closeModalBoxSecure('myModalEditPhoto');
						}
						self.photo = [];
					}
				})
				.catch(error => {
					console.log(error.response)
				}); 
				
				
			}
			
		},
		
		//odomknutie zaznamu
		unlockPhoto:function(photoId){
			
			this.cSession();
			var bodyFormData = new FormData();
			bodyFormData.set('myRecordID',photoId);
				
			var self = this;
			axios({
				method: 'post',
				url: '/admins/app/core/Photo/controlPhotoUnlock.php',
				data: bodyFormData
			})
			.then(response => {
				if (response.data.error) {
						console.log('error', response.data.error)
				} else {
				//	console.log(response.data);
					if(response.data==1){
						self.$emit('changeRecord', '')
					}else if(response.data==0){
						//xx
					}else if(response.data==2){
						//xx
					}else if(response.data==3){
						//xx
					}
					
				}
			})
			.catch(error => {
				console.log(error.response)
			}); 
			
		}
		
	},
	
	watch: {
		photoId() {
			this.loadDefaultValues();
		}
	},

	computed:{
		
	},
	
})

</script>

<template>
	<div id="msg"></div>
	<div id="myModalEditPhoto" class="fade show" role="dialog" style="z-index: 1040;  display: none;">
		<div class="modal-dialog modal-lg" style="width: 100%; max-width: none;">
		<div v-show="preloader" class="preloader"><div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>
			<div class="modal-content">
				<div class="modal-header">
					<h6 class="modal-title">{{ $t('msgCore.Photo') }} <span v-if="photo.isNewRecord">{{ $tc('msgCore.new',1) }}</span><span v-else><b>{{ photo.title }}</b> id: {{photo.id}}</span></h6>
				</div>
			
				<div v-if="accData.modulAcc && (auth.photosEdit)" class="modal-body">
						
						<div class="row">
							<div class="col-12">
								<div v-if="photo.isCheckedOut && secData.userId != photo.isCheckedOutId" class="angle">
									<span class="angle-inner" :class="{'angle-warning':photo.isCheckedOut}"><i class="fa" :class="{'angle-warning':photo.isCheckedOut, 'fa-exclamation-triangle':photo.isCheckedOut}" aria-hidden="true"></i> {{$t('msgCore.Edited')}} </span >
									
									<Popper v-if="photo.isCheckedOut" :content="photo.isCheckedOutName+', '+photo.isCheckedOutDate">
										<button class="personCircle v-center" v-bind:style="{background:photo.isCheckedOutColor}">{{ photo.isCheckedOutLetterName+''+photo.isCheckedOutLetterSurname }}</button>
									</Popper>
								</div>
							</div>
						</div>
						
						<div class="row">
						
							<div class="col-md-2 col-12">
								<img :src="photo.fotoData" class="img-fluid">
							</div>	
						
							<div class="col-md-6 col-12">
						
								<div class="form-row">
									
																				
									<div class="form-group col-md-6 col-12">
										<label :class="{'text-danger':photo.titleError}"><b>{{ $t('msgCore.Title') }}</b> </label>
										<input type="text" class="form-control form-control-sm" :class="{'is-invalid':photo.titleError}" v-model="photo.title" v-on:change="checkSave()">
										<div v-show="photo.titleError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.fillIn')}}</span></div>
									</div>
									
									
									
									<div class="form-group col-md-12 col-12">
										<label><b>{{ $t('msgCore.Note') }}</b> </label>
										<input type="text" class="form-control form-control-sm" v-model="photo.note" >
										
									</div>
									
									
																			
									
								</div>
							</div>	
								
							<div class="col-md-4 col-12 form-control-system-data">
								
								<div class="form-row">
								
									<div class="form-group col-12">
										<label :class="{'text-danger':photo.accessError}"><b>{{ $t('msgCore.AccessLevel') }}</b></label>
																							
										<select v-model='photo.access' class="form-control form-control-sm " :class="{'is-invalid':photo.accessError}" v-on:change="checkSave()">
											<option value="">{{ $t('msgCore.selectLevel') }}</option>
											<option v-for="(option) in optionsAccessLevels" :value="option.value" :key="option" >{{ option.title }}</option>
										</select>
										<div v-show="photo.accessError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.selectLevel')}}</span></div>
									</div>
									
									<div class="form-group col-12">
										<label :class="{'text-danger':photo.stateError}"><b>{{ $t('msgCore.State') }}</b></label>
										
											<div  v-for="(option) in optionsStates" :key="option" class="form-check">
											<label class="form-check-label">
												<input class="form-check-input" type="radio" name="optionsStates"  v-model='photo.state' v-on:change="checkSave()" :value="option.value">
												{{ $t('msgCore.'+option.title) }}
												<span class="circle">
												<span class="check"></span>
												</span>
											</label>
											</div>
											<div v-show="photo.stateError" class="error-input"><span class="badge badge-pill badge-danger">{{$t('msgCore.selectState')}}</span></div>
									</div>
								
								</div>
								
							</div>										
								
						</div>	
							
					
				</div>
				
				<div v-else class="modal-body">
					<div class="row">
						<div class="col-12">
							<div class="alert alert-warning" role="alert">
								{{$t('msgCore.accessDenied')}}
							</div>
						</div>
					</div>
				</div>
				
				<div class="modal-footer">
				
					<div class="btn-group">
						<button type="button" v-on:click="closeModalBoxSecureHelper('myModalEditPhoto',photo.id)" class="btn btn-default btn-sm">{{ $t('msgCore.btnClose') }}</button>
						
						<button type="button" :disabled="!auth.photosEdit || !accData.modulAcc" v-on:click="savePhoto()" class="btn btn-warning btn-sm">{{ $t('msgCore.btnSave') }}</button>	
					</div>	
					
				</div>
				
			</div>

		</div>
			
	</div>
		

	
</template>


