<script>

export default {
	name: 'MainMenu',
	
	components: {
	
	},
	
	data() {
		return {
			activeItem: null,
			activeClass: null,
			currentUrl: null,
			loadingMenu:true,
			menuItems:[]
		}
	},
	
	props: {'propActLang': String, 'menuData': Array, 'params': Object},
	
	mounted:function(){
		//if(localStorage.Lang!=null) {this.actLang=localStorage.Lang} else { this.actLang='sk'}

		var myPath = window.location.pathname;
		var n = myPath.length;
		this.currentUrl=myPath.substr(1,n-1);
		this.currentUrl=myPath;
		
		/*if(myPath.substring(1, 8)==this.params.appFolder){
			var str = myPath.substr(1);
			var resNumber = str.indexOf("/")
			var res = str.substr(resNumber);
			n = res.length;
			res = res.substr(1,n-2);
			console.log(this.currentUrl)
			this.currentUrl=res;
	
		}*/
		
	},
	
	methods: {
		
		selectItem(i) {
			for(var j = 0; j < this.menuItems.length; j++){
				this.menuItems[j].activeParent=false;
			}
            if(this.activeItem === i){
				this.activeClass = this.activeClass===i?null:i;
			}else{
				this.activeClass = i
			}
			this.activeItem = i;
        }
	
	},	
	
	watch: {
		propActLang () {
			this.$i18n.locale = this.propActLang
		},
		menuData () {
			this.menuItems = this.menuData;
			if(this.menuItems){
				if(this.menuItems.length>0){
					this.loadingMenu=false;
				}
				for(var i = 0; i < this.menuItems.length; i++){
					this.menuItems[i].activeParent=false;

					// kontrola submenu
					for(var j = 0; j < this.menuItems[i].subMenus.length; j++){
						if(this.menuItems[i].subMenus[j].url==this.currentUrl){
							this.menuItems[i].activeParent=true;
						}
					}

					// kontrola hlavneho menu
					if(this.menuItems[i].url==this.currentUrl){
						this.menuItems[i].activeParent=true;
					}
				}
			}

		},
		params () {
			//console.log(this.params);
						
		}
	},

	computed:{
		
	},
	
}

</script>

<template>
	<ul v-if="!loadingMenu" class='main-menu'>
		<li v-for='(menuItem, index) in menuItems' :key="menuItem" :class='{ active: index === activeClass||menuItem.activeParent}' v-on:click='selectItem(index)'><a :href='menuItem.url' :class='{deeper:menuItem.isDeeper, haveSubmenu:menuItem.isDeeper, active: index === activeClass}'  >{{$t('msgMenu.'+menuItem.name)}}</a><ul v-if='menuItem.isDeeper' class='submenu'><li v-for='(menuItem2) in menuItem.subMenus' :key="menuItem2"><a :href='menuItem2.url' :class='{active:menuItem2.url==currentUrl}'> {{$t('msgMenu.'+menuItem2.name)}}</a></li></ul></li></ul>
</template>


